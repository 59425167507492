import { notice } from '@/util/notice'
import i18n from '@/plugins/i18n'

/**
 * Table column欄位處理
 * @param {array} columns // columns 欄位設定
 * @param {int} tableWidth // table欄位寬度
 * @returns
 */
export function columnHandler (columns) {
  // 透過isShow，判斷是否顯示欄位
  columns = columns.filter(e => e.isShow !== false)
  return columns
}

// 變更column排序，並寫入isShow值
export function sortColumnByShowColumn (columns, showColumnObj) {
  if (showColumnObj.length === 0) {
    return columns
  }
  const sortColumns = []
  showColumnObj.forEach(showColumn => {
    columns.forEach((column, index) => {
      if (showColumn.dataIndex === column.dataIndex) {
        const tmp = columns[index]
        tmp.isShow = showColumn.isShow
        sortColumns.push(tmp)
      }
    })
  })
  return sortColumns
}

// 顯示、隱藏column
export function selectedColumn(columns, selectedItems) {
  columns.forEach((e, index) => {
    if (selectedItems.includes(e.title)) {
      columns[index]['isShow'] = true
    } else {
      columns[index]['isShow'] = false
    }
  })
  return columns
}

// 取得localStorage中的showColumn
export function getShowColumns () {
  return localStorage.getItem('showColumns') ? JSON.parse(localStorage.getItem('showColumns')) : {}
}

// 拖曳欄位寬度時，保留資料showColumn進localStorage中
export function setShowColumnsWidth (route, columnName, columns) {
  const showColumns = this.getShowColumns()
  if (showColumns[route.currentRoute.value.name] === undefined) {
    showColumns[route.ccurrentRoute.value.name] = {}
  }
  showColumns[route.currentRoute.value.name][columnName] = columns

  notice('success', i18n.global.t('success_action', { input: i18n.global.t('update')}))
  localStorage.setItem('showColumns', JSON.stringify(showColumns))
}
