import { createRouter, createWebHashHistory } from 'vue-router'

export const mainRoutes = {
  name: 'Main',
  component: () => import(/* webpackChunkName: "main" */ '@/views/Main'),
  children: [
    // 首頁
    // { path: '/index', component: () => import(/* webpackChunkName: "Index" */ '@/views/Index/Index'), name: 'index', meta: { keepAlive: false } },
  ],
  meta: {
    requiresAuth: true
  }
}

export const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Index.vue')
    // component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  }
  // mainRoutes
]

export const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(), // 使用 hash 模式
  routes
})

// Middleware
router.beforeEach((to, from, next) => {
  next()
  // const isAuthenticated = sessionStorage.getItem('token')
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!isAuthenticated) {
  //     next({ name: 'login' })
  //   } else {
  //     next()
  //   }
  // } else {
  //   next()
  // }
})
