import { createI18n } from 'vue-i18n'

// 載入語言
import en from '@/lang/en.json'
import tw from '@/lang/tw.json'

const mappingNavigator = (originalLanguage) => {
  const languageObj = {
    'zh-tw': 'tw',
    en: 'en'
  }
  return languageObj[originalLanguage] ? languageObj[originalLanguage] : 'en'
}

// 因為登入頁載入時才將locale寫入sessionStorage，所以初始配置會直接抓瀏覽器語言，當切換語言時，就會用sessionStorage的locale
const i18n = createI18n({
  locale: sessionStorage.getItem('locale') || mappingNavigator((navigator.language || navigator.browserLanguage).toLowerCase()),
  fallbackLocale: 'en',   // 若選擇的語言缺少翻譯則退回的語言
  messages: {
    en,
    tw
  }
})
export default i18n