export const listData = [
    {
        label: "20MICRONS",
        value: "20 Microns Limited"
    },
    {
        label: "21STCENMGM",
        value: "21st Century Management Services Limited"
    },
    {
        label: "360ONE",
        value: "360 ONE WAM LIMITED"
    },
    {
        label: "3IINFOLTD",
        value: "3i Infotech Limited"
    },
    {
        label: "3MINDIA",
        value: "3M India Limited"
    },
    {
        label: "3PLAND",
        value: "3P Land Holdings Limited"
    },
    {
        label: "5PAISA",
        value: "5Paisa Capital Limited"
    },
    {
        label: "63MOONS",
        value: "63 moons technologies limited"
    },
    {
        label: "A2ZINFRA",
        value: "A2Z Infra Engineering Limited"
    },
    {
        label: "AAATECH",
        value: "AAA Technologies Limited"
    },
    {
        label: "AADHARHFC",
        value: "Aadhar Housing Finance Limited"
    },
    {
        label: "AAKASH",
        value: "Aakash Exploration Services Limited"
    },
    {
        label: "AAREYDRUGS",
        value: "Aarey Drugs & Pharmaceuticals Limited"
    },
    {
        label: "AARON",
        value: "Aaron Industries Limited"
    },
    {
        label: "AARTECH",
        value: "Aartech Solonics Limited"
    },
    {
        label: "AARTIDRUGS",
        value: "Aarti Drugs Limited"
    },
    {
        label: "AARTIIND",
        value: "Aarti Industries Limited"
    },
    {
        label: "AARTIPHARM",
        value: "Aarti Pharmalabs Limited"
    },
    {
        label: "AARTISURF",
        value: "Aarti Surfactants Limited"
    },
    {
        label: "AARVEEDEN",
        value: "Aarvee Denims & Exports Limited"
    },
    {
        label: "AARVI",
        value: "Aarvi Encon Limited"
    },
    {
        label: "AAVAS",
        value: "Aavas Financiers Limited"
    },
    {
        label: "ABAN",
        value: "Aban Offshore Limited"
    },
    {
        label: "ABB",
        value: "ABB India Limited"
    },
    {
        label: "ABBOTINDIA",
        value: "Abbott India Limited"
    },
    {
        label: "ABCAPITAL",
        value: "Aditya Birla Capital Limited"
    },
    {
        label: "ABDL",
        value: "Allied Blenders and Distillers Limited"
    },
    {
        label: "ABFRL",
        value: "Aditya Birla Fashion and Retail Limited"
    },
    {
        label: "ABINFRA",
        value: "A B Infrabuild Limited"
    },
    {
        label: "ABMINTLLTD",
        value: "ABM International Limited"
    },
    {
        label: "ABREL",
        value: "Aditya Birla Real Estate Limited"
    },
    {
        label: "ABSLAMC",
        value: "Aditya Birla Sun Life AMC Limited"
    },
    {
        label: "ACC",
        value: "ACC Limited"
    },
    {
        label: "ACCELYA",
        value: "Accelya Solutions India Limited"
    },
    {
        label: "ACCURACY",
        value: "Accuracy Shipping Limited"
    },
    {
        label: "ACE",
        value: "Action Construction Equipment Limited"
    },
    {
        label: "ACEINTEG",
        value: "Ace Integrated Solutions Limited"
    },
    {
        label: "ACI",
        value: "Archean Chemical Industries Limited"
    },
    {
        label: "ACL",
        value: "Andhra Cements Limited"
    },
    {
        label: "ACLGATI",
        value: "Allcargo Gati Limited"
    },
    {
        label: "ACMESOLAR",
        value: "Acme Solar Holdings Limited"
    },
    {
        label: "ADANIENSOL",
        value: "Adani Energy Solutions Limited"
    },
    {
        label: "ADANIENT",
        value: "Adani Enterprises Limited"
    },
    {
        label: "ADANIGREEN",
        value: "Adani Green Energy Limited"
    },
    {
        label: "ADANIPORTS",
        value: "Adani Ports and Special Economic Zone Limited"
    },
    {
        label: "ADANIPOWER",
        value: "Adani Power Limited"
    },
    {
        label: "ADFFOODS",
        value: "ADF Foods Limited"
    },
    {
        label: "ADL",
        value: "Archidply Decor Limited"
    },
    {
        label: "ADORWELD",
        value: "Ador Welding Limited"
    },
    {
        label: "ADROITINFO",
        value: "Adroit Infotech Limited"
    },
    {
        label: "ADSL",
        value: "Allied Digital Services Limited"
    },
    {
        label: "ADVANIHOTR",
        value: "Advani Hotels & Resorts (India) Limited"
    },
    {
        label: "ADVENZYMES",
        value: "Advanced Enzyme Technologies Limited"
    },
    {
        label: "AEGISLOG",
        value: "Aegis Logistics Limited"
    },
    {
        label: "AEROFLEX",
        value: "Aeroflex Industries Limited"
    },
    {
        label: "AETHER",
        value: "Aether Industries Limited"
    },
    {
        label: "AFCONS",
        value: "Afcons Infrastructure Limited"
    },
    {
        label: "AFFLE",
        value: "Affle (India) Limited"
    },
    {
        label: "AFFORDABLE",
        value: "Affordable Robotic & Automation Limited"
    },
    {
        label: "AFIL",
        value: "Akme Fintrade (India) Limited"
    },
    {
        label: "AGARIND",
        value: "Agarwal Industrial Corporation Limited"
    },
    {
        label: "AGI",
        value: "AGI Greenpac Limited"
    },
    {
        label: "AGIIL",
        value: "Agi Infra Limited"
    },
    {
        label: "AGRITECH",
        value: "Agri-Tech (India) Limited"
    },
    {
        label: "AGROPHOS",
        value: "Agro Phos India Limited"
    },
    {
        label: "AGSTRA",
        value: "AGS Transact Technologies Limited"
    },
    {
        label: "AHL",
        value: "Abans Holdings Limited"
    },
    {
        label: "AHLADA",
        value: "Ahlada Engineers Limited"
    },
    {
        label: "AHLEAST",
        value: "Asian Hotels (East) Limited"
    },
    {
        label: "AHLUCONT",
        value: "Ahluwalia Contracts (India) Limited"
    },
    {
        label: "AIAENG",
        value: "AIA Engineering Limited"
    },
    {
        label: "AIIL",
        value: "Authum Investment & Infrastructure Limited"
    },
    {
        label: "AIRAN",
        value: "Airan Limited"
    },
    {
        label: "AIROLAM",
        value: "Airo Lam limited"
    },
    {
        label: "AJANTPHARM",
        value: "Ajanta Pharma Limited"
    },
    {
        label: "AJMERA",
        value: "Ajmera Realty & Infra India Limited"
    },
    {
        label: "AJOONI",
        value: "Ajooni Biotech Limited"
    },
    {
        label: "AKASH",
        value: "Akash Infra-Projects Limited"
    },
    {
        label: "AKG",
        value: "Akg Exim Limited"
    },
    {
        label: "AKI",
        value: "AKI India Limited"
    },
    {
        label: "AKSHAR",
        value: "Akshar Spintex Limited"
    },
    {
        label: "AKSHARCHEM",
        value: "AksharChem India Limited"
    },
    {
        label: "AKSHOPTFBR",
        value: "Aksh Optifibre Limited"
    },
    {
        label: "AKUMS",
        value: "Akums Drugs and Pharmaceuticals Limited"
    },
    {
        label: "AKZOINDIA",
        value: "Akzo Nobel India Limited"
    },
    {
        label: "ALANKIT",
        value: "Alankit Limited"
    },
    {
        label: "ALBERTDAVD",
        value: "Albert David Limited"
    },
    {
        label: "ALEMBICLTD",
        value: "Alembic Limited"
    },
    {
        label: "ALICON",
        value: "Alicon Castalloy Limited"
    },
    {
        label: "ALKALI",
        value: "Alkali Metals Limited"
    },
    {
        label: "ALKEM",
        value: "Alkem Laboratories Limited"
    },
    {
        label: "ALKYLAMINE",
        value: "Alkyl Amines Chemicals Limited"
    },
    {
        label: "ALLCARGO",
        value: "Allcargo Logistics Limited"
    },
    {
        label: "ALLDIGI",
        value: "Alldigi Tech Limited"
    },
    {
        label: "ALMONDZ",
        value: "Almondz Global Securities Limited"
    },
    {
        label: "ALOKINDS",
        value: "Alok Industries Limited"
    },
    {
        label: "ALPA",
        value: "Alpa Laboratories Limited"
    },
    {
        label: "ALPHAGEO",
        value: "Alphageo (India) Limited"
    },
    {
        label: "AMBER",
        value: "Amber Enterprises India Limited"
    },
    {
        label: "AMBICAAGAR",
        value: "Ambica Agarbathies & Aroma industries Limited"
    },
    {
        label: "AMBIKCO",
        value: "Ambika Cotton Mills Limited"
    },
    {
        label: "AMBUJACEM",
        value: "Ambuja Cements Limited"
    },
    {
        label: "AMDIND",
        value: "AMD Industries Limited"
    },
    {
        label: "AMIORG",
        value: "Ami Organics Limited"
    },
    {
        label: "AMJLAND",
        value: "Amj Land Holdings Limited"
    },
    {
        label: "AMNPLST",
        value: "Amines & Plasticizers Limited"
    },
    {
        label: "AMRUTANJAN",
        value: "Amrutanjan Health Care Limited"
    },
    {
        label: "ANANDRATHI",
        value: "Anand Rathi Wealth Limited"
    },
    {
        label: "ANANTRAJ",
        value: "Anant Raj Limited"
    },
    {
        label: "ANDHRAPAP",
        value: "ANDHRA PAPER LIMITED"
    },
    {
        label: "ANDHRSUGAR",
        value: "The Andhra Sugars Limited"
    },
    {
        label: "ANGELONE",
        value: "Angel One Limited"
    },
    {
        label: "ANIKINDS",
        value: "Anik Industries Limited"
    },
    {
        label: "ANKITMETAL",
        value: "Ankit Metal & Power Limited"
    },
    {
        label: "ANMOL",
        value: "Anmol India Limited"
    },
    {
        label: "ANSALAPI",
        value: "Ansal Properties & Infrastructure Limited"
    },
    {
        label: "ANTGRAPHIC",
        value: "Antarctica Limited"
    },
    {
        label: "ANUP",
        value: "The Anup Engineering Limited"
    },
    {
        label: "ANURAS",
        value: "Anupam Rasayan India Limited"
    },
    {
        label: "APARINDS",
        value: "Apar Industries Limited"
    },
    {
        label: "APCL",
        value: "Anjani Portland Cement Limited"
    },
    {
        label: "APCOTEXIND",
        value: "Apcotex Industries Limited"
    },
    {
        label: "APEX",
        value: "Apex Frozen Foods Limited"
    },
    {
        label: "APLAPOLLO",
        value: "APL Apollo Tubes Limited"
    },
    {
        label: "APLLTD",
        value: "Alembic Pharmaceuticals Limited"
    },
    {
        label: "APOLLO",
        value: "Apollo Micro Systems Limited"
    },
    {
        label: "APOLLOHOSP",
        value: "Apollo Hospitals Enterprise Limited"
    },
    {
        label: "APOLLOPIPE",
        value: "Apollo Pipes Limited"
    },
    {
        label: "APOLLOTYRE",
        value: "Apollo Tyres Limited"
    },
    {
        label: "APOLSINHOT",
        value: "Apollo Sindoori Hotels Limited"
    },
    {
        label: "APTECHT",
        value: "Aptech Limited"
    },
    {
        label: "APTUS",
        value: "Aptus Value Housing Finance India Limited"
    },
    {
        label: "ARCHIDPLY",
        value: "Archidply Industries Limited"
    },
    {
        label: "ARCHIES",
        value: "Archies Limited"
    },
    {
        label: "ARE&M",
        value: "Amara Raja Energy & Mobility Limited"
    },
    {
        label: "ARENTERP",
        value: "Rajdarshan Industries Limited"
    },
    {
        label: "ARIES",
        value: "Aries Agro Limited"
    },
    {
        label: "ARIHANTCAP",
        value: "Arihant Capital Markets Limited"
    },
    {
        label: "ARIHANTSUP",
        value: "Arihant Superstructures Limited"
    },
    {
        label: "ARKADE",
        value: "Arkade Developers Limited"
    },
    {
        label: "ARMANFIN",
        value: "Arman Financial Services Limited"
    },
    {
        label: "AROGRANITE",
        value: "Aro Granite Industries Limited"
    },
    {
        label: "ARROWGREEN",
        value: "Arrow Greentech Limited"
    },
    {
        label: "ARSHIYA",
        value: "Arshiya Limited"
    },
    {
        label: "ARSSINFRA",
        value: "ARSS Infrastructure Projects Limited"
    },
    {
        label: "ARTEMISMED",
        value: "Artemis Medicare Services Limited"
    },
    {
        label: "ARTNIRMAN",
        value: "Art Nirman Limited"
    },
    {
        label: "ARVEE",
        value: "Arvee Laboratories (India) Limited"
    },
    {
        label: "ARVIND",
        value: "Arvind Limited"
    },
    {
        label: "ARVINDFASN",
        value: "Arvind Fashions Limited"
    },
    {
        label: "ARVSMART",
        value: "Arvind SmartSpaces Limited"
    },
    {
        label: "ASAHIINDIA",
        value: "Asahi India Glass Limited"
    },
    {
        label: "ASAHISONG",
        value: "Asahi Songwon Colors Limited"
    },
    {
        label: "ASAL",
        value: "Automotive Stampings and Assemblies Limited"
    },
    {
        label: "ASALCBR",
        value: "Associated Alcohols & Breweries Ltd."
    },
    {
        label: "ASHAPURMIN",
        value: "Ashapura Minechem Limited"
    },
    {
        label: "ASHIANA",
        value: "Ashiana Housing Limited"
    },
    {
        label: "ASHIMASYN",
        value: "Ashima Limited"
    },
    {
        label: "ASHOKA",
        value: "Ashoka Buildcon Limited"
    },
    {
        label: "ASHOKAMET",
        value: "Ashoka Metcast Limited"
    },
    {
        label: "ASHOKLEY",
        value: "Ashok Leyland Limited"
    },
    {
        label: "ASIANENE",
        value: "Asian Energy Services Limited"
    },
    {
        label: "ASIANHOTNR",
        value: "Asian Hotels (North) Limited"
    },
    {
        label: "ASIANPAINT",
        value: "Asian Paints Limited"
    },
    {
        label: "ASIANTILES",
        value: "Asian Granito India Limited"
    },
    {
        label: "ASKAUTOLTD",
        value: "ASK Automotive Limited"
    },
    {
        label: "ASMS",
        value: "Bartronics India Limited"
    },
    {
        label: "ASPINWALL",
        value: "Aspinwall and Company Limited"
    },
    {
        label: "ASTEC",
        value: "Astec LifeSciences Limited"
    },
    {
        label: "ASTERDM",
        value: "Aster DM Healthcare Limited"
    },
    {
        label: "ASTRAL",
        value: "Astral Limited"
    },
    {
        label: "ASTRAMICRO",
        value: "Astra Microwave Products Limited"
    },
    {
        label: "ASTRAZEN",
        value: "AstraZeneca Pharma India Limited"
    },
    {
        label: "ASTRON",
        value: "Astron Paper & Board Mill Limited"
    },
    {
        label: "ATALREAL",
        value: "Atal Realtech Limited"
    },
    {
        label: "ATAM",
        value: "Atam Valves Limited"
    },
    {
        label: "ATFL",
        value: "Agro Tech Foods Limited"
    },
    {
        label: "ATGL",
        value: "Adani Total Gas Limited"
    },
    {
        label: "ATL",
        value: "Allcargo Terminals Limited"
    },
    {
        label: "ATLANTAA",
        value: "ATLANTAA LIMITED"
    },
    {
        label: "ATUL",
        value: "Atul Limited"
    },
    {
        label: "ATULAUTO",
        value: "Atul Auto Limited"
    },
    {
        label: "AUBANK",
        value: "AU Small Finance Bank Limited"
    },
    {
        label: "AURIONPRO",
        value: "Aurionpro Solutions Limited"
    },
    {
        label: "AUROPHARMA",
        value: "Aurobindo Pharma Limited"
    },
    {
        label: "AURUM",
        value: "Aurum PropTech Limited"
    },
    {
        label: "AUSOMENT",
        value: "Ausom Enterprise Limited"
    },
    {
        label: "AUTOAXLES",
        value: "Automotive Axles Limited"
    },
    {
        label: "AUTOIND",
        value: "Autoline Industries Limited"
    },
    {
        label: "AVADHSUGAR",
        value: "Avadh Sugar & Energy Limited"
    },
    {
        label: "AVALON",
        value: "Avalon Technologies Limited"
    },
    {
        label: "AVANTEL",
        value: "Avantel Limited"
    },
    {
        label: "AVANTIFEED",
        value: "Avanti Feeds Limited"
    },
    {
        label: "AVG",
        value: "AVG Logistics Limited"
    },
    {
        label: "AVL",
        value: "Aditya Vision Limited"
    },
    {
        label: "AVONMORE",
        value: "Avonmore Capital & Management Services Limited"
    },
    {
        label: "AVROIND",
        value: "AVRO INDIA LIMITED"
    },
    {
        label: "AVTNPL",
        value: "AVT Natural Products Limited"
    },
    {
        label: "AWFIS",
        value: "Awfis Space Solutions Limited"
    },
    {
        label: "AWHCL",
        value: "Antony Waste Handling Cell Limited"
    },
    {
        label: "AWL",
        value: "Adani Wilmar Limited"
    },
    {
        label: "AXISBANK",
        value: "Axis Bank Limited"
    },
    {
        label: "AXISCADES",
        value: "AXISCADES Technologies Limited"
    },
    {
        label: "AXITA",
        value: "Axita Cotton Limited"
    },
    {
        label: "AYMSYNTEX",
        value: "AYM Syntex Limited"
    },
    {
        label: "AZAD",
        value: "Azad Engineering Limited"
    },
    {
        label: "BAFNAPH",
        value: "Bafna Pharmaceuticals Limited"
    },
    {
        label: "BAGFILMS",
        value: "B.A.G Films and Media Limited"
    },
    {
        label: "BAIDFIN",
        value: "Baid Finserv Limited"
    },
    {
        label: "BAJAJ-AUTO",
        value: "Bajaj Auto Limited"
    },
    {
        label: "BAJAJCON",
        value: "Bajaj Consumer Care Limited"
    },
    {
        label: "BAJAJELEC",
        value: "Bajaj Electricals Limited"
    },
    {
        label: "BAJAJFINSV",
        value: "Bajaj Finserv Limited"
    },
    {
        label: "BAJAJHCARE",
        value: "Bajaj Healthcare Limited"
    },
    {
        label: "BAJAJHFL",
        value: "Bajaj Housing Finance Limited"
    },
    {
        label: "BAJAJHIND",
        value: "Bajaj Hindusthan Sugar Limited"
    },
    {
        label: "BAJAJHLDNG",
        value: "Bajaj Holdings & Investment Limited"
    },
    {
        label: "BAJEL",
        value: "Bajel Projects Limited"
    },
    {
        label: "BAJFINANCE",
        value: "Bajaj Finance Limited"
    },
    {
        label: "BALAJEE",
        value: "Shree Tirupati Balajee Agro Trading Company Limited"
    },
    {
        label: "BALAJITELE",
        value: "Balaji Telefilms Limited"
    },
    {
        label: "BALAMINES",
        value: "Balaji Amines Limited"
    },
    {
        label: "BALAXI",
        value: "BALAXI PHARMACEUTICALS LIMITED"
    },
    {
        label: "BALKRISHNA",
        value: "Balkrishna Paper Mills Limited"
    },
    {
        label: "BALKRISIND",
        value: "Balkrishna Industries Limited"
    },
    {
        label: "BALMLAWRIE",
        value: "Balmer Lawrie & Company Limited"
    },
    {
        label: "BALPHARMA",
        value: "Bal Pharma Limited"
    },
    {
        label: "BALRAMCHIN",
        value: "Balrampur Chini Mills Limited"
    },
    {
        label: "BALUFORGE",
        value: "Balu Forge Industries Limited"
    },
    {
        label: "BANARBEADS",
        value: "Banaras Beads Limited"
    },
    {
        label: "BANARISUG",
        value: "Bannari Amman Sugars Limited"
    },
    {
        label: "BANCOINDIA",
        value: "Banco Products (I) Limited"
    },
    {
        label: "BANDHANBNK",
        value: "Bandhan Bank Limited"
    },
    {
        label: "BANG",
        value: "Bang Overseas Limited"
    },
    {
        label: "BANKA",
        value: "Banka BioLoo Limited"
    },
    {
        label: "BANKBARODA",
        value: "Bank of Baroda"
    },
    {
        label: "BANKINDIA",
        value: "Bank of India"
    },
    {
        label: "BANSALWIRE",
        value: "Bansal Wire Industries Limited"
    },
    {
        label: "BANSWRAS",
        value: "Banswara Syntex Limited"
    },
    {
        label: "BARBEQUE",
        value: "Barbeque Nation Hospitality Limited"
    },
    {
        label: "BASF",
        value: "BASF India Limited"
    },
    {
        label: "BASML",
        value: "Bannari Amman Spinning Mills Limited"
    },
    {
        label: "BATAINDIA",
        value: "Bata India Limited"
    },
    {
        label: "BAYERCROP",
        value: "Bayer Cropscience Limited"
    },
    {
        label: "BBL",
        value: "Bharat Bijlee Limited"
    },
    {
        label: "BBOX",
        value: "Black Box Limited"
    },
    {
        label: "BBTC",
        value: "Bombay Burmah Trading Corporation Limited"
    },
    {
        label: "BBTCL",
        value: "B&B Triplewall Containers Limited"
    },
    {
        label: "BCLIND",
        value: "Bcl Industries Limited"
    },
    {
        label: "BCONCEPTS",
        value: "Brand Concepts Limited"
    },
    {
        label: "BDL",
        value: "Bharat Dynamics Limited"
    },
    {
        label: "BEARDSELL",
        value: "Beardsell Limited"
    },
    {
        label: "BECTORFOOD",
        value: "Mrs. Bectors Food Specialities Limited"
    },
    {
        label: "BEDMUTHA",
        value: "Bedmutha Industries Limited"
    },
    {
        label: "BEL",
        value: "Bharat Electronics Limited"
    },
    {
        label: "BEML",
        value: "BEML Limited"
    },
    {
        label: "BEPL",
        value: "Bhansali Engineering Polymers Limited"
    },
    {
        label: "BERGEPAINT",
        value: "Berger Paints (I) Limited"
    },
    {
        label: "BESTAGRO",
        value: "Best Agrolife Limited"
    },
    {
        label: "BFINVEST",
        value: "BF Investment Limited"
    },
    {
        label: "BFUTILITIE",
        value: "BF Utilities Limited"
    },
    {
        label: "BGRENERGY",
        value: "BGR Energy Systems Limited"
    },
    {
        label: "BHAGCHEM",
        value: "Bhagiradha Chemicals & Industries Limited"
    },
    {
        label: "BHAGERIA",
        value: "Bhageria Industries Limited"
    },
    {
        label: "BHAGYANGR",
        value: "Bhagyanagar India Limited"
    },
    {
        label: "BHANDARI",
        value: "Bhandari Hosiery Exports Limited"
    },
    {
        label: "BHARATFORG",
        value: "Bharat Forge Limited"
    },
    {
        label: "BHARATGEAR",
        value: "Bharat Gears Limited"
    },
    {
        label: "BHARATRAS",
        value: "Bharat Rasayan Limited"
    },
    {
        label: "BHARATWIRE",
        value: "Bharat Wire Ropes Limited"
    },
    {
        label: "BHARTIARTL",
        value: "Bharti Airtel Limited"
    },
    {
        label: "BHARTIHEXA",
        value: "Bharti Hexacom Limited"
    },
    {
        label: "BHEL",
        value: "Bharat Heavy Electricals Limited"
    },
    {
        label: "BIGBLOC",
        value: "Bigbloc Construction Limited"
    },
    {
        label: "BIKAJI",
        value: "Bikaji Foods International Limited"
    },
    {
        label: "BIL",
        value: "Bhartiya International Limited"
    },
    {
        label: "BINANIIND",
        value: "Binani Industries Limited"
    },
    {
        label: "BIOCON",
        value: "Biocon Limited"
    },
    {
        label: "BIOFILCHEM",
        value: "Biofil Chemicals & Pharmaceuticals Limited"
    },
    {
        label: "BIRLACABLE",
        value: "Birla Cable Limited"
    },
    {
        label: "BIRLACORPN",
        value: "Birla Corporation Limited"
    },
    {
        label: "BIRLAMONEY",
        value: "Aditya Birla Money Limited"
    },
    {
        label: "BLACKBUCK",
        value: "Zinka Logistics Solutions Limited"
    },
    {
        label: "BLAL",
        value: "BEML Land Assets Limited"
    },
    {
        label: "BLBLIMITED",
        value: "BLB Limited"
    },
    {
        label: "BLISSGVS",
        value: "Bliss GVS Pharma Limited"
    },
    {
        label: "BLKASHYAP",
        value: "B. L. Kashyap and Sons Limited"
    },
    {
        label: "BLS",
        value: "BLS International Services Limited"
    },
    {
        label: "BLSE",
        value: "BLS E-Services Limited"
    },
    {
        label: "BLUECHIP",
        value: "Blue Chip India Limited"
    },
    {
        label: "BLUEDART",
        value: "Blue Dart Express Limited"
    },
    {
        label: "BLUEJET",
        value: "Blue Jet Healthcare Limited"
    },
    {
        label: "BLUESTARCO",
        value: "Blue Star Limited"
    },
    {
        label: "BODALCHEM",
        value: "Bodal Chemicals Limited"
    },
    {
        label: "BOMDYEING",
        value: "Bombay Dyeing & Mfg Company Limited"
    },
    {
        label: "BOROLTD",
        value: "Borosil Limited"
    },
    {
        label: "BORORENEW",
        value: "BOROSIL RENEWABLES LIMITED"
    },
    {
        label: "BOROSCI",
        value: "Borosil Scientific Limited"
    },
    {
        label: "BOSCHLTD",
        value: "Bosch Limited"
    },
    {
        label: "BPCL",
        value: "Bharat Petroleum Corporation Limited"
    },
    {
        label: "BPL",
        value: "BPL Limited"
    },
    {
        label: "BRIGADE",
        value: "Brigade Enterprises Limited"
    },
    {
        label: "BRITANNIA",
        value: "Britannia Industries Limited"
    },
    {
        label: "BRNL",
        value: "Bharat Road Network Limited"
    },
    {
        label: "BROOKS",
        value: "Brooks Laboratories Limited"
    },
    {
        label: "BSE",
        value: "BSE Limited"
    },
    {
        label: "BSHSL",
        value: "Bombay Super Hybrid Seeds Limited"
    },
    {
        label: "BSL",
        value: "BSL Limited"
    },
    {
        label: "BSOFT",
        value: "BIRLASOFT LIMITED"
    },
    {
        label: "BTML",
        value: "Bodhi Tree Multimedia Limited"
    },
    {
        label: "BURNPUR",
        value: "Burnpur Cement Limited"
    },
    {
        label: "BUTTERFLY",
        value: "Butterfly Gandhimathi Appliances Limited"
    },
    {
        label: "BVCL",
        value: "Barak Valley Cements Limited"
    },
    {
        label: "BYKE",
        value: "The Byke Hospitality Ltd"
    },
    {
        label: "CALSOFT",
        value: "California Software Company Limited"
    },
    {
        label: "CAMLINFINE",
        value: "Camlin Fine Sciences Limited"
    },
    {
        label: "CAMPUS",
        value: "Campus Activewear Limited"
    },
    {
        label: "CAMS",
        value: "Computer Age Management Services Limited"
    },
    {
        label: "CANBK",
        value: "Canara Bank"
    },
    {
        label: "CANFINHOME",
        value: "Can Fin Homes Limited"
    },
    {
        label: "CANTABIL",
        value: "Cantabil Retail India Limited"
    },
    {
        label: "CAPACITE",
        value: "Capacit'e Infraprojects Limited"
    },
    {
        label: "CAPITALSFB",
        value: "Capital Small Finance Bank Limited"
    },
    {
        label: "CAPLIPOINT",
        value: "Caplin Point Laboratories Limited"
    },
    {
        label: "CAPTRUST",
        value: "Capital Trust Limited"
    },
    {
        label: "CARBORUNIV",
        value: "Carborundum Universal Limited"
    },
    {
        label: "CAREERP",
        value: "Career Point Limited"
    },
    {
        label: "CARERATING",
        value: "CARE Ratings Limited"
    },
    {
        label: "CARTRADE",
        value: "Cartrade Tech Limited"
    },
    {
        label: "CARYSIL",
        value: "CARYSIL LIMITED"
    },
    {
        label: "CASTROLIND",
        value: "Castrol India Limited"
    },
    {
        label: "CCCL",
        value: "Consolidated Construction Consortium Limited"
    },
    {
        label: "CCHHL",
        value: "Country Club Hospitality & Holidays Limited"
    },
    {
        label: "CCL",
        value: "CCL Products (India) Limited"
    },
    {
        label: "CDSL",
        value: "Central Depository Services (India) Limited"
    },
    {
        label: "CEATLTD",
        value: "CEAT Limited"
    },
    {
        label: "CEIGALL",
        value: "Ceigall India Limited"
    },
    {
        label: "CELEBRITY",
        value: "Celebrity Fashions Limited"
    },
    {
        label: "CELLO",
        value: "Cello World Limited"
    },
    {
        label: "CENTENKA",
        value: "Century Enka Limited"
    },
    {
        label: "CENTEXT",
        value: "Century Extrusions Limited"
    },
    {
        label: "CENTRALBK",
        value: "Central Bank of India"
    },
    {
        label: "CENTRUM",
        value: "Centrum Capital Limited"
    },
    {
        label: "CENTUM",
        value: "Centum Electronics Limited"
    },
    {
        label: "CENTURYPLY",
        value: "Century Plyboards (India) Limited"
    },
    {
        label: "CERA",
        value: "Cera Sanitaryware Limited"
    },
    {
        label: "CEREBRAINT",
        value: "Cerebra Integrated Technologies Limited"
    },
    {
        label: "CESC",
        value: "CESC Limited"
    },
    {
        label: "CGCL",
        value: "Capri Global Capital Limited"
    },
    {
        label: "CGPOWER",
        value: "CG Power and Industrial Solutions Limited"
    },
    {
        label: "CHALET",
        value: "Chalet Hotels Limited"
    },
    {
        label: "CHAMBLFERT",
        value: "Chambal Fertilizers & Chemicals Limited"
    },
    {
        label: "CHEMBOND",
        value: "Chembond Chemicals Ltd"
    },
    {
        label: "CHEMCON",
        value: "Chemcon Speciality Chemicals Limited"
    },
    {
        label: "CHEMFAB",
        value: "Chemfab Alkalis Limited"
    },
    {
        label: "CHEMPLASTS",
        value: "Chemplast Sanmar Limited"
    },
    {
        label: "CHENNPETRO",
        value: "Chennai Petroleum Corporation Limited"
    },
    {
        label: "CHEVIOT",
        value: "Cheviot Company Limited"
    },
    {
        label: "CHOICEIN",
        value: "Choice International Limited"
    },
    {
        label: "CHOLAFIN",
        value: "Cholamandalam Investment and Finance Company Limited"
    },
    {
        label: "CHOLAHLDNG",
        value: "Cholamandalam Financial Holdings Limited"
    },
    {
        label: "CIEINDIA",
        value: "CIE Automotive India Limited"
    },
    {
        label: "CIGNITITEC",
        value: "Cigniti Technologies Limited"
    },
    {
        label: "CINELINE",
        value: "Cineline India Limited"
    },
    {
        label: "CINEVISTA",
        value: "Cinevista Limited"
    },
    {
        label: "CIPLA",
        value: "Cipla Limited"
    },
    {
        label: "CLEAN",
        value: "Clean Science and Technology Limited"
    },
    {
        label: "CLEDUCATE",
        value: "CL Educate Limited"
    },
    {
        label: "CLSEL",
        value: "Chaman Lal Setia Exports Limited"
    },
    {
        label: "CMSINFO",
        value: "CMS Info Systems Limited"
    },
    {
        label: "COALINDIA",
        value: "Coal India Limited"
    },
    {
        label: "COASTCORP",
        value: "Coastal Corporation Limited"
    },
    {
        label: "COCHINSHIP",
        value: "Cochin Shipyard Limited"
    },
    {
        label: "COFORGE",
        value: "Coforge Limited"
    },
    {
        label: "COLPAL",
        value: "Colgate Palmolive (India) Limited"
    },
    {
        label: "COMPUSOFT",
        value: "Compucom Software Limited"
    },
    {
        label: "COMSYN",
        value: "Commercial Syn Bags Limited"
    },
    {
        label: "CONCOR",
        value: "Container Corporation of India Limited"
    },
    {
        label: "CONCORDBIO",
        value: "Concord Biotech Limited"
    },
    {
        label: "CONFIPET",
        value: "Confidence Petroleum India Limited"
    },
    {
        label: "CONSOFINVT",
        value: "Consolidated Finvest & Holdings Limited"
    },
    {
        label: "CONTROLPR",
        value: "Control Print Limited"
    },
    {
        label: "CORALFINAC",
        value: "Coral India Finance & Housing Limited"
    },
    {
        label: "CORDSCABLE",
        value: "Cords Cable Industries Limited"
    },
    {
        label: "COROMANDEL",
        value: "Coromandel International Limited"
    },
    {
        label: "COSMOFIRST",
        value: "COSMO FIRST LIMITED"
    },
    {
        label: "COUNCODOS",
        value: "Country Condo's Limited"
    },
    {
        label: "CRAFTSMAN",
        value: "Craftsman Automation Limited"
    },
    {
        label: "CREATIVE",
        value: "Creative Newtech Limited"
    },
    {
        label: "CREATIVEYE",
        value: "Creative Eye Limited"
    },
    {
        label: "CREDITACC",
        value: "CREDITACCESS GRAMEEN LIMITED"
    },
    {
        label: "CREST",
        value: "Crest Ventures Limited"
    },
    {
        label: "CRISIL",
        value: "CRISIL Limited"
    },
    {
        label: "CROMPTON",
        value: "Crompton Greaves Consumer Electricals Limited"
    },
    {
        label: "CROWN",
        value: "Crown Lifters Limited"
    },
    {
        label: "CSBBANK",
        value: "CSB Bank Limited"
    },
    {
        label: "CSLFINANCE",
        value: "CSL Finance Limited"
    },
    {
        label: "CTE",
        value: "Cambridge Technology Enterprises Limited"
    },
    {
        label: "CUB",
        value: "City Union Bank Limited"
    },
    {
        label: "CUBEXTUB",
        value: "Cubex Tubings Limited"
    },
    {
        label: "CUMMINSIND",
        value: "Cummins India Limited"
    },
    {
        label: "CUPID",
        value: "Cupid Limited"
    },
    {
        label: "CYBERMEDIA",
        value: "Cyber Media (India) Limited"
    },
    {
        label: "CYBERTECH",
        value: "Cybertech Systems And Software Limited"
    },
    {
        label: "CYIENT",
        value: "Cyient Limited"
    },
    {
        label: "CYIENTDLM",
        value: "Cyient DLM Limited"
    },
    {
        label: "DABUR",
        value: "Dabur India Limited"
    },
    {
        label: "DALBHARAT",
        value: "Dalmia Bharat Limited"
    },
    {
        label: "DALMIASUG",
        value: "Dalmia Bharat Sugar and Industries Limited"
    },
    {
        label: "DAMODARIND",
        value: "Damodar Industries Limited"
    },
    {
        label: "DANGEE",
        value: "Dangee Dums Limited"
    },
    {
        label: "DATAMATICS",
        value: "Datamatics Global Services Limited"
    },
    {
        label: "DATAPATTNS",
        value: "Data Patterns (India) Limited"
    },
    {
        label: "DAVANGERE",
        value: "Davangere Sugar Company Limited"
    },
    {
        label: "DBCORP",
        value: "D.B.Corp Limited"
    },
    {
        label: "DBEIL",
        value: "Deepak Builders & Engineers India Limited"
    },
    {
        label: "DBL",
        value: "Dilip Buildcon Limited"
    },
    {
        label: "DBOL",
        value: "Dhampur Bio Organics Limited"
    },
    {
        label: "DBREALTY",
        value: "Valor Estate Limited"
    },
    {
        label: "DBSTOCKBRO",
        value: "DB (International) Stock Brokers Limited"
    },
    {
        label: "DCAL",
        value: "Dishman Carbogen Amcis Limited"
    },
    {
        label: "DCBBANK",
        value: "DCB Bank Limited"
    },
    {
        label: "DCI",
        value: "Dc Infotech And Communication Limited"
    },
    {
        label: "DCM",
        value: "DCM  Limited"
    },
    {
        label: "DCMFINSERV",
        value: "DCM Financial Services Limited"
    },
    {
        label: "DCMNVL",
        value: "DCM Nouvelle Limited"
    },
    {
        label: "DCMSHRIRAM",
        value: "DCM Shriram Limited"
    },
    {
        label: "DCMSRIND",
        value: "DCM Shriram Industries Limited"
    },
    {
        label: "DCW",
        value: "DCW Limited"
    },
    {
        label: "DCXINDIA",
        value: "DCX Systems Limited"
    },
    {
        label: "DECCANCE",
        value: "Deccan Cements Limited"
    },
    {
        label: "DEEDEV",
        value: "DEE Development Engineers Limited"
    },
    {
        label: "DEEPAKFERT",
        value: "Deepak Fertilizers and Petrochemicals Corporation Limited"
    },
    {
        label: "DEEPAKNTR",
        value: "Deepak Nitrite Limited"
    },
    {
        label: "DEEPINDS",
        value: "Deep Industries Limited"
    },
    {
        label: "DELHIVERY",
        value: "Delhivery Limited"
    },
    {
        label: "DELPHIFX",
        value: "DELPHI WORLD MONEY LIMITED"
    },
    {
        label: "DELTACORP",
        value: "Delta Corp Limited"
    },
    {
        label: "DELTAMAGNT",
        value: "Delta Manufacturing Limited"
    },
    {
        label: "DEN",
        value: "Den Networks Limited"
    },
    {
        label: "DENORA",
        value: "De Nora India Limited"
    },
    {
        label: "DEVIT",
        value: "Dev Information Technology Limited"
    },
    {
        label: "DEVYANI",
        value: "Devyani International Limited"
    },
    {
        label: "DGCONTENT",
        value: "Digicontent Limited"
    },
    {
        label: "DHAMPURSUG",
        value: "Dhampur Sugar Mills Limited"
    },
    {
        label: "DHANBANK",
        value: "Dhanlaxmi Bank Limited"
    },
    {
        label: "DHANI",
        value: "Dhani Services Limited"
    },
    {
        label: "DHANUKA",
        value: "Dhanuka Agritech Limited"
    },
    {
        label: "DHARMAJ",
        value: "Dharmaj Crop Guard Limited"
    },
    {
        label: "DHRUV",
        value: "Dhruv Consultancy Services Limited"
    },
    {
        label: "DHUNINV",
        value: "Dhunseri Investments Limited"
    },
    {
        label: "DIACABS",
        value: "Diamond Power Infrastructure Limited"
    },
    {
        label: "DIAMINESQ",
        value: "Diamines & Chemicals Limited"
    },
    {
        label: "DIAMONDYD",
        value: "Prataap Snacks Limited"
    },
    {
        label: "DICIND",
        value: "DIC India Limited"
    },
    {
        label: "DIFFNKG",
        value: "Diffusion Engineers Limited"
    },
    {
        label: "DIGIDRIVE",
        value: "Digidrive Distributors Limited"
    },
    {
        label: "DIGISPICE",
        value: "DiGiSPICE Technologies Limited"
    },
    {
        label: "DIGJAMLMTD",
        value: "Digjam Limited"
    },
    {
        label: "DIL",
        value: "Debock Industries Limited"
    },
    {
        label: "DISHTV",
        value: "Dish TV India Limited"
    },
    {
        label: "DIVGIITTS",
        value: "Divgi Torqtransfer Systems Limited"
    },
    {
        label: "DIVISLAB",
        value: "Divi's Laboratories Limited"
    },
    {
        label: "DIXON",
        value: "Dixon Technologies (India) Limited"
    },
    {
        label: "DJML",
        value: "DJ Mediaprint & Logistics Limited"
    },
    {
        label: "DLF",
        value: "DLF Limited"
    },
    {
        label: "DLINKINDIA",
        value: "D-Link (India) Limited"
    },
    {
        label: "DMART",
        value: "Avenue Supermarts Limited"
    },
    {
        label: "DMCC",
        value: "DMCC SPECIALITY CHEMICALS LIMITED"
    },
    {
        label: "DNAMEDIA",
        value: "Diligent Media Corporation Limited"
    },
    {
        label: "DODLA",
        value: "Dodla Dairy Limited"
    },
    {
        label: "DOLATALGO",
        value: "Dolat Algotech Limited"
    },
    {
        label: "DOLLAR",
        value: "Dollar Industries Limited"
    },
    {
        label: "DOLPHIN",
        value: "Dolphin Offshore Enterprises (India) Limited"
    },
    {
        label: "DOMS",
        value: "DOMS Industries Limited"
    },
    {
        label: "DONEAR",
        value: "Donear Industries Limited"
    },
    {
        label: "DPABHUSHAN",
        value: "D. P. Abhushan Limited"
    },
    {
        label: "DPSCLTD",
        value: "DPSC Limited"
    },
    {
        label: "DPWIRES",
        value: "D P Wires Limited"
    },
    {
        label: "DRCSYSTEMS",
        value: "DRC Systems India Limited"
    },
    {
        label: "DREAMFOLKS",
        value: "Dreamfolks Services Limited"
    },
    {
        label: "DREDGECORP",
        value: "Dredging Corporation of India Limited"
    },
    {
        label: "DRREDDY",
        value: "Dr. Reddy's Laboratories Limited"
    },
    {
        label: "DSSL",
        value: "Dynacons Systems & Solutions Limited"
    },
    {
        label: "DTIL",
        value: "Dhunseri Tea & Industries Limited"
    },
    {
        label: "DUCON",
        value: "Ducon Infratechnologies Limited"
    },
    {
        label: "DVL",
        value: "Dhunseri Ventures Limited"
    },
    {
        label: "DWARKESH",
        value: "Dwarikesh Sugar Industries Limited"
    },
    {
        label: "DYCL",
        value: "Dynamic Cables Limited"
    },
    {
        label: "DYNAMATECH",
        value: "Dynamatic Technologies Limited"
    },
    {
        label: "DYNPRO",
        value: "Dynemic Products Limited"
    },
    {
        label: "E2E",
        value: "E2E Networks Limited"
    },
    {
        label: "EASEMYTRIP",
        value: "Easy Trip Planners Limited"
    },
    {
        label: "ECLERX",
        value: "eClerx Services Limited"
    },
    {
        label: "ECOSMOBLTY",
        value: "Ecos (India) Mobility & Hospitality Limited"
    },
    {
        label: "EDELWEISS",
        value: "Edelweiss Financial Services Limited"
    },
    {
        label: "EICHERMOT",
        value: "Eicher Motors Limited"
    },
    {
        label: "EIDPARRY",
        value: "EID Parry India Limited"
    },
    {
        label: "EIFFL",
        value: "Euro India Fresh Foods Limited"
    },
    {
        label: "EIHAHOTELS",
        value: "EIH Associated Hotels Limited"
    },
    {
        label: "EIHOTEL",
        value: "EIH Limited"
    },
    {
        label: "EIMCOELECO",
        value: "Eimco Elecon (India) Limited"
    },
    {
        label: "EKC",
        value: "Everest Kanto Cylinder Limited"
    },
    {
        label: "ELDEHSG",
        value: "Eldeco Housing And Industries Limited"
    },
    {
        label: "ELECON",
        value: "Elecon Engineering Company Limited"
    },
    {
        label: "ELECTCAST",
        value: "Electrosteel Castings Limited"
    },
    {
        label: "ELECTHERM",
        value: "Electrotherm (India) Limited"
    },
    {
        label: "ELGIEQUIP",
        value: "Elgi Equipments Limited"
    },
    {
        label: "ELGIRUBCO",
        value: "Elgi Rubber Company Limited"
    },
    {
        label: "ELIN",
        value: "Elin Electronics Limited"
    },
    {
        label: "EMAMILTD",
        value: "Emami Limited"
    },
    {
        label: "EMAMIPAP",
        value: "Emami Paper Mills Limited"
    },
    {
        label: "EMAMIREAL",
        value: "Emami Realty Limited"
    },
    {
        label: "EMBDL",
        value: "Equinox India Developments Limited"
    },
    {
        label: "EMCURE",
        value: "Emcure Pharmaceuticals Limited"
    },
    {
        label: "EMIL",
        value: "Electronics Mart India Limited"
    },
    {
        label: "EMKAY",
        value: "Emkay Global Financial Services Limited"
    },
    {
        label: "EMMBI",
        value: "Emmbi Industries Limited"
    },
    {
        label: "EMSLIMITED",
        value: "EMS Limited"
    },
    {
        label: "EMUDHRA",
        value: "eMudhra Limited"
    },
    {
        label: "ENDURANCE",
        value: "Endurance Technologies Limited"
    },
    {
        label: "ENERGYDEV",
        value: "Energy Development Company Limited"
    },
    {
        label: "ENGINERSIN",
        value: "Engineers India Limited"
    },
    {
        label: "ENIL",
        value: "Entertainment Network (India) Limited"
    },
    {
        label: "ENTERO",
        value: "Entero Healthcare Solutions Limited"
    },
    {
        label: "EPACK",
        value: "EPACK Durable Limited"
    },
    {
        label: "EPIGRAL",
        value: "Epigral Limited"
    },
    {
        label: "EPL",
        value: "EPL Limited"
    },
    {
        label: "EQUIPPP",
        value: "Equippp Social Impact Technologies Limited"
    },
    {
        label: "EQUITASBNK",
        value: "Equitas Small Finance Bank Limited"
    },
    {
        label: "ERIS",
        value: "Eris Lifesciences Limited"
    },
    {
        label: "EROSMEDIA",
        value: "Eros International Media Limited"
    },
    {
        label: "ESABINDIA",
        value: "Esab India Limited"
    },
    {
        label: "ESAFSFB",
        value: "ESAF Small Finance Bank Limited"
    },
    {
        label: "ESCORTS",
        value: "Escorts Kubota Limited"
    },
    {
        label: "ESSARSHPNG",
        value: "Essar Shipping Limited"
    },
    {
        label: "ESSENTIA",
        value: "Integra Essentia Limited"
    },
    {
        label: "ESTER",
        value: "Ester Industries Limited"
    },
    {
        label: "ETHOSLTD",
        value: "Ethos Limited"
    },
    {
        label: "EUREKAFORB",
        value: "Eureka Forbes Limited"
    },
    {
        label: "EUROTEXIND",
        value: "Eurotex Industries and Exports Limited"
    },
    {
        label: "EVEREADY",
        value: "Eveready Industries India Limited"
    },
    {
        label: "EVERESTIND",
        value: "Everest Industries Limited"
    },
    {
        label: "EXCEL",
        value: "Excel Realty N Infra Limited"
    },
    {
        label: "EXCELINDUS",
        value: "Excel Industries Limited"
    },
    {
        label: "EXICOM",
        value: "Exicom Tele-Systems Limited"
    },
    {
        label: "EXIDEIND",
        value: "Exide Industries Limited"
    },
    {
        label: "EXPLEOSOL",
        value: "Expleo Solutions Limited"
    },
    {
        label: "EXXARO",
        value: "Exxaro Tiles Limited"
    },
    {
        label: "FACT",
        value: "Fertilizers and Chemicals Travancore Limited"
    },
    {
        label: "FAIRCHEMOR",
        value: "Fairchem Organics Limited"
    },
    {
        label: "FAZE3Q",
        value: "Faze Three Limited"
    },
    {
        label: "FCL",
        value: "Fineotex Chemical Limited"
    },
    {
        label: "FCSSOFT",
        value: "FCS Software Solutions Limited"
    },
    {
        label: "FDC",
        value: "FDC Limited"
    },
    {
        label: "FEDERALBNK",
        value: "The Federal Bank  Limited"
    },
    {
        label: "FEDFINA",
        value: "Fedbank Financial Services Limited"
    },
    {
        label: "FEL",
        value: "Future Enterprises Limited"
    },
    {
        label: "FELDVR",
        value: "Future Enterprises Limited"
    },
    {
        label: "FIBERWEB",
        value: "Fiberweb (India) Limited"
    },
    {
        label: "FIEMIND",
        value: "Fiem Industries Limited"
    },
    {
        label: "FILATEX",
        value: "Filatex India Limited"
    },
    {
        label: "FILATFASH",
        value: "Filatex Fashions Limited"
    },
    {
        label: "FINCABLES",
        value: "Finolex Cables Limited"
    },
    {
        label: "FINEORG",
        value: "Fine Organic Industries Limited"
    },
    {
        label: "FINOPB",
        value: "Fino Payments Bank Limited"
    },
    {
        label: "FINPIPE",
        value: "Finolex Industries Limited"
    },
    {
        label: "FIRSTCRY",
        value: "Brainbees Solutions Limited"
    },
    {
        label: "FIVESTAR",
        value: "Five-Star Business Finance Limited"
    },
    {
        label: "FLAIR",
        value: "Flair Writing Industries Limited"
    },
    {
        label: "FLEXITUFF",
        value: "Flexituff Ventures International Limited"
    },
    {
        label: "FLFL",
        value: "Future Lifestyle Fashions Limited"
    },
    {
        label: "FLUOROCHEM",
        value: "Gujarat Fluorochemicals Limited"
    },
    {
        label: "FMGOETZE",
        value: "Federal-Mogul Goetze (India) Limited."
    },
    {
        label: "FMNL",
        value: "Future Market Networks Limited"
    },
    {
        label: "FOCUS",
        value: "Focus Lighting and Fixtures Limited"
    },
    {
        label: "FOODSIN",
        value: "Foods & Inns Limited"
    },
    {
        label: "FORCEMOT",
        value: "FORCE MOTORS LTD"
    },
    {
        label: "FORTIS",
        value: "Fortis Healthcare Limited"
    },
    {
        label: "FOSECOIND",
        value: "Foseco India Limited"
    },
    {
        label: "FSL",
        value: "Firstsource Solutions Limited"
    },
    {
        label: "FUSION",
        value: "Fusion Finance Limited"
    },
    {
        label: "GABRIEL",
        value: "Gabriel India Limited"
    },
    {
        label: "GAEL",
        value: "Gujarat Ambuja Exports Limited"
    },
    {
        label: "GAIL",
        value: "GAIL (India) Limited"
    },
    {
        label: "GALAPREC",
        value: "Gala Precision Engineering Limited"
    },
    {
        label: "GALAXYSURF",
        value: "Galaxy Surfactants Limited"
    },
    {
        label: "GALLANTT",
        value: "Gallantt Ispat Limited"
    },
    {
        label: "GANDHAR",
        value: "Gandhar Oil Refinery (India) Limited"
    },
    {
        label: "GANDHITUBE",
        value: "Gandhi Special Tubes Limited"
    },
    {
        label: "GANECOS",
        value: "Ganesha Ecosphere Limited"
    },
    {
        label: "GANESHBE",
        value: "Ganesh Benzoplast Limited"
    },
    {
        label: "GANESHHOUC",
        value: "Ganesh Housing Corporation Limited"
    },
    {
        label: "GANGAFORGE",
        value: "Ganga Forging Limited"
    },
    {
        label: "GANGESSECU",
        value: "Ganges Securities Limited"
    },
    {
        label: "GARFIBRES",
        value: "Garware Technical Fibres Limited"
    },
    {
        label: "GARUDA",
        value: "Garuda Construction and Engineering Limited"
    },
    {
        label: "GATECH",
        value: "GACM Technologies Limited"
    },
    {
        label: "GATECHDVR",
        value: "GACM Technologies Limited"
    },
    {
        label: "GATEWAY",
        value: "Gateway Distriparks Limited"
    },
    {
        label: "GAYAPROJ",
        value: "Gayatri Projects Limited"
    },
    {
        label: "GEECEE",
        value: "GeeCee Ventures Limited"
    },
    {
        label: "GEEKAYWIRE",
        value: "Geekay Wires Limited"
    },
    {
        label: "GENCON",
        value: "Generic Engineering Construction and Projects Limited"
    },
    {
        label: "GENESYS",
        value: "Genesys International Corporation Limited"
    },
    {
        label: "GENSOL",
        value: "Gensol Engineering Limited"
    },
    {
        label: "GENUSPAPER",
        value: "Genus Paper & Boards Limited"
    },
    {
        label: "GENUSPOWER",
        value: "Genus Power Infrastructures Limited"
    },
    {
        label: "GEOJITFSL",
        value: "Geojit Financial Services Limited"
    },
    {
        label: "GEPIL",
        value: "GE Power India Limited"
    },
    {
        label: "GESHIP",
        value: "The Great Eastern Shipping Company Limited"
    },
    {
        label: "GFLLIMITED",
        value: "GFL Limited"
    },
    {
        label: "GHCL",
        value: "GHCL Limited"
    },
    {
        label: "GHCLTEXTIL",
        value: "GHCL Textiles Limited"
    },
    {
        label: "GICHSGFIN",
        value: "GIC Housing Finance Limited"
    },
    {
        label: "GICRE",
        value: "General Insurance Corporation of India"
    },
    {
        label: "GILLANDERS",
        value: "Gillanders Arbuthnot & Company Limited"
    },
    {
        label: "GILLETTE",
        value: "Gillette India Limited"
    },
    {
        label: "GINNIFILA",
        value: "Ginni Filaments Limited"
    },
    {
        label: "GIPCL",
        value: "Gujarat Industries Power Company Limited"
    },
    {
        label: "GKWLIMITED",
        value: "GKW Limited"
    },
    {
        label: "GLAND",
        value: "Gland Pharma Limited"
    },
    {
        label: "GLAXO",
        value: "GlaxoSmithKline Pharmaceuticals Limited"
    },
    {
        label: "GLENMARK",
        value: "Glenmark Pharmaceuticals Limited"
    },
    {
        label: "GLFL",
        value: "Gujarat Lease Financing Limited"
    },
    {
        label: "GLOBAL",
        value: "Global Education Limited"
    },
    {
        label: "GLOBALE",
        value: "Globale Tessile Limited"
    },
    {
        label: "GLOBALVECT",
        value: "Global Vectra Helicorp Limited"
    },
    {
        label: "GLOBE",
        value: "Globe Textiles (India) Limited"
    },
    {
        label: "GLOBUSSPR",
        value: "Globus Spirits Limited"
    },
    {
        label: "GLOSTERLTD",
        value: "Gloster Limited"
    },
    {
        label: "GLS",
        value: "Glenmark Life Sciences Limited"
    },
    {
        label: "GMBREW",
        value: "GM Breweries Limited"
    },
    {
        label: "GMDCLTD",
        value: "Gujarat Mineral Development Corporation Limited"
    },
    {
        label: "GMMPFAUDLR",
        value: "GMM Pfaudler Limited"
    },
    {
        label: "GMRINFRA",
        value: "GMR Airports Infrastructure Limited"
    },
    {
        label: "GMRP&UI",
        value: "GMR Power and Urban Infra Limited"
    },
    {
        label: "GNA",
        value: "GNA Axles Limited"
    },
    {
        label: "GNFC",
        value: "Gujarat Narmada Valley Fertilizers and Chemicals Limited"
    },
    {
        label: "GOACARBON",
        value: "Goa Carbon Limited"
    },
    {
        label: "GOCLCORP",
        value: "GOCL Corporation Limited"
    },
    {
        label: "GOCOLORS",
        value: "Go Fashion (India) Limited"
    },
    {
        label: "GODAVARIB",
        value: "Godavari Biorefineries Limited"
    },
    {
        label: "GODFRYPHLP",
        value: "Godfrey Phillips India Limited"
    },
    {
        label: "GODHA",
        value: "Godha Cabcon & Insulation Limited"
    },
    {
        label: "GODIGIT",
        value: "Go Digit General Insurance Limited"
    },
    {
        label: "GODREJAGRO",
        value: "Godrej Agrovet Limited"
    },
    {
        label: "GODREJCP",
        value: "Godrej Consumer Products Limited"
    },
    {
        label: "GODREJIND",
        value: "Godrej Industries Limited"
    },
    {
        label: "GODREJPROP",
        value: "Godrej Properties Limited"
    },
    {
        label: "GOENKA",
        value: "Goenka Diamond and Jewels Limited"
    },
    {
        label: "GOKEX",
        value: "Gokaldas Exports Limited"
    },
    {
        label: "GOKUL",
        value: "Gokul Refoils and Solvent Limited"
    },
    {
        label: "GOKULAGRO",
        value: "Gokul Agro Resources Limited"
    },
    {
        label: "GOLDENTOBC",
        value: "Golden Tobacco Limited"
    },
    {
        label: "GOLDIAM",
        value: "Goldiam International Limited"
    },
    {
        label: "GOLDTECH",
        value: "AION-TECH SOLUTIONS LIMITED"
    },
    {
        label: "GOODLUCK",
        value: "Goodluck India Limited"
    },
    {
        label: "GOPAL",
        value: "Gopal Snacks Limited"
    },
    {
        label: "GOYALALUM",
        value: "Goyal Aluminiums Limited"
    },
    {
        label: "GPIL",
        value: "Godawari Power And Ispat limited"
    },
    {
        label: "GPPL",
        value: "Gujarat Pipavav Port Limited"
    },
    {
        label: "GPTHEALTH",
        value: "GPT Healthcare Limited"
    },
    {
        label: "GPTINFRA",
        value: "GPT Infraprojects Limited"
    },
    {
        label: "GRANULES",
        value: "Granules India Limited"
    },
    {
        label: "GRAPHITE",
        value: "Graphite India Limited"
    },
    {
        label: "GRASIM",
        value: "Grasim Industries Limited"
    },
    {
        label: "GRAVITA",
        value: "Gravita India Limited"
    },
    {
        label: "GREAVESCOT",
        value: "Greaves Cotton Limited"
    },
    {
        label: "GREENLAM",
        value: "Greenlam Industries Limited"
    },
    {
        label: "GREENPANEL",
        value: "Greenpanel Industries Limited"
    },
    {
        label: "GREENPLY",
        value: "Greenply Industries Limited"
    },
    {
        label: "GREENPOWER",
        value: "Orient Green Power Company Limited"
    },
    {
        label: "GRINDWELL",
        value: "Grindwell Norton Limited"
    },
    {
        label: "GRINFRA",
        value: "G R Infraprojects Limited"
    },
    {
        label: "GRMOVER",
        value: "GRM Overseas Limited"
    },
    {
        label: "GROBTEA",
        value: "The Grob Tea Company Limited"
    },
    {
        label: "GRPLTD",
        value: "GRP Limited"
    },
    {
        label: "GRSE",
        value: "Garden Reach Shipbuilders & Engineers Limited"
    },
    {
        label: "GRWRHITECH",
        value: "Garware Hi-Tech Films Limited"
    },
    {
        label: "GSFC",
        value: "Gujarat State Fertilizers & Chemicals Limited"
    },
    {
        label: "GSLSU",
        value: "Global Surfaces Limited"
    },
    {
        label: "GSPL",
        value: "Gujarat State Petronet Limited"
    },
    {
        label: "GSS",
        value: "GSS Infotech Limited"
    },
    {
        label: "GTECJAINX",
        value: "G-TEC JAINX EDUCATION LIMITED"
    },
    {
        label: "GTL",
        value: "GTL Limited"
    },
    {
        label: "GTLINFRA",
        value: "GTL Infrastructure Limited"
    },
    {
        label: "GTPL",
        value: "GTPL Hathway Limited"
    },
    {
        label: "GUFICBIO",
        value: "Gufic Biosciences Limited"
    },
    {
        label: "GUJALKALI",
        value: "Gujarat Alkalies and Chemicals Limited"
    },
    {
        label: "GUJAPOLLO",
        value: "Gujarat Apollo Industries Limited"
    },
    {
        label: "GUJGASLTD",
        value: "Gujarat Gas Limited"
    },
    {
        label: "GUJRAFFIA",
        value: "Gujarat Raffia Industries Limited"
    },
    {
        label: "GULFOILLUB",
        value: "Gulf Oil Lubricants India Limited"
    },
    {
        label: "GULFPETRO",
        value: "GP Petroleums Limited"
    },
    {
        label: "GULPOLY",
        value: "Gulshan Polyols Limited"
    },
    {
        label: "GVKPIL",
        value: "GVK Power & Infrastructure Limited"
    },
    {
        label: "GVPTECH",
        value: "GVP Infotech Limited"
    },
    {
        label: "GVT&D",
        value: "GE Vernova T&D India Limited"
    },
    {
        label: "HAL",
        value: "Hindustan Aeronautics Limited"
    },
    {
        label: "HAPPSTMNDS",
        value: "Happiest Minds Technologies Limited"
    },
    {
        label: "HAPPYFORGE",
        value: "Happy Forgings Limited"
    },
    {
        label: "HARDWYN",
        value: "Hardwyn India Limited"
    },
    {
        label: "HARIOMPIPE",
        value: "Hariom Pipe Industries Limited"
    },
    {
        label: "HARRMALAYA",
        value: "Harrisons  Malayalam Limited"
    },
    {
        label: "HARSHA",
        value: "Harsha Engineers International Limited"
    },
    {
        label: "HATHWAY",
        value: "Hathway Cable & Datacom Limited"
    },
    {
        label: "HATSUN",
        value: "Hatsun Agro Product Limited"
    },
    {
        label: "HAVELLS",
        value: "Havells India Limited"
    },
    {
        label: "HAVISHA",
        value: "Sri Havisha Hospitality and Infrastructure Limited"
    },
    {
        label: "HBLPOWER",
        value: "HBL Power Systems Limited"
    },
    {
        label: "HBSL",
        value: "HB Stockholdings Limited"
    },
    {
        label: "HCC",
        value: "Hindustan Construction Company Limited"
    },
    {
        label: "HCG",
        value: "Healthcare Global Enterprises Limited"
    },
    {
        label: "HCL-INSYS",
        value: "HCL Infosystems Limited"
    },
    {
        label: "HCLTECH",
        value: "HCL Technologies Limited"
    },
    {
        label: "HDFCAMC",
        value: "HDFC Asset Management Company Limited"
    },
    {
        label: "HDFCBANK",
        value: "HDFC Bank Limited"
    },
    {
        label: "HDFCLIFE",
        value: "HDFC Life Insurance Company Limited"
    },
    {
        label: "HDIL",
        value: "Housing Development and Infrastructure Limited"
    },
    {
        label: "HEADSUP",
        value: "Heads UP Ventures Limited"
    },
    {
        label: "HECPROJECT",
        value: "HEC Infra Projects Limited"
    },
    {
        label: "HEG",
        value: "HEG Limited"
    },
    {
        label: "HEIDELBERG",
        value: "HeidelbergCement India Limited"
    },
    {
        label: "HEMIPROP",
        value: "Hemisphere Properties India Limited"
    },
    {
        label: "HERANBA",
        value: "Heranba Industries Limited"
    },
    {
        label: "HERCULES",
        value: "Hercules Hoists Limited"
    },
    {
        label: "HERITGFOOD",
        value: "Heritage Foods Limited"
    },
    {
        label: "HEROMOTOCO",
        value: "Hero MotoCorp Limited"
    },
    {
        label: "HESTERBIO",
        value: "Hester Biosciences Limited"
    },
    {
        label: "HEUBACHIND",
        value: "Heubach Colorants India Limited"
    },
    {
        label: "HEXATRADEX",
        value: "Hexa Tradex Limited"
    },
    {
        label: "HFCL",
        value: "HFCL Limited"
    },
    {
        label: "HGINFRA",
        value: "H.G. Infra Engineering Limited"
    },
    {
        label: "HGS",
        value: "Hinduja Global Solutions Limited"
    },
    {
        label: "HIKAL",
        value: "Hikal Limited"
    },
    {
        label: "HIL",
        value: "HIL Limited"
    },
    {
        label: "HILTON",
        value: "Hilton Metal Forging Limited"
    },
    {
        label: "HIMATSEIDE",
        value: "Himatsingka Seide Limited"
    },
    {
        label: "HINDALCO",
        value: "Hindalco Industries Limited"
    },
    {
        label: "HINDCOMPOS",
        value: "Hindustan Composites Limited"
    },
    {
        label: "HINDCON",
        value: "Hindcon Chemicals Limited"
    },
    {
        label: "HINDCOPPER",
        value: "Hindustan Copper Limited"
    },
    {
        label: "HINDMOTORS",
        value: "Hindustan Motors Limited"
    },
    {
        label: "HINDNATGLS",
        value: "Hindusthan National Glass & Industries Limited"
    },
    {
        label: "HINDOILEXP",
        value: "Hindustan Oil Exploration Company Limited"
    },
    {
        label: "HINDPETRO",
        value: "Hindustan Petroleum Corporation Limited"
    },
    {
        label: "HINDUNILVR",
        value: "Hindustan Unilever Limited"
    },
    {
        label: "HINDWAREAP",
        value: "Hindware Home Innovation Limited"
    },
    {
        label: "HINDZINC",
        value: "Hindustan Zinc Limited"
    },
    {
        label: "HIRECT",
        value: "Hind Rectifiers Limited"
    },
    {
        label: "HISARMETAL",
        value: "Hisar Metal Industries Limited"
    },
    {
        label: "HITECH",
        value: "Hi-Tech Pipes Limited"
    },
    {
        label: "HITECHCORP",
        value: "Hitech Corporation Limited"
    },
    {
        label: "HITECHGEAR",
        value: "The Hi-Tech Gears Limited"
    },
    {
        label: "HLEGLAS",
        value: "HLE Glascoat Limited"
    },
    {
        label: "HLVLTD",
        value: "HLV LIMITED"
    },
    {
        label: "HMAAGRO",
        value: "HMA Agro Industries Limited"
    },
    {
        label: "HMT",
        value: "HMT Limited"
    },
    {
        label: "HMVL",
        value: "Hindustan Media Ventures Limited"
    },
    {
        label: "HNDFDS",
        value: "Hindustan Foods Limited"
    },
    {
        label: "HOMEFIRST",
        value: "Home First Finance Company India Limited"
    },
    {
        label: "HONASA",
        value: "Honasa Consumer Limited"
    },
    {
        label: "HONAUT",
        value: "Honeywell Automation India Limited"
    },
    {
        label: "HONDAPOWER",
        value: "Honda India Power Products Limited"
    },
    {
        label: "HOVS",
        value: "HOV Services Limited"
    },
    {
        label: "HPAL",
        value: "HP Adhesives Limited"
    },
    {
        label: "HPIL",
        value: "Hindprakash Industries Limited"
    },
    {
        label: "HPL",
        value: "HPL Electric & Power Limited"
    },
    {
        label: "HSCL",
        value: "Himadri Speciality Chemical Limited"
    },
    {
        label: "HTMEDIA",
        value: "HT Media Limited"
    },
    {
        label: "HUBTOWN",
        value: "Hubtown Limited"
    },
    {
        label: "HUDCO",
        value: "Housing & Urban Development Corporation Limited"
    },
    {
        label: "HUHTAMAKI",
        value: "Huhtamaki India Limited"
    },
    {
        label: "HYBRIDFIN",
        value: "Hybrid Financial Services Limited"
    },
    {
        label: "HYUNDAI",
        value: "Hyundai Motor India Limited"
    },
    {
        label: "ICDSLTD",
        value: "ICDS Limited"
    },
    {
        label: "ICEMAKE",
        value: "Ice Make Refrigeration Limited"
    },
    {
        label: "ICICIBANK",
        value: "ICICI Bank Limited"
    },
    {
        label: "ICICIGI",
        value: "ICICI Lombard General Insurance Company Limited"
    },
    {
        label: "ICICIPRULI",
        value: "ICICI Prudential Life Insurance Company Limited"
    },
    {
        label: "ICIL",
        value: "Indo Count Industries Limited"
    },
    {
        label: "ICRA",
        value: "ICRA Limited"
    },
    {
        label: "IDBI",
        value: "IDBI Bank Limited"
    },
    {
        label: "IDEA",
        value: "Vodafone Idea Limited"
    },
    {
        label: "IDEAFORGE",
        value: "Ideaforge Technology Limited"
    },
    {
        label: "IDFCFIRSTB",
        value: "IDFC First Bank Limited"
    },
    {
        label: "IEL",
        value: "Indiabulls Enterprises Limited"
    },
    {
        label: "IEX",
        value: "Indian Energy Exchange Limited"
    },
    {
        label: "IFBAGRO",
        value: "IFB Agro Industries Limited"
    },
    {
        label: "IFBIND",
        value: "IFB Industries Limited"
    },
    {
        label: "IFCI",
        value: "IFCI Limited"
    },
    {
        label: "IFGLEXPOR",
        value: "IFGL Refractories Limited"
    },
    {
        label: "IGARASHI",
        value: "Igarashi Motors India Limited"
    },
    {
        label: "IGL",
        value: "Indraprastha Gas Limited"
    },
    {
        label: "IGPL",
        value: "IG Petrochemicals Limited"
    },
    {
        label: "IIFL",
        value: "IIFL Finance Limited"
    },
    {
        label: "IIFLSEC",
        value: "IIFL Securities Limited"
    },
    {
        label: "IITL",
        value: "Industrial Investment Trust Limited"
    },
    {
        label: "IKIO",
        value: "IKIO Lighting Limited"
    },
    {
        label: "IL&FSENGG",
        value: "IL&FS Engineering and Construction Company Limited"
    },
    {
        label: "IL&FSTRANS",
        value: "IL&FS Transportation Networks Limited"
    },
    {
        label: "IMAGICAA",
        value: "Imagicaaworld Entertainment Limited"
    },
    {
        label: "IMFA",
        value: "Indian Metals & Ferro Alloys Limited"
    },
    {
        label: "IMPAL",
        value: "India Motor Parts and Accessories Limited"
    },
    {
        label: "IMPEXFERRO",
        value: "Impex Ferro Tech Limited"
    },
    {
        label: "INCREDIBLE",
        value: "INCREDIBLE INDUSTRIES LIMITED"
    },
    {
        label: "INDBANK",
        value: "Indbank Merchant Banking Services Limited"
    },
    {
        label: "INDGN",
        value: "Indegene Limited"
    },
    {
        label: "INDHOTEL",
        value: "The Indian Hotels Company Limited"
    },
    {
        label: "INDIACEM",
        value: "The India Cements Limited"
    },
    {
        label: "INDIAGLYCO",
        value: "India Glycols Limited"
    },
    {
        label: "INDIAMART",
        value: "Indiamart Intermesh Limited"
    },
    {
        label: "INDIANB",
        value: "Indian Bank"
    },
    {
        label: "INDIANCARD",
        value: "Indian Card Clothing Company Limited"
    },
    {
        label: "INDIANHUME",
        value: "Indian Hume Pipe Company Limited"
    },
    {
        label: "INDIASHLTR",
        value: "India Shelter Finance Corporation Limited"
    },
    {
        label: "INDIGO",
        value: "InterGlobe Aviation Limited"
    },
    {
        label: "INDIGOPNTS",
        value: "Indigo Paints Limited"
    },
    {
        label: "INDNIPPON",
        value: "India Nippon Electricals Limited"
    },
    {
        label: "INDOAMIN",
        value: "Indo Amines Limited"
    },
    {
        label: "INDOBORAX",
        value: "Indo Borax & Chemicals Limited"
    },
    {
        label: "INDOCO",
        value: "Indoco Remedies Limited"
    },
    {
        label: "INDORAMA",
        value: "Indo Rama Synthetics (India) Limited"
    },
    {
        label: "INDOSTAR",
        value: "IndoStar Capital Finance Limited"
    },
    {
        label: "INDOTECH",
        value: "Indo Tech Transformers Limited"
    },
    {
        label: "INDOTHAI",
        value: "Indo Thai Securities Limited"
    },
    {
        label: "INDOUS",
        value: "Indo Us Biotech Limited"
    },
    {
        label: "INDOWIND",
        value: "Indowind Energy Limited"
    },
    {
        label: "INDRAMEDCO",
        value: "Indraprastha Medical Corporation Limited"
    },
    {
        label: "INDSWFTLAB",
        value: "Ind-Swift Laboratories Limited"
    },
    {
        label: "INDSWFTLTD",
        value: "Ind-Swift Limited"
    },
    {
        label: "INDTERRAIN",
        value: "Indian Terrain Fashions Limited"
    },
    {
        label: "INDUSINDBK",
        value: "IndusInd Bank Limited"
    },
    {
        label: "INDUSTOWER",
        value: "Indus Towers Limited"
    },
    {
        label: "INFIBEAM",
        value: "Infibeam Avenues Limited"
    },
    {
        label: "INFOBEAN",
        value: "InfoBeans Technologies Limited"
    },
    {
        label: "INFOMEDIA",
        value: "Infomedia Press Limited"
    },
    {
        label: "INFY",
        value: "Infosys Limited"
    },
    {
        label: "INGERRAND",
        value: "Ingersoll Rand (India) Limited"
    },
    {
        label: "INNOVACAP",
        value: "Innova Captab Limited"
    },
    {
        label: "INOXGREEN",
        value: "Inox Green Energy Services Limited"
    },
    {
        label: "INOXINDIA",
        value: "INOX India Limited"
    },
    {
        label: "INOXWIND",
        value: "Inox Wind Limited"
    },
    {
        label: "INSECTICID",
        value: "Insecticides (India) Limited"
    },
    {
        label: "INSPIRISYS",
        value: "Inspirisys Solutions Limited"
    },
    {
        label: "INTELLECT",
        value: "Intellect Design Arena Limited"
    },
    {
        label: "INTENTECH",
        value: "Intense Technologies Limited"
    },
    {
        label: "INTERARCH",
        value: "Interarch Building Products Limited"
    },
    {
        label: "INTLCONV",
        value: "International Conveyors Limited"
    },
    {
        label: "INVENTURE",
        value: "Inventure Growth & Securities Limited"
    },
    {
        label: "IOB",
        value: "Indian Overseas Bank"
    },
    {
        label: "IOC",
        value: "Indian Oil Corporation Limited"
    },
    {
        label: "IOLCP",
        value: "IOL Chemicals and Pharmaceuticals Limited"
    },
    {
        label: "IONEXCHANG",
        value: "ION Exchange (India) Limited"
    },
    {
        label: "IPCALAB",
        value: "IPCA Laboratories Limited"
    },
    {
        label: "IPL",
        value: "India Pesticides Limited"
    },
    {
        label: "IRB",
        value: "IRB Infrastructure Developers Limited"
    },
    {
        label: "IRCON",
        value: "Ircon International Limited"
    },
    {
        label: "IRCTC",
        value: "Indian Railway Catering And Tourism Corporation Limited"
    },
    {
        label: "IREDA",
        value: "Indian Renewable Energy Development Agency Limited"
    },
    {
        label: "IRFC",
        value: "Indian Railway Finance Corporation Limited"
    },
    {
        label: "IRIS",
        value: "Iris Business Services Limited"
    },
    {
        label: "IRISDOREME",
        value: "Iris Clothings Limited"
    },
    {
        label: "IRMENERGY",
        value: "IRM Energy Limited"
    },
    {
        label: "ISEC",
        value: "ICICI Securities Limited"
    },
    {
        label: "ISFT",
        value: "Intrasoft Technologies Limited"
    },
    {
        label: "ISGEC",
        value: "Isgec Heavy Engineering Limited"
    },
    {
        label: "ITC",
        value: "ITC Limited"
    },
    {
        label: "ITDC",
        value: "India Tourism Development Corporation Limited"
    },
    {
        label: "ITDCEM",
        value: "ITD Cementation India Limited"
    },
    {
        label: "ITI",
        value: "ITI Limited"
    },
    {
        label: "IVC",
        value: "IL&FS Investment Managers Limited"
    },
    {
        label: "IVP",
        value: "IVP Limited"
    },
    {
        label: "IWEL",
        value: "Inox Wind Energy Limited"
    },
    {
        label: "IXIGO",
        value: "Le Travenues Technology Limited"
    },
    {
        label: "IZMO",
        value: "IZMO Limited"
    },
    {
        label: "J&KBANK",
        value: "The Jammu & Kashmir Bank Limited"
    },
    {
        label: "JAGRAN",
        value: "Jagran Prakashan Limited"
    },
    {
        label: "JAGSNPHARM",
        value: "Jagsonpal Pharmaceuticals Limited"
    },
    {
        label: "JAIBALAJI",
        value: "Jai Balaji Industries Limited"
    },
    {
        label: "JAICORPLTD",
        value: "Jai Corp Limited"
    },
    {
        label: "JAIPURKURT",
        value: "Nandani Creation Limited"
    },
    {
        label: "JAMNAAUTO",
        value: "Jamna Auto Industries Limited"
    },
    {
        label: "JASH",
        value: "Jash Engineering Limited"
    },
    {
        label: "JAYAGROGN",
        value: "Jayant Agro Organics Limited"
    },
    {
        label: "JAYBARMARU",
        value: "Jay Bharat Maruti Limited"
    },
    {
        label: "JAYNECOIND",
        value: "Jayaswal Neco Industries Limited"
    },
    {
        label: "JAYSREETEA",
        value: "Jayshree Tea & Industries Limited"
    },
    {
        label: "JBCHEPHARM",
        value: "JB Chemicals & Pharmaceuticals Limited"
    },
    {
        label: "JBMA",
        value: "JBM Auto Limited"
    },
    {
        label: "JCHAC",
        value: "Johnson Controls - Hitachi Air Conditioning India Limited"
    },
    {
        label: "JETFREIGHT",
        value: "Jet Freight Logistics Limited"
    },
    {
        label: "JGCHEM",
        value: "J.G.Chemicals Limited"
    },
    {
        label: "JHS",
        value: "JHS Svendgaard Laboratories Limited"
    },
    {
        label: "JINDALPHOT",
        value: "Jindal Photo Limited"
    },
    {
        label: "JINDALPOLY",
        value: "Jindal Poly Films Limited"
    },
    {
        label: "JINDALSAW",
        value: "Jindal Saw Limited"
    },
    {
        label: "JINDALSTEL",
        value: "Jindal Steel & Power Limited"
    },
    {
        label: "JINDRILL",
        value: "Jindal Drilling And Industries Limited"
    },
    {
        label: "JINDWORLD",
        value: "Jindal Worldwide Limited"
    },
    {
        label: "JIOFIN",
        value: "Jio Financial Services Limited"
    },
    {
        label: "JISLDVREQS",
        value: "Jain Irrigation Systems Limited"
    },
    {
        label: "JISLJALEQS",
        value: "Jain Irrigation Systems Limited"
    },
    {
        label: "JITFINFRA",
        value: "JITF Infralogistics Limited"
    },
    {
        label: "JKCEMENT",
        value: "JK Cement Limited"
    },
    {
        label: "JKIL",
        value: "J.Kumar Infraprojects Limited"
    },
    {
        label: "JKLAKSHMI",
        value: "JK Lakshmi Cement Limited"
    },
    {
        label: "JKPAPER",
        value: "JK Paper Limited"
    },
    {
        label: "JKTYRE",
        value: "JK Tyre & Industries Limited"
    },
    {
        label: "JLHL",
        value: "Jupiter Life Line Hospitals Limited"
    },
    {
        label: "JMA",
        value: "Jullundur Motor Agency (Delhi) Limited"
    },
    {
        label: "JMFINANCIL",
        value: "JM Financial Limited"
    },
    {
        label: "JNKINDIA",
        value: "JNK India Limited"
    },
    {
        label: "JOCIL",
        value: "Jocil Limited"
    },
    {
        label: "JPOLYINVST",
        value: "Jindal Poly Investment and Finance Company Limited"
    },
    {
        label: "JPPOWER",
        value: "Jaiprakash Power Ventures Limited"
    },
    {
        label: "JSFB",
        value: "Jana Small Finance Bank Limited"
    },
    {
        label: "JSL",
        value: "Jindal Stainless Limited"
    },
    {
        label: "JSWENERGY",
        value: "JSW Energy Limited"
    },
    {
        label: "JSWHL",
        value: "JSW Holdings Limited"
    },
    {
        label: "JSWINFRA",
        value: "JSW Infrastructure Limited"
    },
    {
        label: "JSWSTEEL",
        value: "JSW Steel Limited"
    },
    {
        label: "JTEKTINDIA",
        value: "Jtekt India Limited"
    },
    {
        label: "JTLIND",
        value: "JTL INDUSTRIES LIMITED"
    },
    {
        label: "JUBLFOOD",
        value: "Jubilant Foodworks Limited"
    },
    {
        label: "JUBLINGREA",
        value: "Jubilant Ingrevia Limited"
    },
    {
        label: "JUBLPHARMA",
        value: "Jubilant Pharmova Limited"
    },
    {
        label: "JUNIPER",
        value: "Juniper Hotels Limited"
    },
    {
        label: "JUSTDIAL",
        value: "Just Dial Limited"
    },
    {
        label: "JWL",
        value: "Jupiter Wagons Limited"
    },
    {
        label: "JYOTHYLAB",
        value: "Jyothy Labs Limited"
    },
    {
        label: "JYOTICNC",
        value: "Jyoti CNC Automation Limited"
    },
    {
        label: "JYOTISTRUC",
        value: "Jyoti Structures Limited"
    },
    {
        label: "KABRAEXTRU",
        value: "Kabra Extrusion Technik Limited"
    },
    {
        label: "KAJARIACER",
        value: "Kajaria Ceramics Limited"
    },
    {
        label: "KAKATCEM",
        value: "Kakatiya Cement Sugar & Industries Limited"
    },
    {
        label: "KALAMANDIR",
        value: "Sai Silks (Kalamandir) Limited"
    },
    {
        label: "KALYANI",
        value: "Kalyani Commercials Limited"
    },
    {
        label: "KALYANIFRG",
        value: "Kalyani Forge Limited"
    },
    {
        label: "KALYANKJIL",
        value: "Kalyan Jewellers India Limited"
    },
    {
        label: "KAMATHOTEL",
        value: "Kamat Hotels (I) Limited"
    },
    {
        label: "KAMDHENU",
        value: "Kamdhenu Limited"
    },
    {
        label: "KAMOPAINTS",
        value: "Kamdhenu Ventures Limited"
    },
    {
        label: "KANANIIND",
        value: "Kanani Industries Limited"
    },
    {
        label: "KANORICHEM",
        value: "Kanoria Chemicals & Industries Limited"
    },
    {
        label: "KANPRPLA",
        value: "Kanpur Plastipack Limited"
    },
    {
        label: "KANSAINER",
        value: "Kansai Nerolac Paints Limited"
    },
    {
        label: "KAPSTON",
        value: "Kapston Services Limited"
    },
    {
        label: "KARMAENG",
        value: "Karma Energy Limited"
    },
    {
        label: "KARURVYSYA",
        value: "Karur Vysya Bank Limited"
    },
    {
        label: "KAUSHALYA",
        value: "Kaushalya Infrastructure Development Corporation Limited"
    },
    {
        label: "KAVVERITEL",
        value: "Kavveri Telecom Products Limited"
    },
    {
        label: "KAYA",
        value: "Kaya Limited"
    },
    {
        label: "KAYNES",
        value: "Kaynes Technology India Limited"
    },
    {
        label: "KBCGLOBAL",
        value: "KBC Global Limited"
    },
    {
        label: "KCP",
        value: "KCP Limited"
    },
    {
        label: "KCPSUGIND",
        value: "KCP Sugar and Industries Corporation Limited"
    },
    {
        label: "KDDL",
        value: "KDDL Limited"
    },
    {
        label: "KEC",
        value: "KEC International Limited"
    },
    {
        label: "KECL",
        value: "Kirloskar Electric Company Limited"
    },
    {
        label: "KEEPLEARN",
        value: "DSJ Keep Learning Limited"
    },
    {
        label: "KEI",
        value: "KEI Industries Limited"
    },
    {
        label: "KELLTONTEC",
        value: "Kellton Tech Solutions Limited"
    },
    {
        label: "KERNEX",
        value: "Kernex Microsystems (India) Limited"
    },
    {
        label: "KESORAMIND",
        value: "Kesoram Industries Limited"
    },
    {
        label: "KEYFINSERV",
        value: "Keynote Financial Services Limited"
    },
    {
        label: "KFINTECH",
        value: "Kfin Technologies Limited"
    },
    {
        label: "KHADIM",
        value: "Khadim India Limited"
    },
    {
        label: "KHAICHEM",
        value: "Khaitan Chemicals & Fertilizers Limited"
    },
    {
        label: "KHAITANLTD",
        value: "Khaitan (India) Limited"
    },
    {
        label: "KHANDSE",
        value: "Khandwala Securities Limited"
    },
    {
        label: "KICL",
        value: "Kalyani Investment Company Limited"
    },
    {
        label: "KILITCH",
        value: "Kilitch Drugs (India) Limited"
    },
    {
        label: "KIMS",
        value: "Krishna Institute of Medical Sciences Limited"
    },
    {
        label: "KINGFA",
        value: "Kingfa Science & Technology (India) Limited"
    },
    {
        label: "KIOCL",
        value: "KIOCL Limited"
    },
    {
        label: "KIRIINDUS",
        value: "Kiri Industries Limited"
    },
    {
        label: "KIRLOSBROS",
        value: "Kirloskar Brothers Limited"
    },
    {
        label: "KIRLOSENG",
        value: "Kirloskar Oil Engines Limited"
    },
    {
        label: "KIRLOSIND",
        value: "Kirloskar Industries Limited"
    },
    {
        label: "KIRLPNU",
        value: "Kirloskar Pneumatic Company Limited"
    },
    {
        label: "KITEX",
        value: "Kitex Garments Limited"
    },
    {
        label: "KKCL",
        value: "Kewal Kiran Clothing Limited"
    },
    {
        label: "KMEW",
        value: "Knowledge Marine & Engineering Works Limited"
    },
    {
        label: "KMSUGAR",
        value: "K.M.Sugar Mills Limited"
    },
    {
        label: "KNRCON",
        value: "KNR Constructions Limited"
    },
    {
        label: "KOHINOOR",
        value: "Kohinoor Foods Limited"
    },
    {
        label: "KOKUYOCMLN",
        value: "Kokuyo Camlin Limited"
    },
    {
        label: "KOLTEPATIL",
        value: "Kolte - Patil Developers Limited"
    },
    {
        label: "KOPRAN",
        value: "Kopran Limited"
    },
    {
        label: "KOTAKBANK",
        value: "Kotak Mahindra Bank Limited"
    },
    {
        label: "KOTARISUG",
        value: "Kothari Sugars And Chemicals Limited"
    },
    {
        label: "KOTHARIPET",
        value: "Kothari Petrochemicals Limited"
    },
    {
        label: "KOTHARIPRO",
        value: "Kothari Products Limited"
    },
    {
        label: "KPEL",
        value: "K.P. Energy Limited"
    },
    {
        label: "KPIGREEN",
        value: "KPI Green Energy Limited"
    },
    {
        label: "KPIL",
        value: "Kalpataru Projects International Limited"
    },
    {
        label: "KPITTECH",
        value: "KPIT Technologies Limited"
    },
    {
        label: "KPRMILL",
        value: "K.P.R. Mill Limited"
    },
    {
        label: "KRBL",
        value: "KRBL Limited"
    },
    {
        label: "KREBSBIO",
        value: "Krebs Biochemicals and Industries Limited"
    },
    {
        label: "KRIDHANINF",
        value: "Kridhan Infra Limited"
    },
    {
        label: "KRISHANA",
        value: "Krishana Phoschem Limited"
    },
    {
        label: "KRITI",
        value: "Kriti Industries (India) Limited"
    },
    {
        label: "KRITIKA",
        value: "Kritika Wires Limited"
    },
    {
        label: "KRITINUT",
        value: "Kriti Nutrients Limited"
    },
    {
        label: "KRN",
        value: "KRN Heat Exchanger and Refrigeration Limited"
    },
    {
        label: "KRONOX",
        value: "Kronox Lab Sciences Limited"
    },
    {
        label: "KROSS",
        value: "Kross Limited"
    },
    {
        label: "KRSNAA",
        value: "Krsnaa Diagnostics Limited"
    },
    {
        label: "KRYSTAL",
        value: "Krystal Integrated Services Limited"
    },
    {
        label: "KSB",
        value: "Ksb Limited"
    },
    {
        label: "KSCL",
        value: "Kaveri Seed Company Limited"
    },
    {
        label: "KSHITIJPOL",
        value: "Kshitij Polyline Limited"
    },
    {
        label: "KSL",
        value: "Kalyani Steels Limited"
    },
    {
        label: "KSOLVES",
        value: "Ksolves India Limited"
    },
    {
        label: "KTKBANK",
        value: "The Karnataka Bank Limited"
    },
    {
        label: "KUANTUM",
        value: "Kuantum Papers Limited"
    },
    {
        label: "LAGNAM",
        value: "Lagnam Spintex Limited"
    },
    {
        label: "LAKPRE",
        value: "Lakshmi Precision Screws Limited"
    },
    {
        label: "LAL",
        value: "Lorenzini Apparels Limited"
    },
    {
        label: "LALPATHLAB",
        value: "Dr. Lal Path Labs Ltd."
    },
    {
        label: "LAMBODHARA",
        value: "Lambodhara Textiles Limited"
    },
    {
        label: "LANCORHOL",
        value: "Lancor Holdings Limited"
    },
    {
        label: "LANDMARK",
        value: "Landmark Cars Limited"
    },
    {
        label: "LAOPALA",
        value: "La Opala RG Limited"
    },
    {
        label: "LASA",
        value: "Lasa Supergenerics Limited"
    },
    {
        label: "LATENTVIEW",
        value: "Latent View Analytics Limited"
    },
    {
        label: "LATTEYS",
        value: "Latteys Industries Limited"
    },
    {
        label: "LAURUSLABS",
        value: "Laurus Labs Limited"
    },
    {
        label: "LAXMICOT",
        value: "Laxmi Cotspin Limited"
    },
    {
        label: "LCCINFOTEC",
        value: "LCC Infotech Limited"
    },
    {
        label: "LEMONTREE",
        value: "Lemon Tree Hotels Limited"
    },
    {
        label: "LEXUS",
        value: "Lexus Granito (India) Limited"
    },
    {
        label: "LFIC",
        value: "Lakshmi Finance & Industrial Corporation Limited"
    },
    {
        label: "LGBBROSLTD",
        value: "LG Balakrishnan & Bros Limited"
    },
    {
        label: "LGHL",
        value: "Laxmi Goldorna House Limited"
    },
    {
        label: "LIBAS",
        value: "Libas Consumer Products Limited"
    },
    {
        label: "LIBERTSHOE",
        value: "Liberty Shoes Limited"
    },
    {
        label: "LICHSGFIN",
        value: "LIC Housing Finance Limited"
    },
    {
        label: "LICI",
        value: "Life Insurance Corporation Of India"
    },
    {
        label: "LIKHITHA",
        value: "Likhitha Infrastructure Limited"
    },
    {
        label: "LINC",
        value: "Linc Limited"
    },
    {
        label: "LINCOLN",
        value: "Lincoln Pharmaceuticals Limited"
    },
    {
        label: "LINDEINDIA",
        value: "Linde India Limited"
    },
    {
        label: "LLOYDSENGG",
        value: "LLOYDS ENGINEERING WORKS LIMITED"
    },
    {
        label: "LLOYDSENT",
        value: "Lloyds Enterprises Limited"
    },
    {
        label: "LLOYDSME",
        value: "Lloyds Metals And Energy Limited"
    },
    {
        label: "LMW",
        value: "LMW Limited"
    },
    {
        label: "LODHA",
        value: "Macrotech Developers Limited"
    },
    {
        label: "LOKESHMACH",
        value: "Lokesh Machines Limited"
    },
    {
        label: "LORDSCHLO",
        value: "Lords Chloro Alkali Limited"
    },
    {
        label: "LOTUSEYE",
        value: "Lotus Eye Hospital and Institute Limited"
    },
    {
        label: "LOVABLE",
        value: "Lovable Lingerie Limited"
    },
    {
        label: "LOYALTEX",
        value: "Loyal Textile Mills Limited"
    },
    {
        label: "LPDC",
        value: "Landmark Property Development Company Limited"
    },
    {
        label: "LT",
        value: "Larsen & Toubro Limited"
    },
    {
        label: "LTF",
        value: "L&T Finance Limited"
    },
    {
        label: "LTFOODS",
        value: "LT Foods Limited"
    },
    {
        label: "LTIM",
        value: "LTIMindtree Limited"
    },
    {
        label: "LTTS",
        value: "L&T Technology Services Limited"
    },
    {
        label: "LUMAXIND",
        value: "Lumax Industries Limited"
    },
    {
        label: "LUMAXTECH",
        value: "Lumax Auto Technologies Limited"
    },
    {
        label: "LUPIN",
        value: "Lupin Limited"
    },
    {
        label: "LUXIND",
        value: "Lux Industries Limited"
    },
    {
        label: "LXCHEM",
        value: "Laxmi Organic Industries Limited"
    },
    {
        label: "LYKALABS",
        value: "Lyka Labs Limited"
    },
    {
        label: "LYPSAGEMS",
        value: "Lypsa Gems & Jewellery Limited"
    },
    {
        label: "M&M",
        value: "Mahindra & Mahindra Limited"
    },
    {
        label: "M&MFIN",
        value: "Mahindra & Mahindra Financial Services Limited"
    },
    {
        label: "MAANALU",
        value: "Maan Aluminium Limited"
    },
    {
        label: "MACPOWER",
        value: "Macpower CNC Machines Limited"
    },
    {
        label: "MADHAV",
        value: "Madhav Marbles and Granites Limited"
    },
    {
        label: "MADHUCON",
        value: "Madhucon Projects Limited"
    },
    {
        label: "MADRASFERT",
        value: "Madras Fertilizers Limited"
    },
    {
        label: "MAGADSUGAR",
        value: "Magadh Sugar & Energy Limited"
    },
    {
        label: "MAGNUM",
        value: "Magnum Ventures Limited"
    },
    {
        label: "MAHABANK",
        value: "Bank of Maharashtra"
    },
    {
        label: "MAHAPEXLTD",
        value: "Maha Rashtra Apex Corporation Limited"
    },
    {
        label: "MAHASTEEL",
        value: "Mahamaya Steel Industries Limited"
    },
    {
        label: "MAHEPC",
        value: "Mahindra EPC Irrigation Limited"
    },
    {
        label: "MAHESHWARI",
        value: "Maheshwari Logistics Limited"
    },
    {
        label: "MAHLIFE",
        value: "Mahindra Lifespace Developers Limited"
    },
    {
        label: "MAHLOG",
        value: "Mahindra Logistics Limited"
    },
    {
        label: "MAHSCOOTER",
        value: "Maharashtra Scooters Limited"
    },
    {
        label: "MAHSEAMLES",
        value: "Maharashtra Seamless Limited"
    },
    {
        label: "MAITHANALL",
        value: "Maithan Alloys Limited"
    },
    {
        label: "MALLCOM",
        value: "Mallcom (India) Limited"
    },
    {
        label: "MALUPAPER",
        value: "Malu Paper Mills Limited"
    },
    {
        label: "MANAKALUCO",
        value: "Manaksia Aluminium Company Limited"
    },
    {
        label: "MANAKCOAT",
        value: "Manaksia Coated Metals & Industries Limited"
    },
    {
        label: "MANAKSIA",
        value: "Manaksia Limited"
    },
    {
        label: "MANAKSTEEL",
        value: "Manaksia Steels Limited"
    },
    {
        label: "MANALIPETC",
        value: "Manali Petrochemicals Limited"
    },
    {
        label: "MANAPPURAM",
        value: "Manappuram Finance Limited"
    },
    {
        label: "MANBA",
        value: "Manba Finance Limited"
    },
    {
        label: "MANCREDIT",
        value: "Mangal Credit and Fincorp Limited"
    },
    {
        label: "MANGALAM",
        value: "Mangalam Drugs And Organics Limited"
    },
    {
        label: "MANGCHEFER",
        value: "Mangalore Chemicals & Fertilizers Limited"
    },
    {
        label: "MANGLMCEM",
        value: "Mangalam Cement Limited"
    },
    {
        label: "MANINDS",
        value: "Man Industries (India) Limited"
    },
    {
        label: "MANINFRA",
        value: "Man Infraconstruction Limited"
    },
    {
        label: "MANKIND",
        value: "Mankind Pharma Limited"
    },
    {
        label: "MANOMAY",
        value: "Manomay Tex India Limited"
    },
    {
        label: "MANORAMA",
        value: "Manorama Industries Limited"
    },
    {
        label: "MANORG",
        value: "Mangalam Organics Limited"
    },
    {
        label: "MANUGRAPH",
        value: "Manugraph India Limited"
    },
    {
        label: "MANYAVAR",
        value: "Vedant Fashions Limited"
    },
    {
        label: "MAPMYINDIA",
        value: "C.E. Info Systems Limited"
    },
    {
        label: "MARALOVER",
        value: "Maral Overseas Limited"
    },
    {
        label: "MARATHON",
        value: "Marathon Nextgen Realty Limited"
    },
    {
        label: "MARICO",
        value: "Marico Limited"
    },
    {
        label: "MARINE",
        value: "Marine Electricals (India) Limited"
    },
    {
        label: "MARKSANS",
        value: "Marksans Pharma Limited"
    },
    {
        label: "MARSHALL",
        value: "Marshall Machines Limited"
    },
    {
        label: "MARUTI",
        value: "Maruti Suzuki India Limited"
    },
    {
        label: "MASFIN",
        value: "MAS Financial Services Limited"
    },
    {
        label: "MASKINVEST",
        value: "Mask Investments Limited"
    },
    {
        label: "MASTEK",
        value: "Mastek Limited"
    },
    {
        label: "MASTERTR",
        value: "Master Trust Limited"
    },
    {
        label: "MATRIMONY",
        value: "Matrimony.Com Limited"
    },
    {
        label: "MAWANASUG",
        value: "Mawana Sugars Limited"
    },
    {
        label: "MAXESTATES",
        value: "Max Estates Limited"
    },
    {
        label: "MAXHEALTH",
        value: "Max Healthcare Institute Limited"
    },
    {
        label: "MAXIND",
        value: "Max India Limited"
    },
    {
        label: "MAYURUNIQ",
        value: "Mayur Uniquoters Ltd"
    },
    {
        label: "MAZDA",
        value: "Mazda Limited"
    },
    {
        label: "MAZDOCK",
        value: "Mazagon Dock Shipbuilders Limited"
    },
    {
        label: "MBAPL",
        value: "Madhya Bharat Agro Products Limited"
    },
    {
        label: "MBECL",
        value: "Mcnally Bharat Engineering Company Limited"
    },
    {
        label: "MBLINFRA",
        value: "MBL Infrastructure Limited"
    },
    {
        label: "MCL",
        value: "Madhav Copper Limited"
    },
    {
        label: "MCLEODRUSS",
        value: "Mcleod Russel India Limited"
    },
    {
        label: "MCX",
        value: "Multi Commodity Exchange of India Limited"
    },
    {
        label: "MEDANTA",
        value: "Global Health Limited"
    },
    {
        label: "MEDIASSIST",
        value: "Medi Assist Healthcare Services Limited"
    },
    {
        label: "MEDICAMEQ",
        value: "Medicamen Biotech Limited"
    },
    {
        label: "MEDICO",
        value: "Medico Remedies Limited"
    },
    {
        label: "MEDPLUS",
        value: "Medplus Health Services Limited"
    },
    {
        label: "MEGASOFT",
        value: "Megasoft Limited"
    },
    {
        label: "MEGASTAR",
        value: "Megastar Foods Limited"
    },
    {
        label: "MENONBE",
        value: "Menon Bearings Limited"
    },
    {
        label: "METROBRAND",
        value: "Metro Brands Limited"
    },
    {
        label: "METROPOLIS",
        value: "Metropolis Healthcare Limited"
    },
    {
        label: "MFML",
        value: "Mahalaxmi Fabric Mills Limited"
    },
    {
        label: "MFSL",
        value: "Max Financial Services Limited"
    },
    {
        label: "MGEL",
        value: "Mangalam Global Enterprise Limited"
    },
    {
        label: "MGL",
        value: "Mahanagar Gas Limited"
    },
    {
        label: "MHLXMIRU",
        value: "Mahalaxmi Rubtech Limited"
    },
    {
        label: "MHRIL",
        value: "Mahindra Holidays & Resorts India Limited"
    },
    {
        label: "MICEL",
        value: "MIC Electronics Limited"
    },
    {
        label: "MIDHANI",
        value: "Mishra Dhatu Nigam Limited"
    },
    {
        label: "MINDACORP",
        value: "Minda Corporation Limited"
    },
    {
        label: "MINDTECK",
        value: "Mindteck (India) Limited"
    },
    {
        label: "MIRCELECTR",
        value: "MIRC Electronics Limited"
    },
    {
        label: "MIRZAINT",
        value: "Mirza International Limited"
    },
    {
        label: "MITCON",
        value: "MITCON Consultancy & Engineering Services Limited"
    },
    {
        label: "MITTAL",
        value: "Mittal Life Style Limited"
    },
    {
        label: "MKPL",
        value: "M K Proteins Limited"
    },
    {
        label: "MMFL",
        value: "MM Forgings Limited"
    },
    {
        label: "MMP",
        value: "MMP Industries Limited"
    },
    {
        label: "MMTC",
        value: "MMTC Limited"
    },
    {
        label: "MODIRUBBER",
        value: "Modi Rubber Limited"
    },
    {
        label: "MODISONLTD",
        value: "MODISON LIMITED"
    },
    {
        label: "MODTHREAD",
        value: "Modern Threads (India) Limited"
    },
    {
        label: "MOHITIND",
        value: "Mohit Industries Limited"
    },
    {
        label: "MOIL",
        value: "MOIL Limited"
    },
    {
        label: "MOKSH",
        value: "Moksh Ornaments Limited"
    },
    {
        label: "MOL",
        value: "Meghmani Organics Limited"
    },
    {
        label: "MOLDTECH",
        value: "Mold-Tek Technologies Limited"
    },
    {
        label: "MOLDTKPAC",
        value: "Mold-Tek Packaging Limited"
    },
    {
        label: "MONARCH",
        value: "Monarch Networth Capital Limited"
    },
    {
        label: "MONTECARLO",
        value: "Monte Carlo Fashions Limited"
    },
    {
        label: "MORARJEE",
        value: "Morarjee Textiles Limited"
    },
    {
        label: "MOREPENLAB",
        value: "Morepen Laboratories Limited"
    },
    {
        label: "MOTHERSON",
        value: "Samvardhana Motherson International Limited"
    },
    {
        label: "MOTILALOFS",
        value: "Motilal Oswal Financial Services Limited"
    },
    {
        label: "MOTISONS",
        value: "Motisons Jewellers Limited"
    },
    {
        label: "MOTOGENFIN",
        value: "The Motor & General Finance Limited"
    },
    {
        label: "MPHASIS",
        value: "MphasiS Limited"
    },
    {
        label: "MPSLTD",
        value: "MPS Limited"
    },
    {
        label: "MRF",
        value: "MRF Limited"
    },
    {
        label: "MRO-TEK",
        value: "MRO-TEK Realty Limited"
    },
    {
        label: "MRPL",
        value: "Mangalore Refinery and Petrochemicals Limited"
    },
    {
        label: "MSPL",
        value: "MSP Steel & Power Limited"
    },
    {
        label: "MSTCLTD",
        value: "Mstc Limited"
    },
    {
        label: "MSUMI",
        value: "Motherson Sumi Wiring India Limited"
    },
    {
        label: "MTARTECH",
        value: "Mtar Technologies Limited"
    },
    {
        label: "MTNL",
        value: "Mahanagar Telephone Nigam Limited"
    },
    {
        label: "MUFIN",
        value: "Mufin Green Finance Limited"
    },
    {
        label: "MUFTI",
        value: "Credo Brands Marketing Limited"
    },
    {
        label: "MUKANDLTD",
        value: "Mukand Limited"
    },
    {
        label: "MUKKA",
        value: "Mukka Proteins Limited"
    },
    {
        label: "MUKTAARTS",
        value: "Mukta Arts Limited"
    },
    {
        label: "MUNJALAU",
        value: "Munjal Auto Industries Limited"
    },
    {
        label: "MUNJALSHOW",
        value: "Munjal Showa Limited"
    },
    {
        label: "MURUDCERA",
        value: "Murudeshwar Ceramics Limited"
    },
    {
        label: "MUTHOOTCAP",
        value: "Muthoot Capital Services Limited"
    },
    {
        label: "MUTHOOTFIN",
        value: "Muthoot Finance Limited"
    },
    {
        label: "MUTHOOTMF",
        value: "Muthoot Microfin Limited"
    },
    {
        label: "MVGJL",
        value: "Manoj Vaibhav Gems N Jewellers Limited"
    },
    {
        label: "NACLIND",
        value: "NACL Industries Limited"
    },
    {
        label: "NAGAFERT",
        value: "Nagarjuna Fertilizers and Chemicals Limited"
    },
    {
        label: "NAGREEKCAP",
        value: "Nagreeka Capital & Infrastructure Limited"
    },
    {
        label: "NAGREEKEXP",
        value: "Nagreeka Exports Limited"
    },
    {
        label: "NAHARCAP",
        value: "Nahar Capital and Financial Services Limited"
    },
    {
        label: "NAHARINDUS",
        value: "Nahar Industrial Enterprises Limited"
    },
    {
        label: "NAHARPOLY",
        value: "Nahar Poly Films Limited"
    },
    {
        label: "NAHARSPING",
        value: "Nahar Spinning Mills Limited"
    },
    {
        label: "NAM-INDIA",
        value: "Nippon Life India Asset Management Limited"
    },
    {
        label: "NARMADA",
        value: "Narmada Agrobase Limited"
    },
    {
        label: "NATCOPHARM",
        value: "Natco Pharma Limited"
    },
    {
        label: "NATHBIOGEN",
        value: "Nath Bio-Genes (India) Limited"
    },
    {
        label: "NATIONALUM",
        value: "National Aluminium Company Limited"
    },
    {
        label: "NAUKRI",
        value: "Info Edge (India) Limited"
    },
    {
        label: "NAVA",
        value: "NAVA LIMITED"
    },
    {
        label: "NAVINFLUOR",
        value: "Navin Fluorine International Limited"
    },
    {
        label: "NAVKARCORP",
        value: "Navkar Corporation Limited"
    },
    {
        label: "NAVNETEDUL",
        value: "Navneet Education Limited"
    },
    {
        label: "NAZARA",
        value: "Nazara Technologies Limited"
    },
    {
        label: "NBCC",
        value: "NBCC (India) Limited"
    },
    {
        label: "NBIFIN",
        value: "N. B. I. Industrial Finance Company Limited"
    },
    {
        label: "NCC",
        value: "NCC Limited"
    },
    {
        label: "NCLIND",
        value: "NCL Industries Limited"
    },
    {
        label: "NDGL",
        value: "Naga Dhunseri Group Limited"
    },
    {
        label: "NDL",
        value: "Nandan Denim Limited"
    },
    {
        label: "NDLVENTURE",
        value: "NDL Ventures Limited"
    },
    {
        label: "NDRAUTO",
        value: "Ndr Auto Components Limited"
    },
    {
        label: "NDTV",
        value: "New Delhi Television Limited"
    },
    {
        label: "NECCLTD",
        value: "North Eastern Carrying Corporation Limited"
    },
    {
        label: "NECLIFE",
        value: "Nectar Lifesciences Limited"
    },
    {
        label: "NELCAST",
        value: "Nelcast Limited"
    },
    {
        label: "NELCO",
        value: "NELCO Limited"
    },
    {
        label: "NEOGEN",
        value: "Neogen Chemicals Limited"
    },
    {
        label: "NESCO",
        value: "Nesco Limited"
    },
    {
        label: "NESTLEIND",
        value: "Nestle India Limited"
    },
    {
        label: "NETWEB",
        value: "Netweb Technologies India Limited"
    },
    {
        label: "NETWORK18",
        value: "Network18 Media & Investments Limited"
    },
    {
        label: "NEULANDLAB",
        value: "Neuland Laboratories Limited"
    },
    {
        label: "NEWGEN",
        value: "Newgen Software Technologies Limited"
    },
    {
        label: "NEXTMEDIA",
        value: "Next Mediaworks Limited"
    },
    {
        label: "NFL",
        value: "National Fertilizers Limited"
    },
    {
        label: "NGIL",
        value: "Nakoda Group of Industries Limited"
    },
    {
        label: "NGLFINE",
        value: "NGL Fine-Chem Limited"
    },
    {
        label: "NH",
        value: "Narayana Hrudayalaya Ltd."
    },
    {
        label: "NHPC",
        value: "NHPC Limited"
    },
    {
        label: "NIACL",
        value: "The New India Assurance Company Limited"
    },
    {
        label: "NIBL",
        value: "NRB Industrial Bearings Limited"
    },
    {
        label: "NIITLTD",
        value: "NIIT Limited"
    },
    {
        label: "NIITMTS",
        value: "NIIT Learning Systems Limited"
    },
    {
        label: "NILAINFRA",
        value: "Nila Infrastructures Limited"
    },
    {
        label: "NILASPACES",
        value: "Nila Spaces Limited"
    },
    {
        label: "NILKAMAL",
        value: "Nilkamal Limited"
    },
    {
        label: "NINSYS",
        value: "NINtec Systems Limited"
    },
    {
        label: "NIPPOBATRY",
        value: "Indo-National Limited"
    },
    {
        label: "NIRAJ",
        value: "Niraj Cement Structurals Limited"
    },
    {
        label: "NIRAJISPAT",
        value: "Niraj Ispat Industries Limited"
    },
    {
        label: "NITCO",
        value: "Nitco Limited"
    },
    {
        label: "NITINSPIN",
        value: "Nitin Spinners Limited"
    },
    {
        label: "NITIRAJ",
        value: "Nitiraj Engineers Limited"
    },
    {
        label: "NIVABUPA",
        value: "Niva Bupa Health Insurance Company Limited"
    },
    {
        label: "NKIND",
        value: "NK Industries Limited"
    },
    {
        label: "NLCINDIA",
        value: "NLC India Limited"
    },
    {
        label: "NMDC",
        value: "NMDC Limited"
    },
    {
        label: "NOCIL",
        value: "NOCIL Limited"
    },
    {
        label: "NOIDATOLL",
        value: "Noida Toll Bridge Company Limited"
    },
    {
        label: "NORBTEAEXP",
        value: "Norben Tea & Exports Limited"
    },
    {
        label: "NORTHARC",
        value: "Northern Arc Capital Limited"
    },
    {
        label: "NOVAAGRI",
        value: "Nova Agritech Limited"
    },
    {
        label: "NRAIL",
        value: "N R Agarwal Industries Limited"
    },
    {
        label: "NRBBEARING",
        value: "NRB Bearing Limited"
    },
    {
        label: "NRL",
        value: "Nupur Recyclers Limited"
    },
    {
        label: "NSIL",
        value: "Nalwa Sons Investments Limited"
    },
    {
        label: "NSLNISP",
        value: "NMDC Steel Limited"
    },
    {
        label: "NTPC",
        value: "NTPC Limited"
    },
    {
        label: "NTPCGREEN",
        value: "NTPC Green Energy Limited"
    },
    {
        label: "NUCLEUS",
        value: "Nucleus Software Exports Limited"
    },
    {
        label: "NURECA",
        value: "Nureca Limited"
    },
    {
        label: "NUVAMA",
        value: "Nuvama Wealth Management Limited"
    },
    {
        label: "NUVOCO",
        value: "Nuvoco Vistas Corporation Limited"
    },
    {
        label: "NYKAA",
        value: "FSN E-Commerce Ventures Limited"
    },
    {
        label: "OAL",
        value: "Oriental Aromatics Limited"
    },
    {
        label: "OBCL",
        value: "Orissa Bengal Carrier Limited"
    },
    {
        label: "OBEROIRLTY",
        value: "Oberoi Realty Limited"
    },
    {
        label: "OCCL",
        value: "Oriental Carbon & Chemicals Limited"
    },
    {
        label: "OCCLLTD",
        value: "OCCL Limited"
    },
    {
        label: "OFSS",
        value: "Oracle Financial Services Software Limited"
    },
    {
        label: "OIL",
        value: "Oil India Limited"
    },
    {
        label: "OILCOUNTUB",
        value: "Oil Country Tubular Limited"
    },
    {
        label: "OLAELEC",
        value: "Ola Electric Mobility Limited"
    },
    {
        label: "OLECTRA",
        value: "Olectra Greentech Limited"
    },
    {
        label: "OMAXAUTO",
        value: "Omax Autos Limited"
    },
    {
        label: "OMAXE",
        value: "Omaxe Limited"
    },
    {
        label: "OMINFRAL",
        value: "OM INFRA LIMITED"
    },
    {
        label: "ONELIFECAP",
        value: "Onelife Capital Advisors Limited"
    },
    {
        label: "ONEPOINT",
        value: "One Point One Solutions Limited"
    },
    {
        label: "ONGC",
        value: "Oil & Natural Gas Corporation Limited"
    },
    {
        label: "ONMOBILE",
        value: "OnMobile Global Limited"
    },
    {
        label: "ONWARDTEC",
        value: "Onward Technologies Limited"
    },
    {
        label: "OPTIEMUS",
        value: "Optiemus Infracom Limited"
    },
    {
        label: "ORBTEXP",
        value: "Orbit Exports Limited"
    },
    {
        label: "ORCHPHARMA",
        value: "Orchid Pharma Limited"
    },
    {
        label: "ORICONENT",
        value: "Oricon Enterprises Limited"
    },
    {
        label: "ORIENTALTL",
        value: "Oriental Trimex Limited"
    },
    {
        label: "ORIENTBELL",
        value: "Orient Bell Limited"
    },
    {
        label: "ORIENTCEM",
        value: "Orient Cement Limited"
    },
    {
        label: "ORIENTCER",
        value: "ORIENT CERATECH LIMITED"
    },
    {
        label: "ORIENTELEC",
        value: "Orient Electric Limited"
    },
    {
        label: "ORIENTHOT",
        value: "Oriental Hotels Limited"
    },
    {
        label: "ORIENTLTD",
        value: "Orient Press Limited"
    },
    {
        label: "ORIENTPPR",
        value: "Orient Paper & Industries Limited"
    },
    {
        label: "ORIENTTECH",
        value: "Orient Technologies Limited"
    },
    {
        label: "ORISSAMINE",
        value: "The Orissa Minerals Development Company Limited"
    },
    {
        label: "ORTINGLOBE",
        value: "ORTIN GLOBAL LIMITED"
    },
    {
        label: "OSIAHYPER",
        value: "Osia Hyper Retail Limited"
    },
    {
        label: "OSWALAGRO",
        value: "Oswal Agro Mills Limited"
    },
    {
        label: "OSWALGREEN",
        value: "Oswal Greentech Limited"
    },
    {
        label: "OSWALSEEDS",
        value: "ShreeOswal Seeds And Chemicals Limited"
    },
    {
        label: "PAGEIND",
        value: "Page Industries Limited"
    },
    {
        label: "PAISALO",
        value: "Paisalo Digital Limited"
    },
    {
        label: "PAKKA",
        value: "PAKKA LIMITED"
    },
    {
        label: "PALASHSECU",
        value: "Palash Securities Limited"
    },
    {
        label: "PALREDTEC",
        value: "Palred Technologies Limited"
    },
    {
        label: "PANACEABIO",
        value: "Panacea Biotec Limited"
    },
    {
        label: "PANACHE",
        value: "Panache Digilife Limited"
    },
    {
        label: "PANAMAPET",
        value: "Panama Petrochem Limited"
    },
    {
        label: "PANSARI",
        value: "Pansari Developers Limited"
    },
    {
        label: "PAR",
        value: "Par Drugs And Chemicals Limited"
    },
    {
        label: "PARACABLES",
        value: "Paramount Communications Limited"
    },
    {
        label: "PARADEEP",
        value: "Paradeep Phosphates Limited"
    },
    {
        label: "PARAGMILK",
        value: "Parag Milk Foods Limited"
    },
    {
        label: "PARAS",
        value: "Paras Defence and Space Technologies Limited"
    },
    {
        label: "PARASPETRO",
        value: "Paras Petrofils Limited"
    },
    {
        label: "PARKHOTELS",
        value: "Apeejay Surrendra Park Hotels Limited"
    },
    {
        label: "PARSVNATH",
        value: "Parsvnath Developers Limited"
    },
    {
        label: "PASUPTAC",
        value: "Pasupati Acrylon Limited"
    },
    {
        label: "PATANJALI",
        value: "Patanjali Foods Limited"
    },
    {
        label: "PATELENG",
        value: "Patel Engineering Limited"
    },
    {
        label: "PATINTLOG",
        value: "Patel Integrated Logistics Limited"
    },
    {
        label: "PAVNAIND",
        value: "Pavna Industries Limited"
    },
    {
        label: "PAYTM",
        value: "One 97 Communications Limited"
    },
    {
        label: "PCBL",
        value: "PCBL LIMITED"
    },
    {
        label: "PCJEWELLER",
        value: "PC Jeweller Limited"
    },
    {
        label: "PDMJEPAPER",
        value: "Pudumjee Paper Products Limited"
    },
    {
        label: "PDSL",
        value: "PDS Limited"
    },
    {
        label: "PEARLPOLY",
        value: "Pearl Polymers Limited"
    },
    {
        label: "PEL",
        value: "Piramal Enterprises Limited"
    },
    {
        label: "PENIND",
        value: "Pennar Industries Limited"
    },
    {
        label: "PENINLAND",
        value: "Peninsula Land Limited"
    },
    {
        label: "PERSISTENT",
        value: "Persistent Systems Limited"
    },
    {
        label: "PETRONET",
        value: "Petronet LNG Limited"
    },
    {
        label: "PFC",
        value: "Power Finance Corporation Limited"
    },
    {
        label: "PFIZER",
        value: "Pfizer Limited"
    },
    {
        label: "PFOCUS",
        value: "Prime Focus Limited"
    },
    {
        label: "PFS",
        value: "PTC India Financial Services Limited"
    },
    {
        label: "PGEL",
        value: "PG Electroplast Limited"
    },
    {
        label: "PGHH",
        value: "Procter & Gamble Hygiene and Health Care Limited"
    },
    {
        label: "PGHL",
        value: "Procter & Gamble Health Limited"
    },
    {
        label: "PGIL",
        value: "Pearl Global Industries Limited"
    },
    {
        label: "PHOENIXLTD",
        value: "The Phoenix Mills Limited"
    },
    {
        label: "PIDILITIND",
        value: "Pidilite Industries Limited"
    },
    {
        label: "PIGL",
        value: "Power & Instrumentation (Gujarat) Limited"
    },
    {
        label: "PIIND",
        value: "PI Industries Limited"
    },
    {
        label: "PILANIINVS",
        value: "Pilani Investment and Industries Corporation Limited"
    },
    {
        label: "PILITA",
        value: "PIL ITALICA LIFESTYLE LIMITED"
    },
    {
        label: "PIONEEREMB",
        value: "Pioneer Embroideries Limited"
    },
    {
        label: "PITTIENG",
        value: "Pitti Engineering Limited"
    },
    {
        label: "PIXTRANS",
        value: "Pix Transmissions Limited"
    },
    {
        label: "PKTEA",
        value: "The Peria Karamalai Tea & Produce Company Limited"
    },
    {
        label: "PLASTIBLEN",
        value: "Plastiblends India Limited"
    },
    {
        label: "PLATIND",
        value: "Platinum Industries Limited"
    },
    {
        label: "PLAZACABLE",
        value: "Plaza Wires Limited"
    },
    {
        label: "PNB",
        value: "Punjab National Bank"
    },
    {
        label: "PNBGILTS",
        value: "PNB Gilts Limited"
    },
    {
        label: "PNBHOUSING",
        value: "PNB Housing Finance Limited"
    },
    {
        label: "PNC",
        value: "Pritish Nandy Communications Limited"
    },
    {
        label: "PNCINFRA",
        value: "PNC Infratech Limited"
    },
    {
        label: "PNGJL",
        value: "P N Gadgil Jewellers Limited"
    },
    {
        label: "POCL",
        value: "Pondy Oxides & Chemicals Limited"
    },
    {
        label: "PODDARHOUS",
        value: "Poddar Housing and Development Limited"
    },
    {
        label: "PODDARMENT",
        value: "Poddar Pigments Limited"
    },
    {
        label: "POKARNA",
        value: "Pokarna Limited"
    },
    {
        label: "POLICYBZR",
        value: "PB Fintech Limited"
    },
    {
        label: "POLYCAB",
        value: "Polycab India Limited"
    },
    {
        label: "POLYMED",
        value: "Poly Medicure Limited"
    },
    {
        label: "POLYPLEX",
        value: "Polyplex Corporation Limited"
    },
    {
        label: "PONNIERODE",
        value: "Ponni Sugars (Erode) Limited"
    },
    {
        label: "POONAWALLA",
        value: "Poonawalla Fincorp Limited"
    },
    {
        label: "POWERGRID",
        value: "Power Grid Corporation of India Limited"
    },
    {
        label: "POWERINDIA",
        value: "Hitachi Energy India Limited"
    },
    {
        label: "POWERMECH",
        value: "Power Mech Projects Limited"
    },
    {
        label: "PPAP",
        value: "PPAP Automotive Limited"
    },
    {
        label: "PPL",
        value: "Prakash Pipes Limited"
    },
    {
        label: "PPLPHARMA",
        value: "Piramal Pharma Limited"
    },
    {
        label: "PRAENG",
        value: "Prajay Engineers Syndicate Limited"
    },
    {
        label: "PRAJIND",
        value: "Praj Industries Limited"
    },
    {
        label: "PRAKASH",
        value: "Prakash Industries Limited"
    },
    {
        label: "PRAKASHSTL",
        value: "Prakash Steelage Limited"
    },
    {
        label: "PRAXIS",
        value: "Praxis Home Retail Limited"
    },
    {
        label: "PRECAM",
        value: "Precision Camshafts Limited"
    },
    {
        label: "PRECOT",
        value: "Precot Limited"
    },
    {
        label: "PRECWIRE",
        value: "Precision Wires India Limited"
    },
    {
        label: "PREMEXPLN",
        value: "Premier Explosives Limited"
    },
    {
        label: "PREMIERENE",
        value: "Premier Energies Limited"
    },
    {
        label: "PREMIERPOL",
        value: "Premier Polyfilm Limited"
    },
    {
        label: "PRESTIGE",
        value: "Prestige Estates Projects Limited"
    },
    {
        label: "PRICOLLTD",
        value: "Pricol Limited"
    },
    {
        label: "PRIMESECU",
        value: "Prime Securities Limited"
    },
    {
        label: "PRINCEPIPE",
        value: "Prince Pipes And Fittings Limited"
    },
    {
        label: "PRITI",
        value: "Priti International Limited"
    },
    {
        label: "PRITIKAUTO",
        value: "Pritika Auto Industries Limited"
    },
    {
        label: "PRIVISCL",
        value: "Privi Speciality Chemicals Limited"
    },
    {
        label: "PROZONER",
        value: "Prozone Realty Limited"
    },
    {
        label: "PRSMJOHNSN",
        value: "Prism Johnson Limited"
    },
    {
        label: "PRUDENT",
        value: "Prudent Corporate Advisory Services Limited"
    },
    {
        label: "PRUDMOULI",
        value: "Prudential Sugar Corporation Limited"
    },
    {
        label: "PSB",
        value: "Punjab & Sind Bank"
    },
    {
        label: "PSPPROJECT",
        value: "PSP Projects Limited"
    },
    {
        label: "PTC",
        value: "PTC India Limited"
    },
    {
        label: "PTCIL",
        value: "PTC Industries Limited"
    },
    {
        label: "PTL",
        value: "PTL Enterprises Limited"
    },
    {
        label: "PUNJABCHEM",
        value: "Punjab Chemicals & Crop Protection Limited"
    },
    {
        label: "PURVA",
        value: "Puravankara Limited"
    },
    {
        label: "PVP",
        value: "PVP Ventures Limited"
    },
    {
        label: "PVRINOX",
        value: "PVR INOX Limited"
    },
    {
        label: "PVSL",
        value: "Popular Vehicles and Services Limited"
    },
    {
        label: "PYRAMID",
        value: "Pyramid Technoplast Limited"
    },
    {
        label: "QUESS",
        value: "Quess Corp Limited"
    },
    {
        label: "QUICKHEAL",
        value: "Quick Heal Technologies Limited"
    },
    {
        label: "RACE",
        value: "Race Eco Chain Limited"
    },
    {
        label: "RACLGEAR",
        value: "RACL Geartech Limited"
    },
    {
        label: "RADAAN",
        value: "Radaan Mediaworks India Limited"
    },
    {
        label: "RADHIKAJWE",
        value: "Radhika Jeweltech Limited"
    },
    {
        label: "RADIANTCMS",
        value: "Radiant Cash Management Services Limited"
    },
    {
        label: "RADICO",
        value: "Radico Khaitan Limited"
    },
    {
        label: "RADIOCITY",
        value: "Music Broadcast Limited"
    },
    {
        label: "RAILTEL",
        value: "Railtel Corporation Of India Limited"
    },
    {
        label: "RAIN",
        value: "Rain Industries Limited"
    },
    {
        label: "RAINBOW",
        value: "Rainbow Childrens Medicare Limited"
    },
    {
        label: "RAJESHEXPO",
        value: "Rajesh Exports Limited"
    },
    {
        label: "RAJMET",
        value: "Rajnandini Metal Limited"
    },
    {
        label: "RAJRATAN",
        value: "Rajratan Global Wire Limited"
    },
    {
        label: "RAJRILTD",
        value: "Raj Rayon Industries Limited"
    },
    {
        label: "RAJSREESUG",
        value: "Rajshree Sugars & Chemicals Limited"
    },
    {
        label: "RAJTV",
        value: "Raj Television Network Limited"
    },
    {
        label: "RALLIS",
        value: "Rallis India Limited"
    },
    {
        label: "RAMANEWS",
        value: "Shree Rama Newsprint Limited"
    },
    {
        label: "RAMAPHO",
        value: "Rama Phosphates Limited"
    },
    {
        label: "RAMASTEEL",
        value: "Rama Steel Tubes Limited"
    },
    {
        label: "RAMCOCEM",
        value: "The Ramco Cements Limited"
    },
    {
        label: "RAMCOIND",
        value: "Ramco Industries Limited"
    },
    {
        label: "RAMCOSYS",
        value: "Ramco Systems Limited"
    },
    {
        label: "RAMKY",
        value: "Ramky Infrastructure Limited"
    },
    {
        label: "RAMRAT",
        value: "Ram Ratna Wires Limited"
    },
    {
        label: "RANASUG",
        value: "Rana Sugars Limited"
    },
    {
        label: "RANEENGINE",
        value: "Rane Engine Valve Limited"
    },
    {
        label: "RANEHOLDIN",
        value: "Rane Holdings Limited"
    },
    {
        label: "RATEGAIN",
        value: "Rategain Travel Technologies Limited"
    },
    {
        label: "RATNAMANI",
        value: "Ratnamani Metals & Tubes Limited"
    },
    {
        label: "RATNAVEER",
        value: "Ratnaveer Precision Engineering Limited"
    },
    {
        label: "RAYMOND",
        value: "Raymond Limited"
    },
    {
        label: "RAYMONDLSL",
        value: "Raymond Lifestyle Limited"
    },
    {
        label: "RBA",
        value: "Restaurant Brands Asia Limited"
    },
    {
        label: "RBL",
        value: "Rane Brake Lining Limited"
    },
    {
        label: "RBLBANK",
        value: "RBL Bank Limited"
    },
    {
        label: "RBZJEWEL",
        value: "RBZ Jewellers Limited"
    },
    {
        label: "RCF",
        value: "Rashtriya Chemicals and Fertilizers Limited"
    },
    {
        label: "RECLTD",
        value: "REC Limited"
    },
    {
        label: "REDINGTON",
        value: "Redington Limited"
    },
    {
        label: "REDTAPE",
        value: "Redtape Limited"
    },
    {
        label: "REFEX",
        value: "Refex Industries Limited"
    },
    {
        label: "REGENCERAM",
        value: "Regency Ceramics Limited"
    },
    {
        label: "RELAXO",
        value: "Relaxo Footwears Limited"
    },
    {
        label: "RELCHEMQ",
        value: "Reliance Chemotex Industries Limited"
    },
    {
        label: "RELIABLE",
        value: "Reliable Data Services Limited"
    },
    {
        label: "RELIANCE",
        value: "Reliance Industries Limited"
    },
    {
        label: "RELIGARE",
        value: "Religare Enterprises Limited"
    },
    {
        label: "RELINFRA",
        value: "Reliance Infrastructure Limited"
    },
    {
        label: "RELTD",
        value: "Ravindra Energy Limited"
    },
    {
        label: "REMSONSIND",
        value: "Remsons Industries Limited"
    },
    {
        label: "RENUKA",
        value: "Shree Renuka Sugars Limited"
    },
    {
        label: "REPCOHOME",
        value: "Repco Home Finance Limited"
    },
    {
        label: "REPL",
        value: "Rudrabhishek Enterprises Limited"
    },
    {
        label: "REPRO",
        value: "Repro India Limited"
    },
    {
        label: "RESPONIND",
        value: "Responsive Industries Limited"
    },
    {
        label: "RETAIL",
        value: "JHS Svendgaard Retail Ventures Limited"
    },
    {
        label: "RGL",
        value: "Renaissance Global Limited"
    },
    {
        label: "RHFL",
        value: "Reliance Home Finance Limited"
    },
    {
        label: "RHIM",
        value: "RHI MAGNESITA INDIA LIMITED"
    },
    {
        label: "RHL",
        value: "Robust Hotels Limited"
    },
    {
        label: "RICOAUTO",
        value: "Rico Auto Industries Limited"
    },
    {
        label: "RIIL",
        value: "Reliance Industrial Infrastructure Limited"
    },
    {
        label: "RISHABH",
        value: "Rishabh Instruments Limited"
    },
    {
        label: "RITCO",
        value: "Ritco Logistics Limited"
    },
    {
        label: "RITES",
        value: "RITES Limited"
    },
    {
        label: "RKDL",
        value: "Ravi Kumar Distilleries Limited"
    },
    {
        label: "RKEC",
        value: "RKEC Projects Limited"
    },
    {
        label: "RKFORGE",
        value: "Ramkrishna Forgings Limited"
    },
    {
        label: "RKSWAMY",
        value: "R K Swamy Limited"
    },
    {
        label: "RML",
        value: "Rane (Madras) Limited"
    },
    {
        label: "ROHLTD",
        value: "Royal Orchid Hotels Limited"
    },
    {
        label: "ROLEXRINGS",
        value: "Rolex Rings Limited"
    },
    {
        label: "ROLLT",
        value: "Rollatainers Limited"
    },
    {
        label: "ROML",
        value: "Raj Oil Mills Limited"
    },
    {
        label: "ROSSARI",
        value: "Rossari Biotech Limited"
    },
    {
        label: "ROSSELLIND",
        value: "Rossell India Limited"
    },
    {
        label: "ROTO",
        value: "Roto Pumps Limited"
    },
    {
        label: "ROUTE",
        value: "ROUTE MOBILE LIMITED"
    },
    {
        label: "RPEL",
        value: "Raghav Productivity Enhancers Limited"
    },
    {
        label: "RPGLIFE",
        value: "RPG Life Sciences Limited"
    },
    {
        label: "RPOWER",
        value: "Reliance Power Limited"
    },
    {
        label: "RPPINFRA",
        value: "R.P.P. Infra Projects Limited"
    },
    {
        label: "RPPL",
        value: "Rajshree Polypack Limited"
    },
    {
        label: "RPSGVENT",
        value: "RPSG VENTURES LIMITED"
    },
    {
        label: "RPTECH",
        value: "Rashi Peripherals Limited"
    },
    {
        label: "RRKABEL",
        value: "R R Kabel Limited"
    },
    {
        label: "RSSOFTWARE",
        value: "R. S. Software (India) Limited"
    },
    {
        label: "RSWM",
        value: "RSWM Limited"
    },
    {
        label: "RSYSTEMS",
        value: "R Systems International Limited"
    },
    {
        label: "RTNINDIA",
        value: "RattanIndia Enterprises Limited"
    },
    {
        label: "RTNPOWER",
        value: "RattanIndia Power Limited"
    },
    {
        label: "RUBFILA",
        value: "Rubfila International Limited"
    },
    {
        label: "RUBYMILLS",
        value: "The Ruby Mills Limited"
    },
    {
        label: "RUCHINFRA",
        value: "Ruchi Infrastructure Limited"
    },
    {
        label: "RUCHIRA",
        value: "Ruchira Papers Limited"
    },
    {
        label: "RUPA",
        value: "Rupa & Company Limited"
    },
    {
        label: "RUSHIL",
        value: "Rushil Decor Limited"
    },
    {
        label: "RUSTOMJEE",
        value: "Keystone Realtors Limited"
    },
    {
        label: "RVHL",
        value: "Ravinder Heights Limited"
    },
    {
        label: "RVNL",
        value: "Rail Vikas Nigam Limited"
    },
    {
        label: "RVTH",
        value: "Revathi Equipment India Limited"
    },
    {
        label: "S&SPOWER",
        value: "S&S Power Switchgears Limited"
    },
    {
        label: "SABEVENTS",
        value: "Sab Events & Governance Now Media Limited"
    },
    {
        label: "SABTNL",
        value: "Sri Adhikari Brothers Television Network Limited"
    },
    {
        label: "SADBHAV",
        value: "Sadbhav Engineering Limited"
    },
    {
        label: "SADBHIN",
        value: "Sadbhav Infrastructure Project Limited"
    },
    {
        label: "SADHNANIQ",
        value: "Sadhana Nitrochem Limited"
    },
    {
        label: "SAFARI",
        value: "Safari Industries (India) Limited"
    },
    {
        label: "SAGARDEEP",
        value: "Sagardeep Alloys Limited"
    },
    {
        label: "SAGCEM",
        value: "Sagar Cements Limited"
    },
    {
        label: "SAGILITY",
        value: "Sagility India Limited"
    },
    {
        label: "SAH",
        value: "Sah Polymers Limited"
    },
    {
        label: "SAHYADRI",
        value: "Sahyadri Industries Limited"
    },
    {
        label: "SAIL",
        value: "Steel Authority of India Limited"
    },
    {
        label: "SAKAR",
        value: "Sakar Healthcare Limited"
    },
    {
        label: "SAKHTISUG",
        value: "Sakthi Sugars Limited"
    },
    {
        label: "SAKSOFT",
        value: "Saksoft Limited"
    },
    {
        label: "SAKUMA",
        value: "Sakuma Exports Limited"
    },
    {
        label: "SALASAR",
        value: "Salasar Techno Engineering Limited"
    },
    {
        label: "SALONA",
        value: "Salona Cotspin Limited"
    },
    {
        label: "SALSTEEL",
        value: "S.A.L. Steel Limited"
    },
    {
        label: "SALZERELEC",
        value: "Salzer Electronics Limited"
    },
    {
        label: "SAMBHAAV",
        value: "Sambhaav Media Limited"
    },
    {
        label: "SAMHI",
        value: "Samhi Hotels Limited"
    },
    {
        label: "SAMMAANCAP",
        value: "Sammaan Capital Limited"
    },
    {
        label: "SAMPANN",
        value: "Sampann Utpadan India Limited"
    },
    {
        label: "SANCO",
        value: "Sanco Industries Limited"
    },
    {
        label: "SANDESH",
        value: "The Sandesh Limited"
    },
    {
        label: "SANDHAR",
        value: "Sandhar Technologies Limited"
    },
    {
        label: "SANDUMA",
        value: "Sandur Manganese & Iron Ores Limited"
    },
    {
        label: "SANGAMIND",
        value: "Sangam (India) Limited"
    },
    {
        label: "SANGHIIND",
        value: "Sanghi Industries Limited"
    },
    {
        label: "SANGHVIMOV",
        value: "Sanghvi Movers Limited"
    },
    {
        label: "SANGINITA",
        value: "Sanginita Chemicals Limited"
    },
    {
        label: "SANOFI",
        value: "Sanofi India Limited"
    },
    {
        label: "SANOFICONR",
        value: "Sanofi Consumer Healthcare India Limited"
    },
    {
        label: "SANSERA",
        value: "Sansera Engineering Limited"
    },
    {
        label: "SANSTAR",
        value: "Sanstar Limited"
    },
    {
        label: "SANWARIA",
        value: "Sanwaria Consumer Limited"
    },
    {
        label: "SAPPHIRE",
        value: "Sapphire Foods India Limited"
    },
    {
        label: "SARDAEN",
        value: "Sarda Energy & Minerals Limited"
    },
    {
        label: "SAREGAMA",
        value: "Saregama India Limited"
    },
    {
        label: "SARLAPOLY",
        value: "Sarla Performance Fibers Limited"
    },
    {
        label: "SARVESHWAR",
        value: "Sarveshwar Foods Limited"
    },
    {
        label: "SASKEN",
        value: "Sasken Technologies Limited"
    },
    {
        label: "SASTASUNDR",
        value: "Sastasundar Ventures Limited"
    },
    {
        label: "SATIA",
        value: "Satia Industries Limited"
    },
    {
        label: "SATIN",
        value: "Satin Creditcare Network Limited"
    },
    {
        label: "SATINDLTD",
        value: "Sat Industries Limited"
    },
    {
        label: "SAURASHCEM",
        value: "Saurashtra Cement Limited"
    },
    {
        label: "SBC",
        value: "SBC Exports Limited"
    },
    {
        label: "SBCL",
        value: "Shivalik Bimetal Controls Limited"
    },
    {
        label: "SBFC",
        value: "SBFC Finance Limited"
    },
    {
        label: "SBGLP",
        value: "Suratwwala Business Group Limited"
    },
    {
        label: "SBICARD",
        value: "SBI Cards and Payment Services Limited"
    },
    {
        label: "SBILIFE",
        value: "SBI Life Insurance Company Limited"
    },
    {
        label: "SBIN",
        value: "State Bank of India"
    },
    {
        label: "SCHAEFFLER",
        value: "Schaeffler India Limited"
    },
    {
        label: "SCHAND",
        value: "S Chand And Company Limited"
    },
    {
        label: "SCHNEIDER",
        value: "Schneider Electric Infrastructure Limited"
    },
    {
        label: "SCI",
        value: "Shipping Corporation Of India Limited"
    },
    {
        label: "SCILAL",
        value: "Shipping Corporation of India Land and Assets Limited"
    },
    {
        label: "SCPL",
        value: "Sheetal Cool Products Limited"
    },
    {
        label: "SDBL",
        value: "Som Distilleries & Breweries Limited"
    },
    {
        label: "SEAMECLTD",
        value: "Seamec Limited"
    },
    {
        label: "SECMARK",
        value: "SecMark Consultancy Limited"
    },
    {
        label: "SECURCRED",
        value: "SecUR Credentials Limited"
    },
    {
        label: "SECURKLOUD",
        value: "SECUREKLOUD TECHNOLOGIES LIMITED"
    },
    {
        label: "SEJALLTD",
        value: "Sejal Glass Limited"
    },
    {
        label: "SELAN",
        value: "Selan Exploration Technology Limited"
    },
    {
        label: "SELMC",
        value: "SEL Manufacturing Company Limited"
    },
    {
        label: "SEMAC",
        value: "SEMAC CONSULTANTS LIMITED"
    },
    {
        label: "SENCO",
        value: "Senco Gold Limited"
    },
    {
        label: "SEPC",
        value: "SEPC Limited"
    },
    {
        label: "SEQUENT",
        value: "Sequent Scientific Limited"
    },
    {
        label: "SERVOTECH",
        value: "Servotech Power Systems Limited"
    },
    {
        label: "SESHAPAPER",
        value: "Seshasayee Paper and Boards Limited"
    },
    {
        label: "SETCO",
        value: "Setco Automotive Limited"
    },
    {
        label: "SETUINFRA",
        value: "Setubandhan Infrastructure Limited"
    },
    {
        label: "SFL",
        value: "Sheela Foam Limited"
    },
    {
        label: "SGIL",
        value: "Synergy Green Industries Limited"
    },
    {
        label: "SGL",
        value: "STL Global Limited"
    },
    {
        label: "SHAH",
        value: "Shah Metacorp Limited"
    },
    {
        label: "SHAHALLOYS",
        value: "Shah Alloys Limited"
    },
    {
        label: "SHAILY",
        value: "Shaily Engineering Plastics Limited"
    },
    {
        label: "SHAKTIPUMP",
        value: "Shakti Pumps (India) Limited"
    },
    {
        label: "SHALBY",
        value: "Shalby Limited"
    },
    {
        label: "SHALPAINTS",
        value: "Shalimar Paints Limited"
    },
    {
        label: "SHANKARA",
        value: "Shankara Building Products Limited"
    },
    {
        label: "SHANTI",
        value: "Shanti Overseas (India) Limited"
    },
    {
        label: "SHANTIGEAR",
        value: "Shanthi Gears Limited"
    },
    {
        label: "SHARDACROP",
        value: "Sharda Cropchem Limited"
    },
    {
        label: "SHARDAMOTR",
        value: "Sharda Motor Industries Limited"
    },
    {
        label: "SHAREINDIA",
        value: "Share India Securities Limited"
    },
    {
        label: "SHEKHAWATI",
        value: "Shekhawati Industries Limited"
    },
    {
        label: "SHEMAROO",
        value: "Shemaroo Entertainment Limited"
    },
    {
        label: "SHILPAMED",
        value: "Shilpa Medicare Limited"
    },
    {
        label: "SHIVALIK",
        value: "Shivalik Rasayan Limited"
    },
    {
        label: "SHIVAMAUTO",
        value: "Shivam Autotech Limited"
    },
    {
        label: "SHIVAMILLS",
        value: "Shiva Mills Limited"
    },
    {
        label: "SHIVATEX",
        value: "Shiva Texyarn Limited"
    },
    {
        label: "SHK",
        value: "S H Kelkar and Company Limited"
    },
    {
        label: "SHOPERSTOP",
        value: "Shoppers Stop Limited"
    },
    {
        label: "SHRADHA",
        value: "Shradha Infraprojects Limited"
    },
    {
        label: "SHREDIGCEM",
        value: "Shree Digvijay Cement Co.Ltd"
    },
    {
        label: "SHREECEM",
        value: "SHREE CEMENT LIMITED"
    },
    {
        label: "SHREEPUSHK",
        value: "Shree Pushkar Chemicals & Fertilisers Limited"
    },
    {
        label: "SHREERAMA",
        value: "Shree Rama Multi-Tech Limited"
    },
    {
        label: "SHRENIK",
        value: "Shrenik Limited"
    },
    {
        label: "SHREYANIND",
        value: "Shreyans Industries Limited"
    },
    {
        label: "SHRIPISTON",
        value: "Shriram Pistons & Rings Limited"
    },
    {
        label: "SHRIRAMFIN",
        value: "Shriram Finance Limited"
    },
    {
        label: "SHRIRAMPPS",
        value: "Shriram Properties Limited"
    },
    {
        label: "SHYAMCENT",
        value: "Shyam Century Ferrous Limited"
    },
    {
        label: "SHYAMMETL",
        value: "Shyam Metalics and Energy Limited"
    },
    {
        label: "SHYAMTEL",
        value: "Shyam Telecom Limited"
    },
    {
        label: "SICALLOG",
        value: "Sical Logistics Limited"
    },
    {
        label: "SIEMENS",
        value: "Siemens Limited"
    },
    {
        label: "SIGACHI",
        value: "Sigachi Industries Limited"
    },
    {
        label: "SIGIND",
        value: "Signet Industries Limited"
    },
    {
        label: "SIGMA",
        value: "Sigma Solve Limited"
    },
    {
        label: "SIGNATURE",
        value: "Signatureglobal (India) Limited"
    },
    {
        label: "SIGNPOST",
        value: "Signpost India Limited"
    },
    {
        label: "SIKKO",
        value: "Sikko Industries Limited"
    },
    {
        label: "SIL",
        value: "Standard Industries Limited"
    },
    {
        label: "SILGO",
        value: "Silgo Retail Limited"
    },
    {
        label: "SILINV",
        value: "SIL Investments Limited"
    },
    {
        label: "SILLYMONKS",
        value: "Silly Monks Entertainment Limited"
    },
    {
        label: "SILVERTUC",
        value: "Silver Touch Technologies Limited"
    },
    {
        label: "SIMBHALS",
        value: "Simbhaoli Sugars Limited"
    },
    {
        label: "SIMPLEXINF",
        value: "Simplex Infrastructures Limited"
    },
    {
        label: "SINCLAIR",
        value: "Sinclairs Hotels Limited"
    },
    {
        label: "SINDHUTRAD",
        value: "Sindhu Trade Links Limited"
    },
    {
        label: "SINTERCOM",
        value: "Sintercom India Limited"
    },
    {
        label: "SIRCA",
        value: "Sirca Paints India Limited"
    },
    {
        label: "SIS",
        value: "SIS LIMITED"
    },
    {
        label: "SITINET",
        value: "Siti Networks Limited"
    },
    {
        label: "SIYSIL",
        value: "Siyaram Silk Mills Limited"
    },
    {
        label: "SJS",
        value: "S.J.S. Enterprises Limited"
    },
    {
        label: "SJVN",
        value: "SJVN Limited"
    },
    {
        label: "SKFINDIA",
        value: "SKF India Limited"
    },
    {
        label: "SKIPPER",
        value: "Skipper Limited"
    },
    {
        label: "SKMEGGPROD",
        value: "SKM Egg Products Export (India) Limited"
    },
    {
        label: "SKYGOLD",
        value: "Sky Gold Limited"
    },
    {
        label: "SMARTLINK",
        value: "Smartlink Holdings Limited"
    },
    {
        label: "SMCGLOBAL",
        value: "SMC Global Securities Limited"
    },
    {
        label: "SMLISUZU",
        value: "SML Isuzu Limited"
    },
    {
        label: "SMLT",
        value: "Sarthak Metals Limited"
    },
    {
        label: "SMSLIFE",
        value: "SMS Lifesciences India Limited"
    },
    {
        label: "SMSPHARMA",
        value: "SMS Pharmaceuticals Limited"
    },
    {
        label: "SNOWMAN",
        value: "Snowman Logistics Limited"
    },
    {
        label: "SOBHA",
        value: "Sobha Limited"
    },
    {
        label: "SOFTTECH",
        value: "Softtech Engineers Limited"
    },
    {
        label: "SOLARA",
        value: "Solara Active Pharma Sciences Limited"
    },
    {
        label: "SOLARINDS",
        value: "Solar Industries India Limited"
    },
    {
        label: "SOMANYCERA",
        value: "Somany Ceramics Limited"
    },
    {
        label: "SOMATEX",
        value: "Soma Textiles & Industries Limited"
    },
    {
        label: "SOMICONVEY",
        value: "Somi Conveyor Beltings Limited"
    },
    {
        label: "SONACOMS",
        value: "Sona BLW Precision Forgings Limited"
    },
    {
        label: "SONAMLTD",
        value: "SONAM LIMITED"
    },
    {
        label: "SONATSOFTW",
        value: "Sonata Software Limited"
    },
    {
        label: "SOTL",
        value: "Savita Oil Technologies Limited"
    },
    {
        label: "SOUTHBANK",
        value: "The South Indian Bank Limited"
    },
    {
        label: "SOUTHWEST",
        value: "South West Pinnacle Exploration Limited"
    },
    {
        label: "SPAL",
        value: "S. P. Apparels Limited"
    },
    {
        label: "SPANDANA",
        value: "Spandana Sphoorty Financial Limited"
    },
    {
        label: "SPARC",
        value: "Sun Pharma Advanced Research Company Limited"
    },
    {
        label: "SPCENET",
        value: "Spacenet Enterprises India Limited"
    },
    {
        label: "SPECIALITY",
        value: "Speciality Restaurants Limited"
    },
    {
        label: "SPENCERS",
        value: "Spencer's Retail Limited"
    },
    {
        label: "SPIC",
        value: "Southern Petrochemicals Industries Corporation  Limited"
    },
    {
        label: "SPLIL",
        value: "SPL Industries Limited"
    },
    {
        label: "SPLPETRO",
        value: "Supreme Petrochem Limited"
    },
    {
        label: "SPMLINFRA",
        value: "SPML Infra Limited"
    },
    {
        label: "SPORTKING",
        value: "Sportking India Limited"
    },
    {
        label: "SRD",
        value: "Shankar Lal Rampal Dye-Chem Limited"
    },
    {
        label: "SREEL",
        value: "Sreeleathers Limited"
    },
    {
        label: "SRF",
        value: "SRF Limited"
    },
    {
        label: "SRGHFL",
        value: "SRG Housing Finance Limited"
    },
    {
        label: "SRHHYPOLTD",
        value: "Sree Rayalaseema Hi-Strength Hypo Limited"
    },
    {
        label: "SRM",
        value: "SRM Contractors Limited"
    },
    {
        label: "SRPL",
        value: "Shree Ram Proteins Limited"
    },
    {
        label: "SSDL",
        value: "Saraswati Saree Depot Limited"
    },
    {
        label: "SSWL",
        value: "Steel Strips Wheels Limited"
    },
    {
        label: "STANLEY",
        value: "Stanley Lifestyles Limited"
    },
    {
        label: "STAR",
        value: "Strides Pharma Science Limited"
    },
    {
        label: "STARCEMENT",
        value: "Star Cement Limited"
    },
    {
        label: "STARHEALTH",
        value: "Star Health and Allied Insurance Company Limited"
    },
    {
        label: "STARPAPER",
        value: "Star Paper Mills Limited"
    },
    {
        label: "STARTECK",
        value: "Starteck Finance Limited"
    },
    {
        label: "STCINDIA",
        value: "The State Trading Corporation of India Limited"
    },
    {
        label: "STEELCAS",
        value: "Steelcast Limited"
    },
    {
        label: "STEELCITY",
        value: "Steel City Securities Limited"
    },
    {
        label: "STEELXIND",
        value: "STEEL EXCHANGE INDIA LIMITED"
    },
    {
        label: "STEL",
        value: "Stel Holdings Limited"
    },
    {
        label: "STERTOOLS",
        value: "Sterling Tools Limited"
    },
    {
        label: "STLTECH",
        value: "Sterlite Technologies Limited"
    },
    {
        label: "STOVEKRAFT",
        value: "Stove Kraft Limited"
    },
    {
        label: "STYLAMIND",
        value: "Stylam Industries Limited"
    },
    {
        label: "STYLEBAAZA",
        value: "Baazar Style Retail Limited"
    },
    {
        label: "STYRENIX",
        value: "Styrenix Performance Materials Limited"
    },
    {
        label: "SUBEXLTD",
        value: "Subex Limited"
    },
    {
        label: "SUBROS",
        value: "Subros Limited"
    },
    {
        label: "SUDARSCHEM",
        value: "Sudarshan Chemical Industries Limited"
    },
    {
        label: "SUKHJITS",
        value: "Sukhjit Starch & Chemicals Limited"
    },
    {
        label: "SULA",
        value: "Sula Vineyards Limited"
    },
    {
        label: "SUMICHEM",
        value: "Sumitomo Chemical India Limited"
    },
    {
        label: "SUMIT",
        value: "Sumit Woods Limited"
    },
    {
        label: "SUMMITSEC",
        value: "Summit Securities Limited"
    },
    {
        label: "SUNCLAY",
        value: "Sundaram Clayton Limited"
    },
    {
        label: "SUNDARAM",
        value: "Sundaram Multi Pap Limited"
    },
    {
        label: "SUNDARMFIN",
        value: "Sundaram Finance Limited"
    },
    {
        label: "SUNDARMHLD",
        value: "Sundaram Finance Holdings Limited"
    },
    {
        label: "SUNDRMBRAK",
        value: "Sundaram Brake Linings Limited"
    },
    {
        label: "SUNDRMFAST",
        value: "Sundram Fasteners Limited"
    },
    {
        label: "SUNFLAG",
        value: "Sunflag Iron And Steel Company Limited"
    },
    {
        label: "SUNPHARMA",
        value: "Sun Pharmaceutical Industries Limited"
    },
    {
        label: "SUNTECK",
        value: "Sunteck Realty Limited"
    },
    {
        label: "SUNTV",
        value: "Sun TV Network Limited"
    },
    {
        label: "SUPERHOUSE",
        value: "Superhouse Limited"
    },
    {
        label: "SUPERSPIN",
        value: "Super Spinning Mills Limited"
    },
    {
        label: "SUPRAJIT",
        value: "Suprajit Engineering Limited"
    },
    {
        label: "SUPREMEENG",
        value: "Supreme Engineering Limited"
    },
    {
        label: "SUPREMEIND",
        value: "Supreme Industries Limited"
    },
    {
        label: "SUPREMEINF",
        value: "Supreme Infrastructure India Limited"
    },
    {
        label: "SUPRIYA",
        value: "Supriya Lifescience Limited"
    },
    {
        label: "SURAJEST",
        value: "Suraj Estate Developers Limited"
    },
    {
        label: "SURAJLTD",
        value: "Suraj Limited"
    },
    {
        label: "SURANASOL",
        value: "Surana Solar Limited"
    },
    {
        label: "SURANAT&P",
        value: "Surana Telecom and Power Limited"
    },
    {
        label: "SURYALAXMI",
        value: "Suryalakshmi Cotton Mills Limited"
    },
    {
        label: "SURYAROSNI",
        value: "Surya Roshni Limited"
    },
    {
        label: "SURYODAY",
        value: "Suryoday Small Finance Bank Limited"
    },
    {
        label: "SUTLEJTEX",
        value: "Sutlej Textiles and Industries Limited"
    },
    {
        label: "SUULD",
        value: "Suumaya Industries Limited"
    },
    {
        label: "SUVEN",
        value: "Suven Life Sciences Limited"
    },
    {
        label: "SUVENPHAR",
        value: "Suven Pharmaceuticals Limited"
    },
    {
        label: "SUVIDHAA",
        value: "Suvidhaa Infoserve Limited"
    },
    {
        label: "SUYOG",
        value: "Suyog Telematics Limited"
    },
    {
        label: "SUZLON",
        value: "Suzlon Energy Limited"
    },
    {
        label: "SVLL",
        value: "Shree Vasu Logistics Limited"
    },
    {
        label: "SVPGLOB",
        value: "SVP GLOBAL TEXTILES LIMITED"
    },
    {
        label: "SWANENERGY",
        value: "Swan Energy Limited"
    },
    {
        label: "SWARAJENG",
        value: "Swaraj Engines Limited"
    },
    {
        label: "SWELECTES",
        value: "Swelect Energy Systems Limited"
    },
    {
        label: "SWIGGY",
        value: "Swiggy Limited"
    },
    {
        label: "SWSOLAR",
        value: "Sterling and Wilson Renewable Energy Limited"
    },
    {
        label: "SYMPHONY",
        value: "Symphony Limited"
    },
    {
        label: "SYNCOMF",
        value: "Syncom Formulations (India) Limited"
    },
    {
        label: "SYNGENE",
        value: "Syngene International Limited"
    },
    {
        label: "SYRMA",
        value: "Syrma SGS Technology Limited"
    },
    {
        label: "TAINWALCHM",
        value: "Tainwala Chemical and Plastic (I) Limited"
    },
    {
        label: "TAJGVK",
        value: "Taj GVK Hotels & Resorts Limited"
    },
    {
        label: "TAKE",
        value: "Take Solutions Limited"
    },
    {
        label: "TALBROAUTO",
        value: "Talbros Automotive Components Limited"
    },
    {
        label: "TANLA",
        value: "Tanla Platforms Limited"
    },
    {
        label: "TARACHAND",
        value: "Tara Chand InfraLogistic Solutions Limited"
    },
    {
        label: "TARAPUR",
        value: "Tarapur Transformers Limited"
    },
    {
        label: "TARC",
        value: "TARC Limited"
    },
    {
        label: "TARIL",
        value: "Transformers And Rectifiers (India) Limited"
    },
    {
        label: "TARMAT",
        value: "Tarmat Limited"
    },
    {
        label: "TARSONS",
        value: "Tarsons Products Limited"
    },
    {
        label: "TASTYBITE",
        value: "Tasty Bite Eatables Limited"
    },
    {
        label: "TATACHEM",
        value: "Tata Chemicals Limited"
    },
    {
        label: "TATACOMM",
        value: "Tata Communications Limited"
    },
    {
        label: "TATACONSUM",
        value: "TATA CONSUMER PRODUCTS LIMITED"
    },
    {
        label: "TATAELXSI",
        value: "Tata Elxsi Limited"
    },
    {
        label: "TATAINVEST",
        value: "Tata Investment Corporation Limited"
    },
    {
        label: "TATAMOTORS",
        value: "Tata Motors Limited"
    },
    {
        label: "TATAPOWER",
        value: "Tata Power Company Limited"
    },
    {
        label: "TATASTEEL",
        value: "Tata Steel Limited"
    },
    {
        label: "TATATECH",
        value: "Tata Technologies Limited"
    },
    {
        label: "TATVA",
        value: "Tatva Chintan Pharma Chem Limited"
    },
    {
        label: "TBOTEK",
        value: "TBO Tek Limited"
    },
    {
        label: "TBZ",
        value: "Tribhovandas Bhimji Zaveri Limited"
    },
    {
        label: "TCI",
        value: "Transport Corporation of India Limited"
    },
    {
        label: "TCIEXP",
        value: "TCI Express Limited"
    },
    {
        label: "TCIFINANCE",
        value: "TCI Finance Limited"
    },
    {
        label: "TCLCONS",
        value: "Tantia Constructions Limited"
    },
    {
        label: "TCPLPACK",
        value: "TCPL Packaging Limited"
    },
    {
        label: "TCS",
        value: "Tata Consultancy Services Limited"
    },
    {
        label: "TDPOWERSYS",
        value: "TD Power Systems Limited"
    },
    {
        label: "TEAMLEASE",
        value: "Teamlease Services Limited"
    },
    {
        label: "TECHM",
        value: "Tech Mahindra Limited"
    },
    {
        label: "TECHNOE",
        value: "Techno Electric & Engineering Company Limited"
    },
    {
        label: "TECILCHEM",
        value: "TECIL Chemicals and Hydro Power Limited"
    },
    {
        label: "TEGA",
        value: "Tega Industries Limited"
    },
    {
        label: "TEJASNET",
        value: "Tejas Networks Limited"
    },
    {
        label: "TEMBO",
        value: "Tembo Global Industries Limited"
    },
    {
        label: "TERASOFT",
        value: "Tera Software Limited"
    },
    {
        label: "TEXINFRA",
        value: "Texmaco Infrastructure & Holdings Limited"
    },
    {
        label: "TEXMOPIPES",
        value: "Texmo Pipes and Products Limited"
    },
    {
        label: "TEXRAIL",
        value: "Texmaco Rail & Engineering Limited"
    },
    {
        label: "TFCILTD",
        value: "Tourism Finance Corporation of India Limited"
    },
    {
        label: "TFL",
        value: "Transwarranty Finance Limited"
    },
    {
        label: "TGBHOTELS",
        value: "TGB Banquets And Hotels Limited"
    },
    {
        label: "THANGAMAYL",
        value: "Thangamayil Jewellery Limited"
    },
    {
        label: "THEINVEST",
        value: "The Investment Trust Of India Limited"
    },
    {
        label: "THEJO",
        value: "Thejo Engineering Limited"
    },
    {
        label: "THEMISMED",
        value: "Themis Medicare Limited"
    },
    {
        label: "THERMAX",
        value: "Thermax Limited"
    },
    {
        label: "THOMASCOOK",
        value: "Thomas Cook  (India)  Limited"
    },
    {
        label: "THOMASCOTT",
        value: "Thomas Scott (India) Limited"
    },
    {
        label: "THYROCARE",
        value: "Thyrocare Technologies Limited"
    },
    {
        label: "TI",
        value: "Tilaknagar Industries Limited"
    },
    {
        label: "TIIL",
        value: "Technocraft Industries (India) Limited"
    },
    {
        label: "TIINDIA",
        value: "Tube Investments of India Limited"
    },
    {
        label: "TIJARIA",
        value: "Tijaria Polypipes Limited"
    },
    {
        label: "TIL",
        value: "TIL Limited"
    },
    {
        label: "TIMESGTY",
        value: "Times Guaranty Limited"
    },
    {
        label: "TIMETECHNO",
        value: "Time Technoplast Limited"
    },
    {
        label: "TIMKEN",
        value: "Timken India Limited"
    },
    {
        label: "TIPSFILMS",
        value: "Tips Films Limited"
    },
    {
        label: "TIPSMUSIC",
        value: "Tips Music Limited"
    },
    {
        label: "TIRUMALCHM",
        value: "Thirumalai Chemicals Limited"
    },
    {
        label: "TIRUPATIFL",
        value: "Tirupati Forge Limited"
    },
    {
        label: "TITAGARH",
        value: "TITAGARH RAIL SYSTEMS LIMITED"
    },
    {
        label: "TITAN",
        value: "Titan Company Limited"
    },
    {
        label: "TMB",
        value: "Tamilnad Mercantile Bank Limited"
    },
    {
        label: "TNPETRO",
        value: "Tamilnadu PetroProducts Limited"
    },
    {
        label: "TNPL",
        value: "Tamil Nadu Newsprint & Papers Limited"
    },
    {
        label: "TNTELE",
        value: "Tamilnadu Telecommunication Limited"
    },
    {
        label: "TOKYOPLAST",
        value: "Tokyo Plast International Limited"
    },
    {
        label: "TOLINS",
        value: "Tolins Tyres Limited"
    },
    {
        label: "TORNTPHARM",
        value: "Torrent Pharmaceuticals Limited"
    },
    {
        label: "TORNTPOWER",
        value: "Torrent Power Limited"
    },
    {
        label: "TOTAL",
        value: "Total Transport Systems Limited"
    },
    {
        label: "TOUCHWOOD",
        value: "Touchwood Entertainment Limited"
    },
    {
        label: "TPHQ",
        value: "Teamo Productions HQ Limited"
    },
    {
        label: "TPLPLASTEH",
        value: "TPL Plastech Limited"
    },
    {
        label: "TRACXN",
        value: "Tracxn Technologies Limited"
    },
    {
        label: "TRANSWORLD",
        value: "TRANSWORLD SHIPPING LINES LIMITED"
    },
    {
        label: "TREEHOUSE",
        value: "Tree House Education & Accessories Limited"
    },
    {
        label: "TREJHARA",
        value: "TREJHARA SOLUTIONS LIMITED"
    },
    {
        label: "TREL",
        value: "Transindia Real Estate Limited"
    },
    {
        label: "TRENT",
        value: "Trent Limited"
    },
    {
        label: "TRF",
        value: "TRF Limited"
    },
    {
        label: "TRIDENT",
        value: "Trident Limited"
    },
    {
        label: "TRIGYN",
        value: "Trigyn Technologies Limited"
    },
    {
        label: "TRITURBINE",
        value: "Triveni Turbine Limited"
    },
    {
        label: "TRIVENI",
        value: "Triveni Engineering & Industries Limited"
    },
    {
        label: "TRU",
        value: "TruCap Finance Limited"
    },
    {
        label: "TTKHLTCARE",
        value: "TTK Healthcare Limited"
    },
    {
        label: "TTKPRESTIG",
        value: "TTK Prestige Limited"
    },
    {
        label: "TTL",
        value: "T T Limited"
    },
    {
        label: "TTML",
        value: "Tata Teleservices (Maharashtra) Limited"
    },
    {
        label: "TVSELECT",
        value: "TVS Electronics Limited"
    },
    {
        label: "TVSHLTD",
        value: "TVS Holdings Limited"
    },
    {
        label: "TVSMOTOR",
        value: "TVS Motor Company Limited"
    },
    {
        label: "TVSSCS",
        value: "TVS Supply Chain Solutions Limited"
    },
    {
        label: "TVSSRICHAK",
        value: "TVS Srichakra Limited"
    },
    {
        label: "TVTODAY",
        value: "TV Today Network Limited"
    },
    {
        label: "TVVISION",
        value: "TV Vision Limited"
    },
    {
        label: "UBL",
        value: "United Breweries Limited"
    },
    {
        label: "UCAL",
        value: "UCAL LIMITED"
    },
    {
        label: "UCOBANK",
        value: "UCO Bank"
    },
    {
        label: "UDAICEMENT",
        value: "Udaipur Cement Works Limited"
    },
    {
        label: "UDS",
        value: "Updater Services Limited"
    },
    {
        label: "UFLEX",
        value: "UFLEX Limited"
    },
    {
        label: "UFO",
        value: "UFO Moviez India Limited"
    },
    {
        label: "UGARSUGAR",
        value: "The Ugar Sugar Works Limited"
    },
    {
        label: "UGROCAP",
        value: "Ugro Capital Limited"
    },
    {
        label: "UJJIVANSFB",
        value: "Ujjivan Small Finance Bank Limited"
    },
    {
        label: "ULTRACEMCO",
        value: "UltraTech Cement Limited"
    },
    {
        label: "UMAEXPORTS",
        value: "Uma Exports Limited"
    },
    {
        label: "UMANGDAIRY",
        value: "Umang Dairies Limited"
    },
    {
        label: "UMESLTD",
        value: "Usha Martin Education & Solutions Limited"
    },
    {
        label: "UNICHEMLAB",
        value: "Unichem Laboratories Limited"
    },
    {
        label: "UNIDT",
        value: "United Drilling Tools Limited"
    },
    {
        label: "UNIECOM",
        value: "Unicommerce Esolutions Limited"
    },
    {
        label: "UNIENTER",
        value: "Uniphos Enterprises Limited"
    },
    {
        label: "UNIINFO",
        value: "Uniinfo Telecom Services Limited"
    },
    {
        label: "UNIONBANK",
        value: "Union Bank of India"
    },
    {
        label: "UNIPARTS",
        value: "Uniparts India Limited"
    },
    {
        label: "UNITDSPR",
        value: "United Spirits Limited"
    },
    {
        label: "UNITECH",
        value: "Unitech Limited"
    },
    {
        label: "UNITEDPOLY",
        value: "United Polyfab Gujarat Limited"
    },
    {
        label: "UNITEDTEA",
        value: "The United Nilgiri Tea Estates Company Limited"
    },
    {
        label: "UNIVASTU",
        value: "Univastu India Limited"
    },
    {
        label: "UNIVCABLES",
        value: "Universal Cables Limited"
    },
    {
        label: "UNIVPHOTO",
        value: "Universus Photo Imagings Limited"
    },
    {
        label: "UNOMINDA",
        value: "UNO Minda Limited"
    },
    {
        label: "UPL",
        value: "UPL Limited"
    },
    {
        label: "URAVI",
        value: "Uravi T and Wedge Lamps Limited"
    },
    {
        label: "URJA",
        value: "Urja Global Limited"
    },
    {
        label: "USHAMART",
        value: "Usha Martin Limited"
    },
    {
        label: "USK",
        value: "Udayshivakumar Infra Limited"
    },
    {
        label: "UTIAMC",
        value: "UTI Asset Management Company Limited"
    },
    {
        label: "UTKARSHBNK",
        value: "Utkarsh Small Finance Bank Limited"
    },
    {
        label: "UTTAMSUGAR",
        value: "Uttam Sugar Mills Limited"
    },
    {
        label: "UYFINCORP",
        value: "U. Y. Fincorp Limited"
    },
    {
        label: "V2RETAIL",
        value: "V2 Retail Limited"
    },
    {
        label: "VADILALIND",
        value: "Vadilal Industries Limited"
    },
    {
        label: "VAIBHAVGBL",
        value: "Vaibhav Global Limited"
    },
    {
        label: "VAISHALI",
        value: "Vaishali Pharma Limited"
    },
    {
        label: "VAKRANGEE",
        value: "Vakrangee Limited"
    },
    {
        label: "VALIANTLAB",
        value: "Valiant Laboratories Limited"
    },
    {
        label: "VALIANTORG",
        value: "Valiant Organics Limited"
    },
    {
        label: "VARDHACRLC",
        value: "Vardhman Acrylics Limited"
    },
    {
        label: "VARDMNPOLY",
        value: "Vardhman Polytex Limited"
    },
    {
        label: "VARROC",
        value: "Varroc Engineering Limited"
    },
    {
        label: "VASCONEQ",
        value: "Vascon Engineers Limited"
    },
    {
        label: "VASWANI",
        value: "Vaswani Industries Limited"
    },
    {
        label: "VBL",
        value: "Varun Beverages Limited"
    },
    {
        label: "VCL",
        value: "Vaxtex Cotfab Limited"
    },
    {
        label: "VEDL",
        value: "Vedanta Limited"
    },
    {
        label: "VEEDOL",
        value: "Veedol Corporation Limited"
    },
    {
        label: "VENKEYS",
        value: "Venky's (India) Limited"
    },
    {
        label: "VENUSPIPES",
        value: "Venus Pipes & Tubes Limited"
    },
    {
        label: "VENUSREM",
        value: "Venus Remedies Limited"
    },
    {
        label: "VERANDA",
        value: "Veranda Learning Solutions Limited"
    },
    {
        label: "VERTOZ",
        value: "Vertoz Limited"
    },
    {
        label: "VESUVIUS",
        value: "Vesuvius India Limited"
    },
    {
        label: "VETO",
        value: "Veto Switchgears And Cables Limited"
    },
    {
        label: "VGUARD",
        value: "V-Guard Industries Limited"
    },
    {
        label: "VHL",
        value: "Vardhman Holdings Limited"
    },
    {
        label: "VHLTD",
        value: "Viceroy Hotels Limited"
    },
    {
        label: "VIDHIING",
        value: "Vidhi Specialty Food Ingredients Limited"
    },
    {
        label: "VIJAYA",
        value: "Vijaya Diagnostic Centre Limited"
    },
    {
        label: "VIJIFIN",
        value: "Viji Finance Limited"
    },
    {
        label: "VIKASECO",
        value: "Vikas EcoTech Limited"
    },
    {
        label: "VIKASLIFE",
        value: "Vikas Lifecare Limited"
    },
    {
        label: "VIMTALABS",
        value: "Vimta Labs Limited"
    },
    {
        label: "VINATIORGA",
        value: "Vinati Organics Limited"
    },
    {
        label: "VINCOFE",
        value: "Vintage Coffee And Beverages Limited"
    },
    {
        label: "VINDHYATEL",
        value: "Vindhya Telelinks Limited"
    },
    {
        label: "VINEETLAB",
        value: "Vineet Laboratories Limited"
    },
    {
        label: "VINNY",
        value: "Vinny Overseas Limited"
    },
    {
        label: "VINYLINDIA",
        value: "Vinyl Chemicals (India) Limited"
    },
    {
        label: "VIPCLOTHNG",
        value: "VIP Clothing Limited"
    },
    {
        label: "VIPIND",
        value: "VIP Industries Limited"
    },
    {
        label: "VIPULLTD",
        value: "Vipul Limited"
    },
    {
        label: "VIRINCHI",
        value: "Virinchi Limited"
    },
    {
        label: "VISAKAIND",
        value: "Visaka Industries Limited"
    },
    {
        label: "VISHNU",
        value: "Vishnu Chemicals Limited"
    },
    {
        label: "VISHWARAJ",
        value: "Vishwaraj Sugar Industries Limited"
    },
    {
        label: "VIVIDHA",
        value: "Visagar Polytex Limited"
    },
    {
        label: "VLEGOV",
        value: "VL E-Governance & IT Solutions Limited"
    },
    {
        label: "VLSFINANCE",
        value: "VLS Finance Limited"
    },
    {
        label: "VMART",
        value: "V-Mart Retail Limited"
    },
    {
        label: "VOLTAMP",
        value: "Voltamp Transformers Limited"
    },
    {
        label: "VOLTAS",
        value: "Voltas Limited"
    },
    {
        label: "VPRPL",
        value: "Vishnu Prakash R Punglia Limited"
    },
    {
        label: "VRAJ",
        value: "Vraj Iron and Steel Limited"
    },
    {
        label: "VRLLOG",
        value: "VRL Logistics Limited"
    },
    {
        label: "VSSL",
        value: "Vardhman Special Steels Limited"
    },
    {
        label: "VSTIND",
        value: "VST Industries Limited"
    },
    {
        label: "VSTL",
        value: "Vibhor Steel Tubes Limited"
    },
    {
        label: "VSTTILLERS",
        value: "V.S.T Tillers Tractors Limited"
    },
    {
        label: "VTL",
        value: "Vardhman Textiles Limited"
    },
    {
        label: "WAAREEENER",
        value: "Waaree Energies Limited"
    },
    {
        label: "WABAG",
        value: "VA Tech Wabag Limited"
    },
    {
        label: "WALCHANNAG",
        value: "Walchandnagar Industries Limited"
    },
    {
        label: "WANBURY",
        value: "Wanbury Limited"
    },
    {
        label: "WCIL",
        value: "Western Carriers (India) Limited"
    },
    {
        label: "WEALTH",
        value: "Wealth First Portfolio Managers Limited"
    },
    {
        label: "WEBELSOLAR",
        value: "Websol Energy System Limited"
    },
    {
        label: "WEIZMANIND",
        value: "Weizmann Limited"
    },
    {
        label: "WEL",
        value: "Wonder Electricals Limited"
    },
    {
        label: "WELCORP",
        value: "Welspun Corp Limited"
    },
    {
        label: "WELENT",
        value: "Welspun Enterprises Limited"
    },
    {
        label: "WELINV",
        value: "Welspun Investments and Commercials Limited"
    },
    {
        label: "WELSPUNLIV",
        value: "Welspun Living Limited"
    },
    {
        label: "WENDT",
        value: "Wendt (India) Limited"
    },
    {
        label: "WESTLIFE",
        value: "WESTLIFE FOODWORLD LIMITED"
    },
    {
        label: "WEWIN",
        value: "WE WIN LIMITED"
    },
    {
        label: "WHEELS",
        value: "Wheels India Limited"
    },
    {
        label: "WHIRLPOOL",
        value: "Whirlpool of India Limited"
    },
    {
        label: "WILLAMAGOR",
        value: "Williamson Magor & Company Limited"
    },
    {
        label: "WINDLAS",
        value: "Windlas Biotech Limited"
    },
    {
        label: "WINDMACHIN",
        value: "Windsor Machines Limited"
    },
    {
        label: "WINSOME",
        value: "Winsome Yarns Limited"
    },
    {
        label: "WIPL",
        value: "The Western India Plywoods Limited"
    },
    {
        label: "WIPRO",
        value: "Wipro Limited"
    },
    {
        label: "WOCKPHARMA",
        value: "Wockhardt Limited"
    },
    {
        label: "WONDERLA",
        value: "Wonderla Holidays Limited"
    },
    {
        label: "WORTH",
        value: "Worth Peripherals Limited"
    },
    {
        label: "WSI",
        value: "W S Industries (I) Limited"
    },
    {
        label: "WSTCSTPAPR",
        value: "West Coast Paper Mills Limited"
    },
    {
        label: "XCHANGING",
        value: "Xchanging Solutions Limited"
    },
    {
        label: "XELPMOC",
        value: "Xelpmoc Design And Tech Limited"
    },
    {
        label: "XPROINDIA",
        value: "Xpro India Limited"
    },
    {
        label: "XTGLOBAL",
        value: "Xtglobal Infotech Limited"
    },
    {
        label: "YAARI",
        value: "Yaari Digital Integrated Services Limited"
    },
    {
        label: "YASHO",
        value: "Yasho Industries Limited"
    },
    {
        label: "YATHARTH",
        value: "Yatharth Hospital & Trauma Care Services Limited"
    },
    {
        label: "YATRA",
        value: "Yatra Online Limited"
    },
    {
        label: "YESBANK",
        value: "Yes Bank Limited"
    },
    {
        label: "YUKEN",
        value: "Yuken India Limited"
    },
    {
        label: "ZAGGLE",
        value: "Zaggle Prepaid Ocean Services Limited"
    },
    {
        label: "ZEEL",
        value: "Zee Entertainment Enterprises Limited"
    },
    {
        label: "ZEELEARN",
        value: "Zee Learn Limited"
    },
    {
        label: "ZEEMEDIA",
        value: "Zee Media Corporation Limited"
    },
    {
        label: "ZENITHEXPO",
        value: "Zenith Exports Limited"
    },
    {
        label: "ZENITHSTL",
        value: "Zenith Steel Pipes & Industries Limited"
    },
    {
        label: "ZENSARTECH",
        value: "Zensar Technologies Limited"
    },
    {
        label: "ZENTEC",
        value: "Zen Technologies Limited"
    },
    {
        label: "ZFCVINDIA",
        value: "ZF Commercial Vehicle Control Systems India Limited"
    },
    {
        label: "ZIMLAB",
        value: "Zim Laboratories Limited"
    },
    {
        label: "ZODIAC",
        value: "Zodiac Energy Limited"
    },
    {
        label: "ZODIACLOTH",
        value: "Zodiac Clothing Company Limited"
    },
    {
        label: "ZOMATO",
        value: "Zomato Limited"
    },
    {
        label: "ZOTA",
        value: "Zota Health Care LImited"
    },
    {
        label: "ZUARI",
        value: "Zuari Agro Chemicals Limited"
    },
    {
        label: "ZUARIIND",
        value: "ZUARI INDUSTRIES LIMITED"
    },
    {
        label: "ZYDUSLIFE",
        value: "Zydus Lifesciences Limited"
    },
    {
        label: "ZYDUSWELL",
        value: "Zydus Wellness Limited"
    },
    {
        label: "ONYX",
        value: "Onyx Biotec Limited"
    },
    {
        label: "NEELAM",
        value: "Neelam Linens and Garments (India) Limited"
    },
    {
        label: "USHAFIN",
        value: "Usha Financial Services Limited"
    },
    {
        label: "UHTL",
        value: "United Heat Transfer Limited"
    },
    {
        label: "OBSCP",
        value: "OBSC Perfection Limited"
    },
    {
        label: "DANISH",
        value: "Danish Power Limited"
    },
    {
        label: "PREMIUM",
        value: "Premium Plast Limited"
    },
    {
        label: "FRESHARA",
        value: "Freshara Agro Exports Limited"
    },
    {
        label: "LAKSHYA",
        value: "Lakshya Powertech Limited"
    },
    {
        label: "PRANIK",
        value: "Pranik Logistics Limited"
    },
    {
        label: "PARAMOUNT",
        value: "Paramount Dye Tec Limited"
    },
    {
        label: "SAJHOTELS",
        value: "Saj Hotels Limited"
    },
    {
        label: "HVAX",
        value: "HVAX Technologies Limited"
    },
    {
        label: "FORGEAUTO",
        value: "Forge Auto International Limited"
    },
    {
        label: "SAHASRA",
        value: "Sahasra Electronic Solutions Limited"
    },
    {
        label: "DIVYADHAN",
        value: "Divyadhan Recycling Industries Limited"
    },
    {
        label: "THESL",
        value: "Thinking Hats Entertainment Solutions Limited"
    },
    {
        label: "UNILEX",
        value: "Unilex Colours and Chemicals Limited"
    },
    {
        label: "TECHERA",
        value: "TechEra Engineering (India) Limited"
    },
    {
        label: "RAPPID",
        value: "Rappid Valves (India) Limited"
    },
    {
        label: "WOL3D",
        value: "Wol 3D India Limited"
    },
    {
        label: "BIKEWO",
        value: "Bikewo Green Tech Limited"
    },
    {
        label: "AVIANSH",
        value: "Avi Ansh Textile Limited"
    },
    {
        label: "SDREAMS",
        value: "S D Retail Limited"
    },
    {
        label: "PHOGLOBAL",
        value: "Phoenix Overseas Limited"
    },
    {
        label: "KALANA",
        value: "Kalana Ispat Limited"
    },
    {
        label: "PSFL",
        value: "Paramount Speciality Forgings Limited"
    },
    {
        label: "ENVIRO",
        value: "Envirotech Systems Limited"
    },
    {
        label: "DECCANTRAN",
        value: "Deccan Transcon Leasing Limited"
    },
    {
        label: "PELATRO",
        value: "Pelatro Limited"
    },
    {
        label: "OSELDEVICE",
        value: "Osel Devices Limited"
    },
    {
        label: "EXCELLENT",
        value: "Excellent Wires and Packaging Limited"
    },
    {
        label: "INNOMET",
        value: "Innomet Advanced Materials Limited"
    },
    {
        label: "SPPPOLY",
        value: "SPP Polymer Limited"
    },
    {
        label: "AUSL",
        value: "Aditya Ultra Steel Limited"
    },
    {
        label: "GAJANAND",
        value: "Gajanand International Limited"
    },
    {
        label: "SHUBHSHREE",
        value: "Shubhshree Biofuels Energy Limited"
    },
    {
        label: "VIESL",
        value: "Vision Infra Equipment Solutions Limited"
    },
    {
        label: "MYMUDRA",
        value: "My Mudra Fincorp Limited"
    },
    {
        label: "NAMOEWASTE",
        value: "Namo eWaste Management Limited"
    },
    {
        label: "JEYYAM",
        value: "Jeyyam Global Foods Limited"
    },
    {
        label: "BOSS",
        value: "Boss Packaging Solutions Limited"
    },
    {
        label: "AERON",
        value: "Aeron Composite Limited"
    },
    {
        label: "PARAMATRIX",
        value: "Paramatrix Technologies Limited"
    },
    {
        label: "VDEAL",
        value: "Vdeal System Limited"
    },
    {
        label: "IPHL",
        value: "Indian Phosphate Limited"
    },
    {
        label: "JAYBEE",
        value: "Jay Bee Laminations Limited"
    },
    {
        label: "IDEALTECHO",
        value: "Ideal Technoplast Industries Limited"
    },
    {
        label: "QVCEL",
        value: "QVC Exports Limited"
    },
    {
        label: "BRACEPORT",
        value: "Brace Port Logistics Limited"
    },
    {
        label: "FORCAS",
        value: "Forcas Studio Limited"
    },
    {
        label: "BALCO",
        value: "Solve Plastic Products Limited"
    },
    {
        label: "SUNLITE",
        value: "Sunlite Recycling Industries Limited"
    },
    {
        label: "POSITRON",
        value: "Positron Energy Limited"
    },
    {
        label: "AESTHETIK",
        value: "Aesthetik Engineers Limited"
    },
    {
        label: "PPSL",
        value: "Picturepost Studios Limited"
    },
    {
        label: "DHARIWAL",
        value: "Dhariwalcorp Limited"
    },
    {
        label: "UTSSAV",
        value: "Utssav CZ Gold Jewels Limited"
    },
    {
        label: "BULKCORP",
        value: "Bulkcorp International Limited"
    },
    {
        label: "SSEGL",
        value: "Sathlokhar Synergys E&C Global Limited"
    },
    {
        label: "ASHALOG",
        value: "Ashapura Logistics Limited"
    },
    {
        label: "RAJINDLTD",
        value: "Rajputana Industries Limited"
    },
    {
        label: "ESPRIT",
        value: "Esprit Stones Limited"
    },
    {
        label: "SATECH",
        value: "S A Tech Software India Limited"
    },
    {
        label: "TROM",
        value: "Trom Industries Limited"
    },
    {
        label: "APRAMEYA",
        value: "Aprameya Engineering Limited"
    },
    {
        label: "MIEL",
        value: "Manglam Infra & Engineering Limited"
    },
    {
        label: "CHETANA",
        value: "Chetana Education Limited"
    },
    {
        label: "VLINFRA",
        value: "V.L.Infraprojects Limited"
    },
    {
        label: "RNFI",
        value: "RNFI Services Limited"
    },
    {
        label: "MACOBSTECH",
        value: "Macobs Technologies Limited"
    },
    {
        label: "KATARIA",
        value: "Kataria Industries Limited"
    },
    {
        label: "TUNWAL",
        value: "Tunwal E-Motors Limited"
    },
    {
        label: "SATIPOLY",
        value: "Sati Poly Plast Limited"
    },
    {
        label: "PRIZOR",
        value: "Prizor Viztech Limited"
    },
    {
        label: "SAHAJSOLAR",
        value: "Sahaj Solar Limited"
    },
    {
        label: "GGBL",
        value: "Ganesh Green Bharat Limited"
    },
    {
        label: "EFFWA",
        value: "Effwa Infra & Research Limited"
    },
    {
        label: "AMBEY",
        value: "Ambey Laboratories Limited"
    },
    {
        label: "NEPHROCARE",
        value: "Nephro Care India Limited"
    },
    {
        label: "DTL",
        value: "Diensten Tech Limited"
    },
    {
        label: "AKIKO",
        value: "Akiko Global Services Limited"
    },
    {
        label: "DPEL",
        value: "Divine Power Energy Limited"
    },
    {
        label: "PCCL",
        value: "Petro Carbon and Chemicals Limited"
    },
    {
        label: "MASON",
        value: "Mason Infratech Limited"
    },
    {
        label: "VISAMAN",
        value: "Visaman Global Sales Limited"
    },
    {
        label: "SYLVANPLY",
        value: "Sylvan Plyboard (India) Limited"
    },
    {
        label: "SPCL",
        value: "Shivalic Power Control Limited"
    },
    {
        label: "MEDIORG",
        value: "Medicamen Organics Limited"
    },
    {
        label: "WINNY",
        value: "Winny Immigration & Education Services Limited"
    },
    {
        label: "FALCONTECH",
        value: "Falcon Technoprojects India Limited"
    },
    {
        label: "DURLAX",
        value: "Durlax Top Surface Limited"
    },
    {
        label: "GPECO",
        value: "GP Eco Solutions India Limited"
    },
    {
        label: "TBI",
        value: "TBI Corn Limited"
    },
    {
        label: "AIMTRON",
        value: "Aimtron Electronics Limited"
    },
    {
        label: "ZTECH",
        value: "Z-Tech (India) Limited"
    },
    {
        label: "BEACON",
        value: "Beacon Trusteeship Limited"
    },
    {
        label: "VILAS",
        value: "Vilas Transcore Limited"
    },
    {
        label: "GSMFOILS",
        value: "GSM Foils Limited"
    },
    {
        label: "RULKA",
        value: "Rulka Electricals Limited"
    },
    {
        label: "HOACFOODS",
        value: "Hoac Foods India Limited"
    },
    {
        label: "QUESTLAB",
        value: "Quest Laboratories Limited"
    },
    {
        label: "IEML",
        value: "Indian Emulsifiers Limited"
    },
    {
        label: "ABSMARINE",
        value: "ABS Marine Services Limited"
    },
    {
        label: "VERITAAS",
        value: "Veritaas Advertising Limited"
    },
    {
        label: "MANDEEP",
        value: "Mandeep Auto Industries Limited"
    },
    {
        label: "PRLIND",
        value: "Premier Roadlines Limited"
    },
    {
        label: "EMMIL",
        value: "Energy Mission Machineries (India) Limited"
    },
    {
        label: "SILKFLEX",
        value: "Silkflex Polymers (India) Limited"
    },
    {
        label: "WINSOL",
        value: "Winsol Engineers Limited"
    },
    {
        label: "REFRACTORY",
        value: "Refractory Shapes Limited"
    },
    {
        label: "SLONE",
        value: "Slone Infosystems Limited"
    },
    {
        label: "BIRDYS",
        value: "Grill Splendour Services Limited"
    },
    {
        label: "RBS",
        value: "Ramdevbaba Solvent Limited"
    },
    {
        label: "TGL",
        value: "Teerth Gopicon Limited"
    },
    {
        label: "DCG",
        value: "DCG Cables & Wires Limited"
    },
    {
        label: "DRSCARGO",
        value: "DRS Cargo Movers Limited"
    },
    {
        label: "CGRAPHICS",
        value: "Creative Graphics Solutions India Limited"
    },
    {
        label: "ALUWIND",
        value: "Aluwind Architectural Limited"
    },
    {
        label: "K2INFRA",
        value: "K2 Infragen Limited"
    },
    {
        label: "YASHOPTICS",
        value: "Yash Optics & Lens Limited"
    },
    {
        label: "RADIOWALLA",
        value: "Radiowalla Network Limited"
    },
    {
        label: "TAC",
        value: "TAC Infosec Limited"
    },
    {
        label: "TRUST",
        value: "Trust Fintech Limited"
    },
    {
        label: "BLUEPEBBLE",
        value: "Blue Pebble Limited"
    },
    {
        label: "ASPIRE",
        value: "Aspire & Innovative Advertising Limited"
    },
    {
        label: "NAMAN",
        value: "Naman In-Store (India) Limited"
    },
    {
        label: "VISHWAS",
        value: "Vishwas Agri Seeds Limited"
    },
    {
        label: "ENFUSE",
        value: "Enfuse Solutions Limited"
    },
    {
        label: "ENSER",
        value: "Enser Communications Limited"
    },
    {
        label: "AVPINFRA",
        value: "AVP Infracon Limited"
    },
    {
        label: "SIGNORIA",
        value: "Signoria Creation Limited"
    },
    {
        label: "PRATHAM",
        value: "Pratham EPC Projects Limited"
    },
    {
        label: "SHREEKARNI",
        value: "Shree Karni Fabcom Limited"
    },
    {
        label: "SONAMAC",
        value: "Sona Machinery Limited"
    },
    {
        label: "VR",
        value: "V R Infraspace Limited"
    },
    {
        label: "MVKAGRO",
        value: "M.V.K. Agro Food Product Limited"
    },
    {
        label: "PURVFLEXI",
        value: "Purv Flexipack Limited"
    },
    {
        label: "OWAIS",
        value: "Owais Metal And Mineral Processing Limited"
    },
    {
        label: "SADHAV",
        value: "Sadhav Shipping Limited"
    },
    {
        label: "DEEM",
        value: "Deem Roll Tech Limited"
    },
    {
        label: "ZENITHDRUG",
        value: "Zenith Drugs Limited"
    },
    {
        label: "KTL",
        value: "Kalahridhaan Trendz Limited"
    },
    {
        label: "TCL",
        value: "Thaai Casting Limited"
    },
    {
        label: "ATMASTCO",
        value: "Atmastco Limited"
    },
    {
        label: "INM",
        value: "Interiors & More Limited"
    },
    {
        label: "ESCONET",
        value: "Esconet Technologies Limited"
    },
    {
        label: "WTICAB",
        value: "Wise Travel India Limited"
    },
    {
        label: "POLYSIL",
        value: "Polysil Irrigation Systems Limited"
    },
    {
        label: "ALPEXSOLAR",
        value: "Alpex Solar Limited"
    },
    {
        label: "ITALIANE",
        value: "Italian Edibles Limited"
    },
    {
        label: "BAWEJA",
        value: "Baweja Studios Limited"
    },
    {
        label: "MEGATHERM",
        value: "Megatherm Induction Limited"
    },
    {
        label: "FONEBOX",
        value: "Fonebox Retail Limited"
    },
    {
        label: "DELAPLEX",
        value: "Delaplex Limited"
    },
    {
        label: "DHTL",
        value: "Docmode Health Technologies Limited"
    },
    {
        label: "KONSTELEC",
        value: "Konstelec Engineers Limited"
    },
    {
        label: "LAWSIKHO",
        value: "Addictive Learning Technology Limited"
    },
    {
        label: "MAXPOSURE",
        value: "Maxposure Limited"
    },
    {
        label: "APS",
        value: "Australian Premium Solar (India) Limited"
    },
    {
        label: "IBLFL",
        value: "IBL Finance Limited"
    },
    {
        label: "KLL",
        value: "Kaushalya Logistics Limited"
    },
    {
        label: "KCEIL",
        value: "Kay Cee Energy & Infra Limited"
    },
    {
        label: "HRHNEXT",
        value: "HRH Next Services Limited"
    },
    {
        label: "AKANKSHA",
        value: "Akanksha Power and Infrastructure Limited"
    },
    {
        label: "SAIFL",
        value: "Sameera Agro And Infra Limited"
    },
    {
        label: "SUPREMEPWR",
        value: "Supreme Power Equipment Limited"
    },
    {
        label: "TECHLABS",
        value: "Trident Techlabs Limited"
    },
    {
        label: "INDIFRA",
        value: "Indifra Limited"
    },
    {
        label: "EFORCE",
        value: "Electro Force (India) Limited"
    },
    {
        label: "SHREEOSFM",
        value: "Shree OSFM E-Mobility Limited"
    },
    {
        label: "SJLOGISTIC",
        value: "S J Logistics (India) Limited"
    },
    {
        label: "PRESSTONIC",
        value: "Presstonic Engineering Limited"
    },
    {
        label: "ACCENTMIC",
        value: "Accent Microcell Limited"
    },
    {
        label: "GRAPHISAD",
        value: "Graphisads Limited"
    },
    {
        label: "SHEETAL",
        value: "Sheetal Universal Limited"
    },
    {
        label: "CBAZAAR",
        value: "Net Avenue Technologies Limited"
    },
    {
        label: "MARINETRAN",
        value: "Marinetrans India Limited"
    },
    {
        label: "ROCKINGDCE",
        value: "Rockingdeals Circular Economy Limited"
    },
    {
        label: "SUNREST",
        value: "Sunrest Lifescience Limited"
    },
    {
        label: "ROXHITECH",
        value: "Rox Hi Tech Limited"
    },
    {
        label: "BABAFP",
        value: "Baba Food Processing (India) Limited"
    },
    {
        label: "MICROPRO",
        value: "Micropro Software Solutions Limited"
    },
    {
        label: "SARTELE",
        value: "Sar Televenture Limited"
    },
    {
        label: "MAITREYA",
        value: "Maitreya Medicare Limited"
    },
    {
        label: "TRANSTEEL",
        value: "Transteel Seating Technologies Limited"
    },
    {
        label: "SHANTHALA",
        value: "Shanthala FMCG Products Limited"
    },
    {
        label: "PARAGON",
        value: "Paragon Fine and Speciality Chemical Limited"
    },
    {
        label: "ONDOOR",
        value: "On Door Concepts Limited"
    },
    {
        label: "RCDL",
        value: "Rajgor Castor Derivatives Limited"
    },
    {
        label: "WOMANCART",
        value: "Womancart Limited"
    },
    {
        label: "ACSAL",
        value: "Arvind and Company Shipping Agencies Limited"
    },
    {
        label: "COMMITTED",
        value: "Committed Cargo Care Limited"
    },
    {
        label: "PLADAINFO",
        value: "Plada Infotech Services Limited"
    },
    {
        label: "KARNIKA",
        value: "Karnika Industries Limited"
    },
    {
        label: "SCML",
        value: "Sharp Chucks and Machines Limited"
    },
    {
        label: "GOYALSALT",
        value: "Goyal Salt Limited"
    },
    {
        label: "OLIL",
        value: "Oneclick Logistics India Limited"
    },
    {
        label: "CANARYS",
        value: "Canarys Automations Limited"
    },
    {
        label: "KONTOR",
        value: "Kontor Space Limited"
    },
    {
        label: "VISHNUINFR",
        value: "Vishnusurya Projects and Infra Limited"
    },
    {
        label: "ARABIAN",
        value: "Arabian Petroleum Limited"
    },
    {
        label: "EFACTOR",
        value: "E Factor Experiences Limited"
    },
    {
        label: "VINYAS",
        value: "Vinyas Innovative Technologies Limited"
    },
    {
        label: "NEWJAISA",
        value: "Newjaisa Technologies Limited"
    },
    {
        label: "INSPIRE",
        value: "Inspire Films Limited"
    },
    {
        label: "DIGIKORE",
        value: "Digikore Studios Limited"
    },
    {
        label: "MAL",
        value: "Mangalam Alloys Limited"
    },
    {
        label: "SAAKSHI",
        value: "Saakshi Medtech and Panels Limited"
    },
    {
        label: "MASTER",
        value: "Master Components Limited"
    },
    {
        label: "CELLECOR",
        value: "Cellecor Gadgets Limited"
    },
    {
        label: "HIGREEN",
        value: "Hi-Green Carbon Limited"
    },
    {
        label: "MARCO",
        value: "Marco Cables & Conductors Limited"
    },
    {
        label: "KODYTECH",
        value: "Kody Technolab Limited"
    },
    {
        label: "KEL",
        value: "Kundan Edifice Limited"
    },
    {
        label: "MADHUSUDAN",
        value: "Madhusudan Masala Limited"
    },
    {
        label: "HOLMARC",
        value: "Holmarc Opto-Mechatronics Limited"
    },
    {
        label: "CHAVDA",
        value: "Chavda Infra Limited"
    },
    {
        label: "UNIHEALTH",
        value: "Unihealth Hospitals Limited"
    },
    {
        label: "JIWANRAM",
        value: "Jiwanram Sheoduttrai Industries Limited"
    },
    {
        label: "SAROJA",
        value: "Saroja Pharma Industries India Limited"
    },
    {
        label: "PRAMARA",
        value: "Pramara Promotions Limited"
    },
    {
        label: "BASILIC",
        value: "Basilic Fly Studio Limited"
    },
    {
        label: "CPS",
        value: "C P S Shapers Limited"
    },
    {
        label: "MONOPHARMA",
        value: "Mono Pharmacare Limited"
    },
    {
        label: "SAHAJ",
        value: "Sahaj Fashions Limited"
    },
    {
        label: "SEL",
        value: "Sungarner Energies Limited"
    },
    {
        label: "CLSL",
        value: "Crop Life Science Limited"
    },
    {
        label: "SSFL",
        value: "Srivari Spices And Foods Limited"
    },
    {
        label: "YUDIZ",
        value: "Yudiz Solutions Limited"
    },
    {
        label: "SANGANI",
        value: "Sangani Hospitals Limited"
    },
    {
        label: "ORIANA",
        value: "Oriana Power Limited"
    },
    {
        label: "VINSYS",
        value: "Vinsys IT Services India Limited"
    },
    {
        label: "ZEAL",
        value: "Zeal Global Services Limited"
    },
    {
        label: "SHRITECH",
        value: "Shri Techtex Limited"
    },
    {
        label: "YCCL",
        value: "Yasons Chemex Care Limited"
    },
    {
        label: "SERVICE",
        value: "Service Care Limited"
    },
    {
        label: "DRONE",
        value: "Drone Destination Limited"
    },
    {
        label: "SYNOPTICS",
        value: "Synoptics Technologies Limited"
    },
    {
        label: "TRIDHYA",
        value: "Tridhya Tech Limited"
    },
    {
        label: "GLOBALPET",
        value: "Global Pet Industries Limited"
    },
    {
        label: "PENTAGON",
        value: "Pentagon Rubber Limited"
    },
    {
        label: "MAGSON",
        value: "Magson Retail And Distribution Limited"
    },
    {
        label: "ESFL",
        value: "Essen Speciality Films Limited"
    },
    {
        label: "GREENCHEF",
        value: "Greenchef Appliances Limited"
    },
    {
        label: "AATMAJ",
        value: "Aatmaj Healthcare Limited"
    },
    {
        label: "VILINBIO",
        value: "Vilin Bio Med Limited"
    },
    {
        label: "CELLPOINT",
        value: "Cell Point (India) Limited"
    },
    {
        label: "URBAN",
        value: "Urban Enviro Waste Management Limited"
    },
    {
        label: "SPECTSTM",
        value: "Spectrum Talent Management Limited"
    },
    {
        label: "KDL",
        value: "Kore Digital Limited"
    },
    {
        label: "SAHANA",
        value: "Sahana System Limited"
    },
    {
        label: "INFOLLION",
        value: "Infollion Research Services Limited"
    },
    {
        label: "PROV",
        value: "Proventus Agrocom Limited"
    },
    {
        label: "CRAYONS",
        value: "Crayons Advertising Limited"
    },
    {
        label: "DENTALKART",
        value: "Vasa Denticity Limited"
    },
    {
        label: "REMUS",
        value: "Remus Pharmaceuticals Limited"
    },
    {
        label: "KRISHCA",
        value: "Krishca Strapping Solutions Limited"
    },
    {
        label: "AUROIMPEX",
        value: "Auro Impex  & Chemicals Limited"
    },
    {
        label: "DENEERS",
        value: "De Neers Tools Limited"
    },
    {
        label: "QUICKTOUCH",
        value: "Quicktouch Technologies Limited"
    },
    {
        label: "AGUL",
        value: "A G Universal Limited"
    },
    {
        label: "PATTECH",
        value: "Pattech Fitwell Tube Components Limited"
    },
    {
        label: "MOS",
        value: "Mos Utility Limited"
    },
    {
        label: "INFINIUM",
        value: "Infinium Pharmachem Limited"
    },
    {
        label: "SOTAC",
        value: "Sotac Pharmaceuticals Limited"
    },
    {
        label: "NIRMAN",
        value: "Nirman Agri Genetics Limited"
    },
    {
        label: "QFIL",
        value: "Quality Foils (India) Limited"
    },
    {
        label: "VELS",
        value: "Vels Film International Limited"
    },
    {
        label: "MCON",
        value: "Mcon Rasayan India Limited"
    },
    {
        label: "VERTEXPLUS",
        value: "Vertexplus Technologies Limited"
    },
    {
        label: "SYSTANGO",
        value: "Systango Technologies Limited"
    },
    {
        label: "SRIVASAVI",
        value: "Srivasavi Adhesive Tapes Limited"
    },
    {
        label: "VIAZ",
        value: "Viaz Tyres Limited"
    },
    {
        label: "AGARWALFT",
        value: "Agarwal Float Glass India Limited"
    },
    {
        label: "LRRPL",
        value: "Lead Reclaim And Rubber Products Limited"
    },
    {
        label: "SHERA",
        value: "Shera Energy Limited"
    },
    {
        label: "GRCL",
        value: "Gayatri Rubbers And Chemicals Limited"
    },
    {
        label: "ARISTO",
        value: "Aristo Bio-Tech And Lifescience Limited"
    },
    {
        label: "DUCOL",
        value: "Ducol Organics And Colours Limited"
    },
    {
        label: "CMNL",
        value: "Chaman Metallics Limited"
    },
    {
        label: "ANLON",
        value: "Anlon Technology Solutions Limited"
    },
    {
        label: "RBMINFRA",
        value: "Rbm Infracon Limited"
    },
    {
        label: "HOMESFY",
        value: "Homesfy Realty Limited"
    },
    {
        label: "MOXSH",
        value: "Moxsh Overseas Educon Limited"
    },
    {
        label: "ARIHANTACA",
        value: "Arihant Academy Limited"
    },
    {
        label: "UMA",
        value: "Uma Converter Limited"
    },
    {
        label: "DOLLEX",
        value: "Dollex Agrotech Limited"
    },
    {
        label: "ALLETEC",
        value: "All E Technologies Limited"
    },
    {
        label: "ARHAM",
        value: "Arham Technologies Limited"
    },
    {
        label: "PRITIKA",
        value: "Pritika Engineering Components Limited"
    },
    {
        label: "BAHETI",
        value: "Baheti Recycling Industries Limited"
    },
    {
        label: "AMIABLE",
        value: "Amiable Logistics (India) Limited"
    },
    {
        label: "VITAL",
        value: "Vital Chemtech Limited"
    },
    {
        label: "IPSL",
        value: "Integrated Personnel Services Limited"
    },
    {
        label: "RITEZONE",
        value: "Rite Zone Chemcon India Limited"
    },
    {
        label: "PHANTOMFX",
        value: "Phantom Digital Effects Limited"
    },
    {
        label: "FROG",
        value: "Frog Cellsat Limited"
    },
    {
        label: "SWASTIK",
        value: "Swastik Pipe Limited"
    },
    {
        label: "LLOYDS",
        value: "Lloyds Luxuries Limited"
    },
    {
        label: "QMSMEDI",
        value: "QMS Medical Allied Services Limited"
    },
    {
        label: "CMRSL",
        value: "Cyber Media Research & Services Limited"
    },
    {
        label: "MAKS",
        value: "Maks Energy Solutions India Limited"
    },
    {
        label: "KANDARP",
        value: "Kandarp Digi Smart BPO Limited"
    },
    {
        label: "CLOUD",
        value: "Varanium Cloud Limited"
    },
    {
        label: "ANNAPURNA",
        value: "Annapurna Swadisht Limited"
    },
    {
        label: "ISHAN",
        value: "Ishan International Limited"
    },
    {
        label: "TAPIFRUIT",
        value: "Tapi Fruit Processing Limited"
    },
    {
        label: "SABAR",
        value: "Sabar Flex India Limited"
    },
    {
        label: "MEGAFLEX",
        value: "Mega Flex Plastics Limited"
    },
    {
        label: "VIVIANA",
        value: "Viviana Power Tech Limited"
    },
    {
        label: "KORE",
        value: "Jay Jalaram Technologies Limited"
    },
    {
        label: "JFLLIFE",
        value: "Jfl Life Sciences Limited"
    },
    {
        label: "AMEYA",
        value: "Ameya Precision Engineers Limited"
    },
    {
        label: "VEEKAYEM",
        value: "Veekayem Fashion and Apparels Limited"
    },
    {
        label: "USASEEDS",
        value: "Upsurge Seeds Of Agriculture Limited"
    },
    {
        label: "AGNI",
        value: "Agni Green Power Limited"
    },
    {
        label: "SKP",
        value: "SKP Bearing Industries Limited"
    },
    {
        label: "MWL",
        value: "Mangalam Worldwide Limited"
    },
    {
        label: "KCK",
        value: "Kck Industries Limited"
    },
    {
        label: "FIDEL",
        value: "Fidel Softech Limited"
    },
    {
        label: "RILINFRA",
        value: "Rachana Infrastructure Limited"
    },
    {
        label: "GSTL",
        value: "Globesecure Technologies Limited"
    },
    {
        label: "SONUINFRA",
        value: "Sonu Infratech Limited"
    },
    {
        label: "LEMERITE",
        value: "Le Merite Exports Limited"
    },
    {
        label: "JSLL",
        value: "Jeena Sikho Lifecare Limited"
    },
    {
        label: "KRISHNADEF",
        value: "Krishna Defence and Allied Industries Limited"
    },
    {
        label: "PROPEQUITY",
        value: "P. E. Analytics Limited"
    },
    {
        label: "KRISHIVAL",
        value: "Krishival Foods Limited"
    },
    {
        label: "SWARAJ",
        value: "Swaraj Suiting Limited"
    },
    {
        label: "KNAGRI",
        value: "KN Agri Resources Limited"
    },
    {
        label: "COOLCAPS",
        value: "Cool Caps Industries Limited"
    },
    {
        label: "SPRL",
        value: "Sp Refractories Limited"
    },
    {
        label: "SHIGAN",
        value: "Shigan Quantum Technologies Limited"
    },
    {
        label: "MADHAVBAUG",
        value: "Vaidya Sane Ayurved Laboratories Limited"
    },
    {
        label: "RICHA",
        value: "Richa Info Systems Limited"
    },
    {
        label: "PRECISION",
        value: "Precision Metaliks Limited"
    },
    {
        label: "TIMESCAN",
        value: "Timescan Logistics (India) Limited"
    },
    {
        label: "ABCOTS",
        value: "A B Cotspin India Limited"
    },
    {
        label: "VIVO",
        value: "Vivo Collaboration Solutions Limited"
    },
    {
        label: "FOCE",
        value: "Foce India Limited"
    },
    {
        label: "EUROBOND",
        value: "Euro Panel Products Limited"
    },
    {
        label: "NIDAN",
        value: "Nidan Laboratories and Healthcare Limited"
    },
    {
        label: "KOTYARK",
        value: "Kotyark Industries Limited"
    },
    {
        label: "DKEGL",
        value: "D.K. Enterprises Global Limited"
    },
    {
        label: "DYNAMIC",
        value: "Dynamic Services & Security Limited"
    },
    {
        label: "DESTINY",
        value: "Destiny Logistics & Infra Limited"
    },
    {
        label: "BMETRICS",
        value: "Bombay Metrics Supply Chain Limited"
    },
    {
        label: "JAINAM",
        value: "Jainam Ferro Alloys (I) Limited"
    },
    {
        label: "QUADPRO",
        value: "Quadpro Ites Limited"
    },
    {
        label: "BEWLTD",
        value: "BEW Engineering Limited"
    },
    {
        label: "DUGLOBAL",
        value: "DUDIGITAL GLOBAL LIMITED"
    },
    {
        label: "NPST",
        value: "Network People Services Technologies Limited"
    },
    {
        label: "REXPIPES",
        value: "Rex Pipes And Cables Industries Limited"
    },
    {
        label: "WALPAR",
        value: "Walpar Nutritions Limited"
    },
    {
        label: "AILIMITED",
        value: "Abhishek Integrations Limited"
    },
    {
        label: "VMARCIND",
        value: "V Marc India Limited"
    },
    {
        label: "SIDDHIKA",
        value: "Siddhika Coatings Limited"
    },
    {
        label: "PARTYCRUS",
        value: "Party Cruisers Limited"
    },
    {
        label: "ASCOM",
        value: "Ascom Leasing & Investments Limited"
    },
    {
        label: "GOLDKART",
        value: "Goldkart Jewels Limited"
    },
    {
        label: "SHIVAUM",
        value: "Shiv Aum Steels Limited"
    },
    {
        label: "SECL",
        value: "Salasar Exteriors and Contour Limited"
    },
    {
        label: "KHFM",
        value: "Khfm Hospitality And Facility Management Services Limited"
    },
    {
        label: "MINDPOOL",
        value: "Mindpool Technologies Limited"
    },
    {
        label: "SURANI",
        value: "Surani Steel Tubes Limited"
    },
    {
        label: "DRSDILIP",
        value: "DRS Dilip Roadlines Limited"
    },
    {
        label: "SHUBHLAXMI",
        value: "Shubhlaxmi Jewel Art Limited"
    },
    {
        label: "UWCSL",
        value: "Ultra Wiring Connectivity System Limited"
    },
    {
        label: "PARIN",
        value: "Parin Enterprises Limited"
    },
    {
        label: "SPECTRUM",
        value: "Spectrum Electrical Industries Limited"
    },
    {
        label: "UCL",
        value: "Ushanti Colour Chem Limited"
    },
    {
        label: "AMBANIORGO",
        value: "AMBANI ORGOCHEM LIMITED"
    },
    {
        label: "JAKHARIA",
        value: "JAKHARIA FABRIC LIMITED"
    },
    {
        label: "MAHICKRA",
        value: "Mahickra Chemicals Limited"
    },
    {
        label: "VERA",
        value: "Vera Synthetic Limited"
    },
    {
        label: "CONTI",
        value: "Continental Seeds and Chemicals Limited"
    },
    {
        label: "GIRIRAJ",
        value: "Giriraj Civil Developers Limited"
    },
    {
        label: "MDL",
        value: "Marvel Decor Limited"
    },
    {
        label: "MHHL",
        value: "Mohini Health & Hygiene Limited"
    },
    {
        label: "AMJUMBO",
        value: "A and M Jumbo Bags Limited"
    },
    {
        label: "VASA",
        value: "Vasa Retail and Overseas Ltd"
    },
    {
        label: "SOLEX",
        value: "Solex Energy Limited"
    },
    {
        label: "SMVD",
        value: "SMVD Poly Pack Limited"
    },
    {
        label: "INNOVANA",
        value: "Innovana Thinklabs Limited"
    },
    {
        label: "FELIX",
        value: "Felix Industries Limited"
    },
    {
        label: "PULZ",
        value: "Pulz Electronics Limited"
    },
    {
        label: "AISL",
        value: "ANI Integrated Services Limited"
    },
    {
        label: "MILTON",
        value: "Milton Industries Limited"
    },
    {
        label: "OMFURN",
        value: "Omfurn India Limited"
    },
    {
        label: "BETA",
        value: "Beta Drugs Limited"
    },
    {
        label: "CMMIPL",
        value: "CMM Infraprojects Limited"
    },
    {
        label: "GOLDSTAR",
        value: "Goldstar Power Limited"
    },
    {
        label: "TIRUPATI",
        value: "Shree Tirupati Balajee FIBC Limited"
    },
    {
        label: "RMDRIP",
        value: "R M Drip and Sprinklers Systems Limited"
    },
    {
        label: "CADSYS",
        value: "Cadsys (India) Limited"
    },
    {
        label: "MPTODAY",
        value: "Madhya Pradesh Today Media Limited"
    },
    {
        label: "MANAV",
        value: "Manav Infra Projects Limited"
    },
    {
        label: "PASHUPATI",
        value: "Pashupati Cotspin Limited"
    },
    {
        label: "TRANSWIND",
        value: "Transwind Infrastructures Limited"
    },
    {
        label: "ACCORD",
        value: "Accord Synergy Limited"
    },
    {
        label: "VSCL",
        value: "Vadivarhe Speciality Chemicals Limited"
    },
    {
        label: "JALAN",
        value: "Jalan Transolutions (India) Limited"
    },
    {
        label: "ASLIND",
        value: "ASL Industries Limited"
    },
    {
        label: "PROLIFE",
        value: "Prolife Industries Limited"
    },
    {
        label: "GICL",
        value: "Globe International Carriers Limited"
    },
    {
        label: "DRL",
        value: "Dhanuka Realty Limited"
    },
    {
        label: "AURDIS",
        value: "Aurangabad Distillery Limited"
    },
    {
        label: "GRETEX",
        value: "Gretex Industries Limited"
    },
    {
        label: "JETKNIT",
        value: "Jet Knitwears Limited"
    },
    {
        label: "AVSL",
        value: "AVSL Industries Limited"
    },
    {
        label: "KKVAPOW",
        value: "KKV Agro Powers Limited"
    },
    {
        label: "PERFECT",
        value: "Perfect Infraengineers Limited"
    },
    {
        label: "AHIMSA",
        value: "Ahimsa Industries Limited"
    },
    {
        label: "SHAIVAL",
        value: "Shaival Reality Limited"
    },
    {
        label: "EMKAYTOOLS",
        value: "Emkay Taps and Cutting Tools Limited"
    }
]
