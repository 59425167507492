import dayjs from "dayjs"
import i18n from '@/plugins/i18n'

// 處理日期範圍欄位(搜尋欄位-8小時後搜尋)
export function filterDateRange (params, dateRange, startColumn, endColumn) {
  if (dateRange?.length > 0) {
    params[startColumn] = dayjs(dateRange[0])
        // .subtract(8, 'hour')
        .format('YYYY-MM-DD HH:mm:ss')
    const endTime = dayjs(dateRange[1])
        // .subtract(8, 'hour')
        .format('YYYY-MM-DD HH:mm:ss').replace('00:00:00', '23:59:59')
    params[endColumn] = endTime
  } else {
    params[startColumn] = ''
    params[endColumn] = ''
  }
  return params
}
// 單日期時區處理
export function dateZoneHandler (date) {
  const time = dayjs(date).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss')
  return time
}
// date-picker 單選擇日期的快速選項
export const quickDate = [
  { label: i18n.global.t('date_quick_yesterday'), value: dayjs().add(-1, 'd') },
  { label: i18n.global.t('date_quick_lastWeek'), value: dayjs().add(-7, 'd') },
  { label: i18n.global.t('date_quick_lastMonth'), value: dayjs().add(-1, 'month') }
  // [i18n.global.t('date_quick_yesterday')]: [dayjs().add(-1, 'd')]
]

// datePicker 快捷鍵選項
export const quickDateRange = {
  [i18n.global.t('date_quick_today')]: [dayjs().startOf('day'), dayjs().endOf('day')],
  [i18n.global.t('date_quick_last_7_day')]: [dayjs().subtract(7, 'day').startOf('day'), dayjs().endOf('day')],
  [i18n.global.t('date_quick_last_30_day')]: [dayjs().subtract(30, 'day').startOf('day'), dayjs().endOf('day')],
  [i18n.global.t('date_quick_this_month')]: [dayjs().startOf('month'), dayjs().endOf('month')],
}

// API返回的時間要+8小時
export const datePlusEight = (date) => {
  const newDate = dayjs(date)
      // .add(8, 'hour')
      .format('YYYY-MM-DD HH:mm:ss')
  return newDate
}
// 當天的日期之前都禁用
export const dateDisabled = (current) => {
  return current <= dayjs().subtract(1, 'day')
}
// 禁用時間 禁用目前時間之前的
export const timeDisabled = () => {
  const range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  const date = dayjs().add(1, 'minute')
  return {
    disabledHours: () => range(0, dayjs().hour()),
    disabledMinutes: () => range(0, date.minute())
  }
}

// 取得當天日期範圍
export function getTodayRange () {
  return [
    dayjs().startOf('day'),
    dayjs().endOf('day')
  ]
}