import { createStore } from "vuex"
import catchs from "./catchs"

export default createStore({
  state: {
    pendingCount: ''
  },
  mutations: {
    clickHeader(state, payload) {
      state.pendingCount = payload
    }
  },
  actions: {},
  modules: { catchs }
})