import i18n from '@/plugins/i18n'
import { api } from '@/util/api'

export const localeList = {
  en: 'En',
  tw: 'Tw'
}

// 共用的開關狀態
export const statusList = [
  {
    label: i18n.global.t('enable'),
    value: 'enable'
  },
  {
    label: i18n.global.t('disable'),
    value: 'disable'
  },
]

// 共用Yes No列表
export const trueList = [
  {
    label: i18n.global.t('yes'),
    value: 'true'
  },
  {
    label: i18n.global.t('no'),
    value: 'false'
  },
]

// 用戶列表狀態
export const staffStatusList = [
  {
    label: i18n.global.t('staffStatus_enable'),
    value: 'enable'
  },
  {
    label: i18n.global.t('staffStatus_disable'),
    value: 'disable'
  },
]

// 幣種選單
export const coinUnitList = [
  { label: 'USDT', value: 'USDT' },
]

// 鏈選單
export const chainList = [
  { label: 'TRON', value: 'TRON' },
  { label: 'BSC', value: 'BSC' },
]

//廣告頁面選單
export const adPageList = [
  { label: i18n.global.t('playingPage_index'), value: 'index' },
  { label: i18n.global.t('playingPage_mall'), value: 'mall' },
]

// 審核狀態
export const reviewStatusList = [
  { label: i18n.global.t('success'), value: 'success' },
  { label: i18n.global.t('pending'), value: 'pending' },
  { label: i18n.global.t('fail'), value: 'fail' }
]

// 查詢市場
export const getMarketList = async () => {
  try {
    const { data } = await api.get('admin/common/market/list')
    return data.data.map(item => {
      return {
        label: i18n.global.t(`country_${item}`),
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢國家列表
export const getCountryList = async () => {
  try {
    const { data } = await api.get('admin/common/country')
    return data.data.map(item => {
      return {
        label: i18n.global.t(`country_${item.name}`),
        value: item.name
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢國碼列表
export const getAreaCodeList = async () => {
  try {
    const { data } = await api.get('admin/common/country')
    return data.data.map(item => {
      return {
        label: item.areaCode,
        value: item.areaCode
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢電話國碼
export const getCountryCode = async () => {
  try {
    const { data } = await api.get('admin/common/countryCode/list')
    return data.data.map(item => {
      return {
        label: `(+${item.countryCode}) ${item.name}`,
        value: item.name
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢時區
export const getTimeZone = async () => {
  try {
    const { data } = await api.get('admin/common/zoneGroup/list')
    return data.data.map(item => {
      return {
        label: `GMT${item.timeZoneOffset}`,
        value: item.timeZoneOffset
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 交易對列表
export const tradePairList = async (market) => {
  const params = {
    market: market
  }
  try {
    const { data } = await api.get('admin/common/tradingPair/list', params)
    return data.data.map(item => {
      return {
        label: item.pairName,
        value: item.pairName
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 幣幣交易列表
export const coinTradePairList = async () => {
  try {
    const { data } = await api.get('admin/common/coinTradingPair/list')
    return data.data.map(item => {
      return {
        label: item.pairName,
        value: item.pairName
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 會員狀態
export const memberStatusList = [
  { label: i18n.global.t('memberStatus_enable'), value: 'enable' },
  { label: i18n.global.t('memberStatus_disable'), value: 'disable' },
  { label: i18n.global.t('disableBuy'), value: 'disableBuy' },
]

export const memberLevelList = [
  { label: 1, value: '1' },
]

// KYC審核狀態
export const kycReviewStatusList = [
  { label: i18n.global.t('kycReviewStatus_success'), value: 'success' },
  { label: i18n.global.t('kycReviewStatus_pending'), value: 'pending' },
  { label: i18n.global.t('kycReviewStatus_fail'), value: 'fail' }
]

// 交易紀錄-時間
export const orderTradeTimeTypeList = [
  { label: i18n.global.t('createdTime'), value: 'createdTime' },
  { label: i18n.global.t('completionTime'), value: 'completionTime' }
]

// 買賣類型
export const buySellTypeList = async () => {
  try {
    const { data } = await api.get('admin/order/coinTrade/direction/list')
    return data.data.map(item => {
      return {
        label: i18n.global.t(`buySellType_${item}`),
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 買賣幣種類型(未定)
export const buySellCoinUnitTypeList = [
  { label: i18n.global.t('buySellCoinUnitType_buy'), value: 'buy' },
  { label: i18n.global.t('buySellCoinUnitType_sell'), value: 'sell' }
]

// 交易對類型(未定)
export const tradePairTypeList = [
  { label: i18n.global.t('tradePairType_fiat'), value: 'fiat' },
  { label: i18n.global.t('tradePairType_cryptoToCrypto'), value: 'cryptoToCrypto' }
]

// 數量類型
export const amountTypeList = [
  { label: i18n.global.t('amountType_costPrice'), value: 'costPrice' },
  { label: i18n.global.t('amountType_sellingPrice'), value: 'sellingPrice' },
  { label: i18n.global.t('amountType_profit'), value: 'profit' }
]

// 交易紀錄狀態
export const orderTradeStatusList = [
  { label: i18n.global.t('orderTradeStatus_pending'), value: 'pending' },
  { label: i18n.global.t('orderTradeStatus_success'), value: 'success' },
  { label: i18n.global.t('orderTradeStatus_fail'), value: 'fail' }
]

// 會員修改狀態列表
export const memberReviseStatusList = async () => {
  try {
    const { data } = await api.get('admin/member/status/list')
    const filterData = data.data.filter(item => (item !== 'systemFreezeAccount' && item !== 'systemForbidLogin'))
    return filterData.map(item => {
      return {
        label: i18n.global.t(`memberStatus_${item}`),
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 充值類別
export const depositTypeList = [
  { label: i18n.global.t('depositType_chain'), value: 'chain' },
  { label: i18n.global.t('depositType_offline'), value: 'offline' },
]

// 提領類別
export const withdrawalTypeList = [
  { label: i18n.global.t('withdrawType_chain'), value: 'chain' },
  { label: i18n.global.t('withdrawType_offline'), value: 'offline' },
]

// 訂單狀態
export const orderStatusList = [
  { label: i18n.global.t('orderStatus_pending'), value: 'pending' },
  { label: i18n.global.t('orderStatus_paid'), value: 'paid' },
  { label: i18n.global.t('orderWithdrawStatus_success'), value: 'success' },
  { label: i18n.global.t('orderWithdrawStatus_fail'), value: 'fail' },
]

// 提領紀錄狀態
export const orderWithdrawStatusList = [
  { label: i18n.global.t('orderWithdrawStatus_pending'), value: 'pending' },
  { label: i18n.global.t('orderWithdrawStatus_broadcasting'), value: 'broadcasting' },
  { label: i18n.global.t('orderWithdrawStatus_success'), value: 'success' },
  { label: i18n.global.t('orderWithdrawStatus_fail'), value: 'fail' },
]

// 加帳類型
export const addLedgerTypeList = [
  { label: i18n.global.t('addLedgerType_manualIncrease'), value: 'manualIncrease' },
  { label: i18n.global.t('addLedgerType_offlineIncrease'), value: 'offlineIncrease' }
]

// 減帳類型
export const subtractLedgerTypeList = [
  { label: i18n.global.t('subtractLedgerType_manualReduce'), value: 'manualReduce' },
  { label: i18n.global.t('subtractLedgerType_offlineReduce'), value: 'offlineReduce' }
]

// 銀行狀態(未定)
// export const bankStatusList = [
//   { label: i18n.global.t('bankStatus_success'), value: 'success' },
//   { label: i18n.global.t('bankStatus_pending'), value: 'pending' },
//   { label: i18n.global.t('bankStatus_fail'), value: 'fail' }
// ]

// 會員管理 > 會員流水(事件類型)
export const orderTypeList = [
  { label: i18n.global.t('orderType_deposit'), value: 'deposit' },
  { label: i18n.global.t('orderType_withdraw'), value: 'withdraw' },
  { label: i18n.global.t('orderType_sellerPaid'), value: 'sellerPaid' },
  { label: i18n.global.t('orderType_withdrawFee'), value: 'withdrawFee' },
  { label: i18n.global.t('orderType_withdrawRejected'), value: 'withdrawRejected' },
  { label: i18n.global.t('orderType_manualIncrease'), value: 'manualIncrease' },
  { label: i18n.global.t('orderType_manualReduce'), value: 'manualReduce' },
]

// 帳戶類型
export const accountTypeList = [
  { label: i18n.global.t('accountType_personal'), value: 'personal' },
  { label: i18n.global.t('accountType_company'), value: 'company' }
]

// 資金管理 > 查看(狀態)
export const financeStatusList = [
  { label: i18n.global.t('financeStatus_success'), value: 'success' },
  { label: i18n.global.t('financeStatus_fail'), value: 'fail' },
  { label: i18n.global.t('financeStatus_processing'), value: 'processing' },
]

// 通知列表 > 發信紀錄(發信類型)
export const messageTypeList = async () => {
  try {
    const { data } = await api.get('admin/message/type')
    return data.data.map(item => {
      return {
        label: i18n.global.t(`messageType_${item}`),
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 通知列表 > 發信紀錄(通知類型)
export const noticeTypeList = async () => {
  try {
    const { data } = await api.get('admin/message/noticeType')
    return data.data.map(item => {
      return {
        label: i18n.global.t(`noticeType_${item}`),
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 活躍程度列表
export const activeLevelList = [
  { label: i18n.global.t('all'), value: 'all' },
  { label: i18n.global.t('active'), value: 'active' },
  { label: i18n.global.t('notActive'), value: 'notActive' }
]

// 平台數據 > 統計tab列表
export const activeStatisticList = [
  { label: i18n.global.t('memberStatistic'), value: 'member' },
  { label: i18n.global.t('fundsStatistic'), value: 'funds' }
]

// 企業戶聯繫紀錄-時間
export const companyTimeTypeList = [
  { label: i18n.global.t('applicationTime'), value: 'applicationTime' },
  { label: i18n.global.t('contactTime'), value: 'contactTime' },
  { label: i18n.global.t('closeCaseTime'), value: 'closeCaseTime' }
]

// 實名管理紀錄的時間類型選擇
export const KycRealTime = [
  { label: i18n.global.t('applicationTime'), value: 'apply' },
  { label: i18n.global.t('auditTime'), value: 'review' },
  { label: i18n.global.t('registerTime'), value: 'register' }
]

// 實名管理的狀態選擇
export const kycStateList = [
  { label: i18n.global.t('kyc_pending'), value: 'pending' },
  { label: i18n.global.t('kyc_success'), value: 'success' },
  { label: i18n.global.t('kyc_fail'), value: 'fail' }
]

// 查銀行列表，顯示用name，送值送id
export const bankList = async (market) => {
  const params = {
    market: market
  }
  try {
    const { data } = await api.get('admin/common/bank/list', params)
    return data.data.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 企業戶聯繫紀錄-狀態
export const companyContactStatusList = [
  { label: i18n.global.t('companyContactStatus_apply'), value: 'apply' },
  { label: i18n.global.t('companyContactStatus_progress'), value: 'progress' },
  { label: i18n.global.t('companyContactStatus_complete'), value: 'complete' },
]
// 查詢收藏集類型
export const getCollectionTypeAPI = async () => {
  try {
    const { data } = await api.get('/admin/collection/collectionType/list')
    return data
  } catch (err) {
    console.error(err, 'companyContactStatusList')
  }
}
// 查詢圖庫列表
export const getImageTypeListAPI = async () => {
  try {
    const { data } = await api.get('admin/common/imageType/list')
    return data
  } catch (err) {
    console.error(err)
  }
}

// 幣幣交易類型
export const tradeTypeList = [
  { label: i18n.global.t('trade_spot'), value: 'spot' },
  { label: i18n.global.t('trade_quant'), value: 'quant' }
]

// 查詢NFT的幣種
export const getNetCoinListAPI = async () => {
  try {
    const { data } = await api.get('admin/common/currency/list/nft')
    return data.data
  } catch (err) {
    console.log('error', err)
  }
}

// 提領類別
export const getWalletTypeListAPI = async () => {
  try {
    const { data } = await api.get('admin/common/walletType/list')
    return data.data.map(item => ({ label: i18n.global.t(item), value: item }) )
  } catch (err) {
    console.log('error', err)
  }
}
// 合約交易紀錄的預計結果選擇
export const expectedResultsState = [
  { label: i18n.global.t('profit'), value: 'profit' },
  { label: i18n.global.t('profitLoss'), value: 'profitLoss' }
]

// 查詢合約交易的合約狀態(平倉 未平倉)
export const getContractStatusAPI = async () => {
  try {
    const { data } = await api.get('admin/contractBet/contract/status')
    return data.data
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢合約的多空類型
export const getPositionTypeAPI = async () => {
  try {
    const { data } = await api.get('admin/contractBet/position/type')
    return data.data
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢合約結果類型
export const getResultTypeAPI = async () => {
  try {
    const { data } = await api.get('admin/contractBet/result/type')
    return data.data
  } catch (err) {
    console.log('error', err)
  }
}
// 風控設定頁的類型
export const riskTypeListAPI = async () => {
  try {
    const { data } = await api.get('admin/riskControl/type')
    return data.data.map(item => {
      return { label: i18n.global.t(`risk_${item}`), value: item }
    })
  } catch (err) {
    console.error(err, 'riskTypeListAPI')
  }
}
// 風控設定頁的狀態
export const riskStateListAPI = async () => {
  try {
    const { data } = await api.get('admin/riskControl/status')
    return data.data.map(item => {
      return { label: i18n.global.t(`risk_${item}`), value: item }
    })
  } catch (err) {
    console.error(err, 'riskStateListAPI')
  }
}

// 質押紀錄頁的時間類型
export const orderPledgeRecordTimeList = [
  { label: i18n.global.t('pledgeTime'), value: 'apply' },
  { label: i18n.global.t('estimatedEndTime'), value: 'finish' },
  { label: i18n.global.t('earlyEndTime'), value: 'manual' }
]

// 質押紀錄的狀態選擇
export const pledgeStateList = [
  { label: i18n.global.t('kyc_pending'), value: 'progress' },
  { label: i18n.global.t('redeemed'), value: 'release' },
  { label: i18n.global.t('pledgeProgress'), value: 'toBeReleased' }
]

// 質押紀錄的申購類型
export const subscriptionTypeList = [
  { label: i18n.global.t('manualSubscription'), value: 'regular' },
  { label: i18n.global.t('automaticSubscription'), value: 'current' }
]

// 永續合約紀錄的時間類型
export const perPetualTimeType = [
  { label: i18n.global.t('time', {input: i18n.global.t('entrust')}), value: 'created' },
  { label: i18n.global.t('time', {input: i18n.global.t('openPosition')}), value: 'openPositionAt' },
  { label: i18n.global.t('time', {input: i18n.global.t('eveningUp')}), value: 'closed' },
  { label: i18n.global.t('time', {input: i18n.global.t('cancel')}), value: 'canceledAt' },
]

// 取得永續合約多空類型
export const futuresDirectionTypeAPI = async () => {
  try {
    const { data } = await api.get('admin/futures/direction/all')
    return data.data.map(item => {
      return { label: i18n.global.t(`futures_${item}`), value: item }
    })
  } catch (err) {
    console.error(err, 'futuresDirectionStateAPI')
  }
}

// 取得永續合約狀態
export const futuresStateAPI = async () => {
  try {
    const { data } = await api.get('admin/futures/status/all')
    return data.data.map(item => {
      return { label: i18n.global.t(`futures_${item}`), value: item }
    })
  } catch (err) {
    console.error(err, 'futuresStateAPI')
  }
}
// 取得永續合約平倉類型
export const futuresCloseTypeAPI = async () => {
  try {
    const { data } = await api.get('admin/futures/closeType/all')
    return data.data.map(item => {
      return { label: i18n.global.t(`futures_${item}`), value: item }
    })
  } catch (err) {
    console.error(err, 'futuresCloseTypeAPI')
  }
}

// 取得 升降等類型
export const changeTypeListAPI = async () => {
  try {
    const { data } = await api.get('admin/member/level/change/type')
    return data.data.map(item => {
      return { label: i18n.global.t(`changeType_${item}`), value: item }
    })
  } catch (err) {
    console.error(err, 'changeTypeListAPI')
  }
}

// 永續合約頁的金額類型
export const futuresAmountType = [
  { label: i18n.global.t('orderAmount'), value: 'orderAmount' },
  { label: i18n.global.t('contract_orderPrice'), value: 'openPrice' },
  { label: i18n.global.t('closingPrice'), value: 'closePrice' }
]

// 永續合約頁的盈虧金額類型
export const futuresRealizedType = [
  { label: i18n.global.t('cumulativeProfitLoss'), value: 'realized' },
  { label: i18n.global.t('numberPositions'), value: 'amount' }
]

export const validStatusList = [
  { label: i18n.global.t('all'), value: '' },
  { label: i18n.global.t('validStatus_valid'), value: 'valid' },
  { label: i18n.global.t('validStatus_invalid'), value: 'invalid' }
]

// 查詢商品標籤列表
export const getProductTagList = async () => {
  try {
    const { data } = await api.get('admin/productTag/list')
    return data.data.pageList.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢遊戲項目列表
export const getProductTypeList = async () => {
  try {
    const { data } = await api.get('admin/productType/list')
    return data.data.pageList.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 商品狀態
export const productStatusList = [
  {
    label: i18n.global.t('upShelf'),
    value: 'enable'
  },
  {
    label: i18n.global.t('downShelf'),
    value: 'disable'
  },
]

// 查詢銀行列表
export const getBankListAPI = async () => {
  try {
    const { data } = await api.get('admin/common/bank')
    return data.data.map(item => {
      return {
        label: item.name,
        value: item.code
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 訂單列表時間類型
export const orderTimeTypeList = [
  { label: 'contract_orderTime', value: 'createdAt' },
  { label: 'completionTime', value: 'finishAt' }
]

// 取得所有遊戲項目
export const getAllProductTypeList = async () => {
  try {
    const { data } = await api.get('admin/common/productType')
    return data.data.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}