import { createApp } from 'vue'
import { nextTick } from '@vue/runtime-core'
import ant from 'ant-design-vue'
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import dayjs from 'dayjs'
import { router } from './plugins/router'
import i18n from './plugins/i18n'
import { api } from '@/util/api'
import * as date from '@/util/date'
import * as excel from '@/util/excel'
import * as list from '@/util/list'
import * as number from '@/util/number'
import { notice } from '@/util/notice'
import { authsHandler } from '@/util/btnAuths'
import * as table from '@/util/table'
import store from '@/store'
// v-viewer
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

const isPhone = navigator.userAgent.includes('Mobile')

const app = createApp(App)

nextTick(() => {
  app.config.globalProperties.$isPhone = isPhone
})

// 註冊全域變數
app.provide('$message', message)
app.provide('$api', api)
app.provide('$t', i18n.global.t)
app.provide('$dayjs', dayjs)
app.provide('$date', date)
app.provide('$excel', excel)
app.provide('$list', list)
app.provide('$number', number)
app.provide('$notice', notice)
app.provide('$authsHandler', authsHandler)
app.provide('$table', table)

app.use(router)
  .use(i18n)
  .use(ant)
  .use(store)
  .use(VueViewer, {
    defaultOptions: {
      zIndex: 30000
    }
  })
  .mount('#app')
