<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import platformConfig from './config/PlatformConfig'

const title = ref(platformConfig.title) 
const debounce = (callback, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      callback.apply(context, args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver; // 保存原始的 ResizeObserver 类

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 20)
    super(callback)
  }
}

onMounted(() => {
  document.title = title.value
})

// export default {
//   name: 'App',
//   components: {
//     HelloWorld
//   }
// }
</script>

<style lang="scss">
@import '@/assets/scss/_main.scss';
@import '@/assets/fonts/font.css';
</style>
