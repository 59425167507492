export const state = {
  catchList: JSON.parse(sessionStorage.getItem('catchList')) || [],
};

export const mutations = {
  setCatchs(state, payload) {
    const existsIndex = state.catchList.findIndex(i => i.id === payload.id)
    if (existsIndex !== -1) {
      state.catchList[existsIndex] = payload
    } else {
      state.catchList.push(payload)
    }
    sessionStorage.setItem('catchList', JSON.stringify(state.catchList))
  },

  // 關閉單一頁籤
  removeCatch(state, payload) {
    const index = state.catchList.findIndex(i => i.id === payload)
    state.catchList.splice(index, 1)
    sessionStorage.setItem('catchList', JSON.stringify(state.catchList))
  },

  // 關閉其他頁籤
  removeOtherCatch(state, payload) {
    state.catchList = [payload]
    sessionStorage.setItem('catchList', JSON.stringify(state.catchList))
  },
};

export const actions = {
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};